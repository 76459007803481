import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss'],
})
export class ThankYouComponent {
  public msg: any;
  public type: any;
  constructor(
    private modalCtrl: ModalController,
  ) { }

  goTo(type){
    this.modalCtrl.dismiss(null, type); 
  }

}
