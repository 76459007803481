import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CmsService } from '../api-services/cms/cms.service';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
})
export class FAQsComponent {

  public faqsData: any;
  public allFaqData = [];
  private subscriptions: Subscription[] =[];

  constructor(
    private cmsService: CmsService,
    private sanitizer: DomSanitizer,
    private globalService: GlobalService
  ) { }

  public ionViewDidEnter(): void {
    this.subscriptions.push(
      this.cmsService.getAllCmsData('FAQs').subscribe( res => {
        if(res){
          this.globalService.hideLoading();
          this.allFaqData = res;
          this.faqsData = {};
          res.map(data => {
            if (!this.faqsData[data.category]) {
              this.faqsData[data.category] = [];
            }
            this.faqsData[data.category].push(data);
          })
        } else {
          this.globalService.hideLoading();
        }
        
      }, error => {
        this.globalService.hideLoading();
        console.error('error',error);
      })
    );
  }

  transformYourHtml(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  public ionViewDidLeave(): void {
    this.subscriptions.map( subcribe => {
      subcribe.unsubscribe();
    });
  }

}
