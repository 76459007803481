import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent implements OnInit {
  urlType: string;

  constructor(
    private router : Router,
    private activatedRoute : ActivatedRoute,
  ) {

    // this.subscriptions.push(
      this.activatedRoute.paramMap.subscribe(
        (params: ParamMap) => {
        this.urlType = params.get('type');
        },
      )
    // );
   }

  ngOnInit() {}

}
