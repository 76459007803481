import { Component, OnInit } from '@angular/core';
import { Subscription} from 'rxjs';
import { ConstantVariables } from 'src/const/constant';
import { GlobalService } from 'src/app/services/global.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { OrderService } from 'src/app/api-services/order/order.service';
import { ModalController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-option',
  templateUrl: './payment-option.component.html',
  styleUrls: ['./payment-option.component.scss'],
})
export class PaymentOptionComponent implements OnInit {

  public segmentValue = 'yearly';
  public selectedValue: any;
  public user: any;
  public radioData = [
    {
      package: 'Intro',
      price: '€ 950',
      plan: 'monthly',
      allowCount: 1,
      description: 'One job or one training course can be posted for one month.',
      checked: false,
      vat: '23%' 
    },
    {
      package: 'Base',
      price: '€ 1950',
      plan: 'quarterly',
      allowCount: 3,
      description: 'Three job or three training course can be posted for three month.',
      checked: false,
      vat: '23%' 
    },
    {
      package: 'Popular',
      price: '€ 5950',
      plan: 'yearly',
      description: 'Unlimited access to the platform for 12 months.',
      allowCount: 100000,
      checked: false,
      vat: '23%' 
    },
    // {
    //   package: 'Enterprise',
    //   price: '€ 119',
    //   checked: false
    // },
  ];

  private subscriptions: Subscription[] = [];

  constructor(
    private constantVariable: ConstantVariables,
    private globalService: GlobalService,
    private angularFireFunctions: AngularFireFunctions,
    private orderService: OrderService,
    private ModalCtler: ModalController,
    private router: Router,
  ) {}

  ngOnInit() {}

  public ionViewDidLeave(): void {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    });
  }
  public segmentChanged(e): void {
    this.segmentValue = e.detail.value;
  }

  public updatePayment(event){
    
    this.selectedValue =  event;

    this.radioData.map(res =>{
      if(res.package === event.package){
        res.checked = true;
      } else {
        res.checked = false;   
      } 
    });
    
    switch (event.package) {
      case 'Intro':
        event.selectedPrice = 950
        break;
      case 'Base':
        event.selectedPrice = 1950
        break;
      case 'Popular':
        event.selectedPrice = 5950
        break;
    
      default:
        break;
    }
    
    var increasedPrice = event.selectedPrice * this.constantVariable.vatPrice;
    increasedPrice = Math.round(increasedPrice * 100) / 100;
    const vatPrice = increasedPrice.toFixed(2) 

    this.globalService.showLoading();
    const paymentDetailObj:any = {
      package : this.selectedValue.package,
      price : `€ ${vatPrice}`,
      plan : this.selectedValue.plan,
      allowCount : this.selectedValue.allowCount,
      MaxAddedCount: this.selectedValue.allowCount * 2,
      JobTrainingCountMaxAddedCount: 0,
      paymentDate : new Date(),
      companyName: this.user?.companyName,
      email: this.user?.email,
      userId: this.user?.docId,
      price_id: environment.production ? this.constantVariable.priceId.live[this.selectedValue.package] : this.constantVariable.priceId.local[this.selectedValue.package]
      // price_id:this.constantVariable.priceId.live[this.selectedValue.package]
    }; 
    
    this.subscriptions.push(
      this.orderService.addorderData(paymentDetailObj).subscribe(res => {
        console.log('res', res);
        paymentDetailObj.docId = res.id;
        this.paymentToStrip(paymentDetailObj);
      }, error => {
        console.error('error', error);
      })
    );
  }

  public paymentToStrip(data:any): void{
    const callable = this.angularFireFunctions.httpsCallable('callStripe');
    const obs = callable({
      item: data, 
      successUrl: window.location.origin +'/thank-you/'+ data.docId,
      errorUrl: window.location.origin +'/payment-error'
    });
    obs.subscribe(async resp => {
      window.open(resp.url,"_self");
    }, err => {
      console.log('err', err);
      console.error(err);
    });
  }


  public back() {
    // this.navCntrl.pop();
    this.ModalCtler.dismiss(this.user);
    // this.router.navigate(['/news-feed']);
  }

}
