import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class EducationService {

  constructor(
    private db: AngularFirestore,

  ) { }

  public getAllEductions(id): Observable<any> {
    return this.db.collection('Users').doc(id).collection('Eductions', ref => ref.orderBy('toYear','desc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // console.log('data~~~~~~~~~~~~~~~~~~',data)
            return { docId, ...data, };
          });
        })
        );
      }
      
  public addEductions(data: any): Observable<any> {
    data.createdDate = new Date();
    const obs = new Observable((observer) => {
      this.db.collection('Users').doc(data.userId).collection('Eductions').add(data).then(async (res: any) => {
        observer.next({
          status: 200,
          id: res.id
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }
  
  
  public updateEductions(educationId, data): Observable<any> {
    // console.log('data~~~~~~~~~~~~~~~~~~',id, educationId, data);
    const obj = new Observable( (observer) => {
      this.db.collection('Users').doc(data.userId).collection('Eductions').doc(educationId).update(data).then(res => {
        observer.next({
          status : 200,
          message : 'Eductions update successfully'
        })
      }).catch((error => {
        observer.next({
          status : 400,
          message : 'error'
        })
      }))
    })
    return obj
  }

  public updateDocWalletId(tableName, docId, data){
    // console.log('++++++++++', tableName, docId, data);
    const obj = new Observable( (observer) => {
      const obj = {
        docWalletId: data.docWalletId,
      }
      this.db.collection('Users').doc(data.userId).collection(tableName).doc(docId).update(obj).then(res => {
        observer.next({
          status : 200,
          message : 'successfully'
        })
      }).catch((error => {
        observer.next({
          status : 400,
          message : 'error'
        })
      }))
    })
    return obj
  }

  public deleteEducationData(id, data): Observable<any>{
    // console.log('******DELETE DATA*******',id, data);
    const obs = new Observable((observer) => {
     this.db.collection('Users').doc(id).collection('Eductions').doc(data.docId).delete().then(async () => {
     }).catch((error) => {
       observer.next({
         message: error.message ? error.message : 'There is some issue...',
         status: error.code ? error.code : 400,
       });
     });
   });
   return obs;
 }





}