import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validator, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ApplicantsService } from 'src/app/api-services/applicants/applicants.service';
import { JobsService } from 'src/app/api-services/jobs/jobs.service';
import { GlobalService } from 'src/app/services/global.service';
import { UsersService } from 'src/app/api-services/users/users.service';
import { ProfileComponent } from 'src/app/profile/profile.component';
import { ConstantVariables } from 'src/const/constant';

@Component({
  selector: 'app-applicants-data',
  templateUrl: './applicants-data.page.html',
  styleUrls: ['./applicants-data.page.scss'],
})
export class ApplicantsDataPage implements OnInit {

  public chatFrm: FormGroup = new FormGroup({
    chatting: new FormControl(''),
    status: new FormControl(''),

  });

  public user: any;
  public job: any;
  public title: any;
  public applicantId: any;
  public filterApplicants: any;
  public currentConvertionStatus: any;
  public newMessage = new FormControl('');

  msgList: any;
  User: string = "Me";
  admin: string = "HealthBot";
  height = 0;

  private applicantsData: any;
  public applicantMessage: any;
  private subscriptions: Subscription[] = [];
  private selectedApplicantId = '';
  private openPopup = false;

  constructor(
    public activRoute: ActivatedRoute,
    private modalCtlr: ModalController,
    private applicantsService: ApplicantsService,
    private formBuilder: FormBuilder,
    private jobService: JobsService,
    private globalService: GlobalService,
    private alertController: AlertController,
    private ref: ChangeDetectorRef,
    private userService: UsersService,
    private modalCtrl: ModalController,
    private router: Router,
    private constantVariables: ConstantVariables,

  ) {
    this.height = window.innerHeight - 210;
  }

  ngOnInit() {
    this.getApplicants();
    console.log('$%^$$', this.user)
  }

  public ionViewDidEnter(): void {
    this.chatFrm = this.formBuilder.group({
      chatting: [''],
      status: ['']
    });
  }

  public send(chat, event) {
    const isWhitespace = (this.chatFrm.controls.chatting.value && this.chatFrm.controls.chatting.value.toString() || '').trim().length === 0;
    const isValid = !isWhitespace;
    isValid ? null : { 'whitespace': true };

    if (this.chatFrm.value.chatting.length > 0 && isValid) {
      console.log('value');
      const fromObj = {
        userId: this.user.docId,
        name: this.user.companyName,
        photo: this.user.photo ? this.user.photo : '',
      }
      const toObj = {
        userId: chat[0].currentUserDocId,
        name: chat[0].from.name,
        photo: chat[0].from.photo ? chat[0].from.photo : '',
      }
      this.chatFrm.value.conversation = this.chatFrm.value.chatting;
      this.chatFrm.value.currentUserDocId = this.user.docId;
      this.chatFrm.value.from = fromObj;
      this.chatFrm.value.to = toObj;
      this.chatFrm.value.messageDate = new Date();

      delete this.chatFrm.value.status

      setTimeout(() => {
        this.chatFrm.reset();
      }, 500);
      this.subscriptions.push(
        this.jobService.addMessageJob(this.job.docId, this.chatFrm.value, this.selectedApplicantId).subscribe(res => {
          // console.log('*****************888', this.chatFrm.value.chatting)
        }, error => {
          console.error('error', error);
        })
      );
    } else {
      if (this.chatFrm.value.chatting.length > 0) {
        this.chatFrm.reset();
      }
    }
  }

  public getApplicants() {
    // console.log('<<<<<<<<<<<<', this.job.docId);
    this.subscriptions.push(
      this.applicantsService.getApplicantData(this.job.docId).subscribe(res => {
        this.applicantsData = res;
        this.filterApplicants = res;
        // console.log('applicantsData', this.applicantsData);
        if (this.applicantId) {
          const findApplicant = this.applicantsData.find(status => status.docId === this.applicantId);
          this.getMessages(this.applicantId, findApplicant.status);
        } else {
          if (this.selectedApplicantId) {
            this.getMessages(this.selectedApplicantId, this.currentConvertionStatus);
          } else {
            this.getMessages(this.applicantsData[0].docId, this.applicantsData[0].status);
          }
        }
      })
    );
  }

  public getMessages(id, status?) {
    this.currentConvertionStatus = status;
    this.selectedApplicantId = id;
    this.subscriptions.push(
      this.applicantsService.getApplicantMessages(this.job.docId, id).subscribe(res => {
        console.log('>>>>>>>>>>>>>>>>>>>', res);
        this.applicantMessage = res;
        setTimeout(() => {
          const objDiv = document.getElementById("conversation");
          if (objDiv) {
            objDiv.scrollTop = objDiv.scrollHeight;
            this.globalService.hideLoading();
          } else {
            this.globalService.hideLoading();
          }
        }, 10)
      })
    );
  }

  public cancel() {
    this.router.navigate(['find-jobs']);
    this.modalCtlr.dismiss(null, 'cancel');
  }

  public statusChange(event) {
    // console.log('event------', event.detail.value)
    if (this.openPopup) {
      if (event.detail.value === 'hired' || event.detail.value === 'rejected') {
        if (event.detail.value === 'hired') {
          this.alertStatus("hire");
        } else {
          this.alertStatus("reject");
        }
      } else {
        const obj = {
          status: this.chatFrm.value.status,
          statusSelectDate: new Date(),
          messageForCandidates: ''
        }
        this.subscriptions.push(
          this.jobService.updateApplicantJob(this.job.docId, obj, this.selectedApplicantId).subscribe(res => {
            // console.log('resData-------------------', res);
          }, error => {
            console.error('error', error);
          })
        );
      }
    }
  }

  public clickForStatus() {
    this.openPopup = true;
  }

  public async alertStatus(status) {
    const alert = await this.alertController.create({
      header: 'Alert',
      message: `Are you sure you want to ${status} this user !`,
      mode: 'ios',
      cssClass: 'alert-class',
      inputs: [
        {
          type: 'textarea',
          name: 'message',
          placeholder: 'Message for candidates'
        },
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Alert canceled');
          },
        },
        {
          text: 'Save',
          role: 'confirm',
          handler: data => {
            console.log('Alert confirmed');
            const obj = {
              status: this.chatFrm.value.status,
              statusSelectDate: new Date(),
              messageForCandidates: data.message
            }
            this.subscriptions.push(
              this.jobService.updateApplicantJob(this.job.docId, obj, this.selectedApplicantId).subscribe(res => {
                console.log('resData-------------------', res);


              }, error => {
                console.error('error', error);
              })
            );
          },
        },
      ],
    });
    await alert.present();
  }

  public applicantsFilter(event): void {
    // console.log('applicants Filter',event.target.value);
    const status = event.target.value;
    if (status === 'all') {
      this.filterApplicants = this.applicantsData;
      this.ref.detectChanges();
    } else {
      this.filterApplicants = this.applicantsData.filter(users => users.status === status);
      // console.log('filterApplicants',this.filterApplicants);
      this.ref.detectChanges();
    }
  }

  public ionViewDidLeave(): void {
    this.subscriptions.map(subscriber => {
      subscriber.unsubscribe();
    });
  }

  // public async shareProfile(){
  //   console.log(window.location.port)
  //   // const data = `${window.location.origin}/applicant-profile/${this.applicantMessage[0].from.userId}`
  //   const data = `${window.location.port ==='8100' ? window.location.origin : this.constantVariables.liveUrl}/applicant-profile/${this.applicantMessage[0].from.userId}`;
  //   const alert = await this.alertController.create({
  //     header: 'Please copy link to share',
  //     buttons: [
  //       {
  //         text: 'Copy',
  //         role: 'confirm',
  //         handler: (alertData) => {
  //           this.copyUrl(data);
  //           this.globalService.showToastMessage('Url copied to clipboard');
  //         },
  //       },
  //     ],
  //     mode: 'ios',
  //     inputs: [
  //       {
  //         placeholder: 'Name',
  //         value: data,
  //         disabled: true,
  //       },
  //     ],
  //   });

  //   await alert.present();
  // }

  // private copyUrl(val){
  //   // console.log(val);
  //   const selBox = document.createElement('textarea');
  //   selBox.style.position = 'fixed';
  //   selBox.style.left = '0';
  //   selBox.style.top = '0';
  //   selBox.style.opacity = '0';
  //   selBox.value = val;
  //   document.body.appendChild(selBox);
  //   selBox.focus();
  //   selBox.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(selBox);
  // }


}