import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { EducationService } from '../api-services/education/education.service';
import { ExperienceService } from '../api-services/experience/experience.service';
import { ExperienceCompanysService } from '../api-services/experienceCompanys/experience-companys.service';
import { UserHealthAndSeftyService } from '../api-services/userHealtAndSefty/user-health-and-sefty.service';
import { UsersService } from '../api-services/users/users.service';
import { UserSkillSetService } from '../api-services/userSkillSet/user-skill-set.service';
import { AboutYouComponent } from '../registration/about-you/about-you.component';
import { ExperienceDetailsComponent } from '../registration/experience-details/experience-details.component';
import { SkillComponent } from '../registration/skill/skill.component';
import { ThankYouComponent } from '../registration/thank-you/thank-you.component';
import { GlobalService } from '../services/global.service';
import { PaymentOptionComponent } from '../shared-ui-components/payment-option/payment-option.component';
import { Capacitor } from '@capacitor/core';
@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent  {

  private subscriptions: Subscription[] = [];
  private userSubscriptions: Subscription;
  public webPlateForm: boolean = false;

  user: any;
  nextPaydate: Date;
  constructor(
    private router: Router,
    private userService: UsersService,
    private alertController: AlertController,
    private globalService: GlobalService,
    private modalCtrl: ModalController,
    private educationService: EducationService,

    private experienceService: ExperienceService,
    private experienceCompanysService: ExperienceCompanysService,
    private userHealthAndSeftyService: UserHealthAndSeftyService,
    private userSkillSetService: UserSkillSetService,
    public platform: Platform
  ) { }


  public ionViewDidEnter(): void {
    this.globalService.hideLoading();
    this.subscriptions.push(
      this.userSubscriptions = this.userService.user$.subscribe(res => {
        if (res) {
          this.user = res;
          if (res.userType === 'superAdmin') {
            this.userSubscriptions.unsubscribe();
            this.router.navigate(['/admin/dashboard']);
          } else if (res.userType === 'user') {

            if (res.nextStep === 1 || !res.nextStep) {
              this.openAboutYou();
            } else if (res.nextStep === 2) {
              this.openExperince();
            } else if (res.nextStep === 3) {
              this.openSkill();
            } else if (res.nextStep === 'done') {
              this.router.navigate(['news-feed']);
            }

          } else if (res.userType === 'admin') {
            const currentDate = new Date();


            if (res?.nextPaymentDate) {

              const seconds = res.nextPaymentDate.seconds;
              const nanoseconds = res.nextPaymentDate.nanoseconds;
              const convertedTimestamp = seconds + nanoseconds / 1e9;
              let milliseconds = convertedTimestamp * 1000;
              this.nextPaydate = new Date(milliseconds);
            }


              if (res.activeStatus === true && res.companyStatus === 'approve') {
                this.alertController.dismiss();
                this.modalCtrl.dismiss();
                if (!res.paymentStatus) {
                  this.modalCtrl.dismiss();
                  this.openPaymentOption();
                } else if (currentDate > this.nextPaydate && res.activeStatus === true) {
                  this.modalCtrl.dismiss();
                  this.openPaymentOption();
                } else if ((res.allowCount === res.jobTrainingCount || res.MaxAddedCount === res.JobTrainingCountMaxAddedCount) && res.activeStatus === true) {
                  this.modalCtrl.dismiss();
                  this.openPaymentOption();
                } else {
                  this.router.navigate(['news-feed']);
                }
              } else if ( res.companyStatus === 'pending') {
                this.openPaymentOption();
              } else if ( res.companyStatus === 'reject')  {
                this.router.navigate(['start']);
                this.showNotActiveAlertToAdmin();
              }
          }
        } else {
          this.modalCtrl.dismiss();
          const isDevice = Capacitor.getPlatform();
          if (isDevice === 'web' && !this.user) {
            window.location.replace('https://trainmehireme.com');
          } else {
            this.router.navigate(['support']);
          }
        }
      }, error => {
        this.modalCtrl.dismiss();
        const isDevice = Capacitor.getPlatform();
        if (isDevice === 'web' && !this.user) {
          window.location.replace('https://trainmehireme.com');
        } else {
          this.router.navigate(['support']);
        }
        console.error('error', error);
      })
    );
    if (this.platform.is('desktop')) {
      this.webPlateForm = true;
    } else {
      this.webPlateForm = false;
    }
  }

  public ionViewDidLeave(): void {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    });
  }

  public async showNotActiveAlertToAdmin() {
    this.modalCtrl.dismiss();
    this.alertController.dismiss();
    const alert = await this.alertController.create({
      header: 'Account under review',
      message: 'You will be able to login after your account is approved by the SuperAdmin Please contact admin@trainmehireme.com for help.',
      mode: 'ios',
      backdropDismiss: false,
      cssClass: 'no-approve',
    });
    await alert.present();
  }

  public async openPaymentOption(): Promise<any> {
   const isDevice = Capacitor.getPlatform();
   if (isDevice !== 'web') {
    this.paymentAlert();
   } else {
      this.modalCtrl.dismiss();
      const modal = await this.modalCtrl.create({
        component: PaymentOptionComponent,
        cssClass: 'fullscreen',
        componentProps: {
          user: this.user
        }
      });
      modal.onDidDismiss().then((res: any) => {
        if (res?.data && res?.data?.companyStatus === 'pending') {
          this.userService.logout().subscribe( res => {
            this.router.navigate(['support']);
          }, error => {
            console.error('error', error);
          })
        } else  {
          this.alertController.dismiss();
          this.router.navigate(['news-feed'])
        }
      });
      return await modal.present();
    }
  }

  public async openAboutYou(): Promise<any> {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: AboutYouComponent,
      cssClass: 'aboutYou',
      mode: 'md',
      backdropDismiss: false,
      componentProps: {
        userDocId: this.user.docId
      }
    });
    modal.onDidDismiss().then((res: any) => {
      if (res.role === 'logout') {
        this.subscriptions.push(
          this.userService.logout().subscribe(logoutRes => {
            this.router.navigate(['/support']);
          }, error => {
            console.error('error', error);
          })
        );
      } else {
        const formData = res.data;
        let userObj: any = {
          dob: formData.dob ? formData.dob : '',
          currentCountry: formData.currentCountry ? formData.currentCountry : '',
          timeStampDob: formData.timeStampDob ? formData.timeStampDob : '',
          availableUntil: formData.availableUntil ? formData.availableUntil : '',
          availableFrom: formData.availableFrom ? formData.availableFrom : '',
          timeStmpavailableFrom: isNaN(formData.timeStmpavailableFrom) === false ? formData.timeStmpavailableFrom : '',
          timeStmpavailableUntil: isNaN(formData.timeStmpavailableUntil) === false ? formData.timeStmpavailableUntil : '',
          totalExpYear: formData.expYear ? formData.expYear : '',
          currentProvince: formData.currentProvince ? formData.currentProvince : '',
          currentCounty: formData.currentCounty ? formData.currentCounty : '',
          currentCity: formData.currentCity ? formData.currentCity : '',
          docId: this.user.docId,
          video: formData.video ? formData.video : '',
          verifiedUser: formData?.video ? true : false,
        };
        let eduObj = {
          education: formData.education ? formData.education : '',
          schoolCollege: formData.schoolCollege ? formData.schoolCollege : '',
          fromYear: formData.fromYear ? formData.fromYear : '',
          country: formData.country ? formData.country : '',
          province: formData.province ? formData.province : '',
          county: formData.county ? formData.county : '',
          city: formData.city ? formData.city : '',
          toYear: formData.toYear ? formData.toYear : '',
          userId: this.user.docId,
        };
        
        if (formData.aboutWork === 'new') {
          userObj.nextStep = 'done';
          this.updateUserData(userObj, 'new');
          if (formData.education && formData.education !== '') {
            this.addEductions(eduObj, 'new');
          }
        } else {
          userObj.nextStep = 2;
          this.updateUserData(userObj, 'exp');
          if (formData.education && formData.education !== '') {
            this.addEductions(eduObj, 'exp');
          }
        }
      }
    });
    return await modal.present();
  }

  private updateUserData(userObj, type) {
    console.log('webPlateForm', this.webPlateForm)
    console.log('userObj', userObj)
    console.log('type', type)
    this.globalService.showLoading();
    if (userObj.video && this.webPlateForm) {
      console.log('if condition===')
      this.userService.uploadFile(userObj.video, this.user.docId).then(res => {
        if (res) {
            this.globalService.hideLoading();
            if (type === 'new') {
              this.openThankYou('new');
            }
            userObj.video = res;
            this.subscriptions.push(
              this.userService.updateUser(userObj).subscribe(res => {
            }, error => {
              this.globalService.hideLoading();
              console.error('error', error);
            })
          );
        }
      });
    } else {
      if (type === 'new') {
        this.openThankYou('new');
      }
      console.log('userObj IN ELSE', userObj)
      this.subscriptions.push(
        this.userService.updateUser(userObj).subscribe(res => {
          if (res.status === 200) {
            this.globalService.hideLoading();
          } else {
            this.globalService.hideLoading();
          }
        }, error => {
          this.globalService.hideLoading();
          console.error('error', error);
        })
      );
    }
  }
  private addEductions(eduObj, type) {
    this.subscriptions.push(
      this.educationService.addEductions(eduObj).subscribe(res => {
        if (res.status === 200) {
          console.log('res', res);
        }
      }, error => {
        console.error('error', error)
      })
    );
  }

  public async openThankYou(openType): Promise<any> {
    this.modalCtrl.dismiss();
    let msge = '';

    if (openType === 'exp') {
      msge = 'Let us find some jobs in your area that match your skill set.';
    } else {
      msge = 'You are now registered with Train Me Hire Me. You can add skills,documents, like Safe Pass,CSCS cards and qualifications to your profile when you log back in. You will get your WhatsApp notifications based on the skills in your profile.';
    }
    const modal = await this.modalCtrl.create({
      component: ThankYouComponent,
      cssClass: openType === 'exp' ? 'newThank' : 'thankYou',
      mode: 'ios',
      backdropDismiss: false,
      componentProps: {
        msg: msge,
        type: openType
      }
    });
    modal.onDidDismiss().then((res: any) => {
      if (res && res.role === 'job') {
        this.router.navigate(['find-jobs']);
      } else {
        this.router.navigate(['find-training']);
      }
    });
    return await modal.present();
  }

  public async openExperince(): Promise<any> {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: ExperienceDetailsComponent,
      cssClass: 'aboutExp',
      mode: 'md',
      backdropDismiss: false,
      componentProps: {}
    });
    modal.onDidDismiss().then((res: any) => {
      if (res.role === 'save') {
        const formData = res.data;
        this.addExperienceCompanysData(formData, 'exp');
        const userObj = {
          nextStep: 3,
          // totalexperience: res.data.expYear,
          docId: this.user.docId
        }
        this.updateUserData(userObj, 'exp');
      } else if (res.role === 'skip') {
        const userObj = {
          nextStep: 3,
          // totalexperience: res.data.expYear,
          docId: this.user.docId
        }
        this.updateUserData(userObj, 'exp');
      }
    });
    return await modal.present();
  }

  private addExperienceCompanysData(eduObj, type) {
    eduObj.userId = this.user.docId;
    this.subscriptions.push(
      this.experienceCompanysService.addExperienceCompanys(eduObj).subscribe(res => {
        if (res.status === 200) {
          // console.log('res', res);
        }
      }, error => {
        console.error('error', error)
      })
    );
  }


  public async openSkill(): Promise<any> {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: SkillComponent,
      cssClass: 'aboutSkill',
      mode: 'md',
      backdropDismiss: false,
      componentProps: {}
    });
    modal.onDidDismiss().then((res: any) => {
      if (res.role === 'save') {
        const formData = res.data;

        const expObj = { experience: formData.experience }
        this.addExperience(expObj);

        const heltSeftyObj = { healthAndSafety: formData.healthAndSafety }
        this.addHealtSefty(heltSeftyObj);

        const skillSetObj = { skillSet: formData.skillSet }
        this.addSkillSet(skillSetObj);

        const userObj = {
          nextStep: 'done',
          docId: this.user.docId
        }
        this.updateUserData(userObj, 'exp');

        this.openThankYou('exp');

      } else if (res.role === 'skip') {
        const userObj = {
          nextStep: 'done',
          docId: this.user.docId
        }
        this.updateUserData(userObj, 'exp');
      }
    });
    return await modal.present();
  }


  private addExperience(data) {
    data.userId = this.user.docId;
    this.subscriptions.push(
      this.experienceService.addExperience(data).subscribe(res => {
        if (res.status === 200) {
          // console.log('res', res);
        }
      }, error => {
        console.error('error', error)
      })
    );
  }

  private addHealtSefty(data) {
    data.userId = this.user.docId;
    this.subscriptions.push(
      this.userHealthAndSeftyService.addHealthAndSefty(data).subscribe(res => {
        if (res.status === 200) {
          // console.log('res', res);
        }
      }, error => {
        console.error('error', error)
      })
    );
  }

  private addSkillSet(data) {
    data.userId = this.user.docId;
    this.subscriptions.push(
      this.userSkillSetService.addSkillSet(data).subscribe(res => {
        if (res.status === 200) {
          // console.log('res', res);
        }
      }, error => {
        console.error('error', error)
      })
    );
  }

  private async paymentAlert() {
    const alert = await this.alertController.create({
      message: 'Your account is not verified. Please contact Admin at info@trainmehireme.com for help',
      mode: 'ios',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Okay',
          role: 'confirm',
          handler: () => {
            this.subscriptions.push(
              this.userService.logout().subscribe( res => {
                if ( res.status === 200) {
                  this.router.navigate(['support']);
                }
              }, error => {
                console.error('Error', error);
              })
            );
          },
        }
      ],
    });
    await alert.present();
  }

}
