import { Component, OnInit,Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/api-services/users/users.service';


interface widgetCount {
  name: string;
  count: number;
  navigateTo?: string;
}

@Component({
  selector: 'app-job-view',
  templateUrl: './job-view.component.html',
  styleUrls: ['./job-view.component.scss'],
})
export class JobViewComponent implements OnInit , OnChanges{

  @Input() user: any;
  @Input() superStates: any;

  public show = false;

  public WidgetsdataCountForAdmin: widgetCount[] = [
    {
      name: 'Total jobs',
      count: 0,
      navigateTo: '/find-jobs'
    },
    {
      name: 'Total trainings',
      count: 0,
      navigateTo: '/find-training'
    },
    {
      name: 'View jobs',
      count: 0,
      // navigateTo: ''
    },
    {
      name: 'View trainings',
      count: 0,
      // navigateTo: ''
    },
  ];

  public WidgetsdataCountForSuperAdmin: widgetCount[] = [
    {
      name: 'Total Jobs',
      count: 0,
      navigateTo: '/find-jobs'
    },
    {
      name: 'Total Trainings',
      count: 0,
      navigateTo: '/find-training'
    },
    {
      name: 'Total Employers and Training Providers',
      count: 0,
      navigateTo: '/admin/admins'
    },
    {
      name: 'Total Job seeker',
      count: 0,
      navigateTo: '/admin/Users'
    },
  ];

  private subscriptions: Subscription[] = [];

  constructor(
    private usersService: UsersService,
    private router: Router,
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {

      // console.log('changes-----',changes)
    if (this.user?.userType === 'superAdmin') {
      this.WidgetsdataForSuperAdmin();

    } else if (this.user?.userType === 'admin') {
      this.WidgetsdataForAdmin();
    }
  }

  private WidgetsdataForSuperAdmin(): void {
        if (this.superStates) {
          this.WidgetsdataCountForSuperAdmin[0].count = this.superStates?.totalJob ? this.superStates?.totalJob : 0;
          this.WidgetsdataCountForSuperAdmin[1].count = this.superStates?.totalTraining ? this.superStates?.totalTraining : 0;
          this.WidgetsdataCountForSuperAdmin[2].count = this.superStates?.totalAdmin ? this.superStates?.totalAdmin : 0;
          this.WidgetsdataCountForSuperAdmin[3].count = this.superStates?.totalUser ? this.superStates.totalUser : 0;

          this.WidgetsdataCountForSuperAdmin = this.WidgetsdataCountForSuperAdmin;
          this.show = true;
        }
        // console.log('superSSSSSSSTTTTT',this.superAdminStates)
  }
  
  private WidgetsdataForAdmin(): void {
    if (this.user) {
      this.WidgetsdataCountForAdmin[0].count = this.user.states?.totalJob ? this.user.states?.totalJob : 0;
      this.WidgetsdataCountForAdmin[1].count = this.user.states?.totalTraining ? this.user.states?.totalTraining : 0;
      this.WidgetsdataCountForAdmin[2].count = this.user.states?.viewJob ? this.user.states?.viewJob : 0;
      this.WidgetsdataCountForAdmin[3].count = this.user.states?.viewTrainings ? this.user.states?.viewTrainings : 0;

      this.WidgetsdataCountForAdmin = this.WidgetsdataCountForAdmin;
      this.show = true;
    }
    
  }

  public navigateTo(url): void {
    if (url.navigateTo) {
      this.router.navigate([url.navigateTo]);
    }
  }

}
