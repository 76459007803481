
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ModalController } from '@ionic/angular';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private db: AngularFirestore,
    public modalController: ModalController,
  ) { }

  public addorderData(data: any): Observable<any> {
    data.createdDate = new Date();
    const obs = new Observable((observer) => {
      this.db.collection('Order').add(data).then(async (res: any) => {
        observer.next({
          status: 200,
          id: res.id
        });
      }).catch((error) => {
        console.log('error', error);
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public getOrdersById(id:any): Observable<any> {
    // console.log('========', id);
    return this.db.collection('Order').doc(id).snapshotChanges()
    .pipe(
      map((doc) => {
          const data: any = doc.payload.data();
          const docId = doc.payload.id;
          console.log('---------^^^^^^^-----', data)
          return { docId, ...data, };
        })
      );
  }
}
