import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CmsService } from '../api-services/cms/cms.service';
import { GlobalService } from '../services/global.service';

@Component({
  selector: 'app-mental-health',
  templateUrl: './mental-health.component.html',
  styleUrls: ['./mental-health.component.scss'],
})
export class MentalHealthComponent  {
  
  public mentalHealthData: any;
  public allMentalHealthData = [];
  private subscriptions: Subscription[] = [];
  
  constructor(
    private cmsService: CmsService,
    private router: Router,
    private globalService: GlobalService
  ) { }

  public ionViewDidEnter(): void {
    // this.globalService.showLoading();
    this.subscriptions.push(
      this.cmsService.getAllCmsData('mental-health').subscribe( res => {
        if(res.length > 0){
        this.globalService.hideLoading();
        this.allMentalHealthData = res;
        this.mentalHealthData = {};
        res.map(data => {
          if (!this.mentalHealthData[data.category]) {
            this.mentalHealthData[data.category] = [];
          }
          this.mentalHealthData[data.category].push(data);
        });
      } else {
        this.allMentalHealthData = null;
        this.mentalHealthData = null;
        this.globalService.hideLoading();
      }
      }, error => {
        this.allMentalHealthData = null;
        this.mentalHealthData = null;
        this.globalService.hideLoading();
        console.error('error',error);
      })
    );
  }

  public ionViewDidLeave(): void {
    this.subscriptions.map( subscriber => {
      subscriber.unsubscribe();
    });
  }

  public nevigateTo(data): void {
    const path = `cms-detail/${data.type}/${data.urlSlug}`;
    this.router.navigate([path]);
  }
  

}
