import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SliderComponent } from './slider/slider.component';
import { StartComponent } from './start/start.component';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/compat/firestore';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
// import * as firebase from 'firebase/compat/app';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
// import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuard } from './guard/auth.guard';
import { ConstantVariables } from '../const/constant';
import { SharedUiComponentsModule } from './shared-ui-components/shared-ui-components.module';
// import { JobsDetailsComponent } from './find-jobs/Jobs-details/Jobs-details.component';
import { MobileTabsComponent } from './shared-ui-components/mobile-tabs/mobile-tabs.component';
import { TemplatePage } from './shared-ui-components/webmenu/webmenu.page';
import { AllAdminsComponent } from './admin/all-admins/all-admins.component';
import { AboutYouComponent } from './registration/about-you/about-you.component';
import { ThankYouComponent } from './registration/thank-you/thank-you.component';
import { ExperienceDetailsComponent } from './registration/experience-details/experience-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SkillComponent } from './registration/skill/skill.component';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { CmsPage } from './admin/cms/cms.page';
import { AddEditCmsComponent } from './admin/cms/add-edit-cms/add-edit-cms.component';
import { HelpComponent } from './help/help.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { MentalHealthComponent } from './mental-health/mental-health.component';
import { FAQsComponent } from './faqs/faqs.component';
import {AngularEditorModule} from '@kolkov/angular-editor'
import { ManageAboutComponent } from './admin/cms/manage-about/manage-about.component';
import { PageNotFoundComponent } from './shared-ui-components/page-not-found/page-not-found.component';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { ApplicantProfileComponent } from './applicant-profile/applicant-profile.component';
import {Ng2TelInputModule} from 'ng2-tel-input';

import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';





// eslint-disable-next-line prefer-arrow/prefer-arrow-functions, @typescript-eslint/naming-convention
export function LanguageLoader(http: HttpClient) { return new TranslateHttpLoader(http, 'assets/i18n/', '.json'); }

import firebase from 'firebase/compat/app';
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
const app = firebase.initializeApp(environment.firebase); // For old version please use firebase.initializeApp(environment.firebase);

import { enableProdMode } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { MediaCapture } from '@ionic-native/media-capture/ngx';

// import {NgxPrintModule} from 'ngx-print';
import { File, FileEntry } from '@ionic-native/file/ngx';

import { Media, MediaObject } from '@ionic-native/media/ngx';


if (environment.production) {
  enableProdMode();
}
defineCustomElements(window);

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    SliderComponent,
    SliderComponent,
    // JobsDetailsComponent,
    MobileTabsComponent,
    TemplatePage,
    AllAdminsComponent,
    AboutYouComponent,
    ThankYouComponent,
    ExperienceDetailsComponent,
    SkillComponent,
    // AddSkillComponent,

    CmsPage,
    AddEditCmsComponent,
    HelpComponent,
    AboutUsComponent,
    MentalHealthComponent,
    FAQsComponent,

    ManageAboutComponent,
    PageNotFoundComponent,
    ApplicantProfileComponent,


    
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    MaterialModule,
    BrowserAnimationsModule,
    AngularFirestoreModule,
    HttpClientModule,
    AngularFireFunctionsModule,
    SharedUiComponentsModule,
    AngularEditorModule,
    AngularFireMessagingModule,
    Ng2TelInputModule,
    AngularFireAnalyticsModule,

    
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (LanguageLoader),
        deps: [HttpClient]
      }
    }),
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ConstantVariables,
    AuthGuard,
    Geolocation,
    NativeGeocoder,
    {
      provide: SETTINGS,
      useValue: environment.emulators ? {
        host: 'localhost:8080',
        ssl: false
      } : undefined
    },
    ScreenTrackingService ,
    UserTrackingService,
    MediaCapture,
    File,
    Media
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    if (environment.emulators) {
      const functions = getFunctions(app);
      connectFunctionsEmulator(functions, "localhost", 5001);
    }
  }
}
