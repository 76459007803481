import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Camera, CameraDirection, CameraOptions, CameraResultType, CameraSource } from '@capacitor/camera';
import { Subscription } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { CmsService } from 'src/app/api-services/cms/cms.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-manage-about',
  templateUrl: './manage-about.component.html',
  styleUrls: ['./manage-about.component.scss'],
})
export class ManageAboutComponent implements OnInit {

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '20rem',
    minHeight: '50',
    placeholder: 'Enter details here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    sanitize: false
  };

  public cmsData: any;
  public urlType = 'about';
  public openForm = 'Edit';

  public loading = false;
  public cmsForm: FormGroup;
  public provincesList = [];
  public countiesList = [];
  public cuntryList = [];
  public choosenFile: any;
  public selectedFiles: any;
  public type: any;
  public currentFileUpload: any;
  public selectedPhoto = '';
  public tackNewImg = false;
  public isImageSaved = false;
  private journalImageInput: any;
  private subscriptions: Subscription[] = [];

  count = 0
  public chackingSlag = false;
  public validation_messages: any = {

    title: [
      { type: 'required', message: 'Title is required.' },
    ],
    urlSlug: [
      { type: 'required', message: 'Url slug  is required.' },
    ],
    keywordsTag: [
      { type: 'required', message: 'Keywordstag  is required.' },
    ],
    sortOrder: [
      { type: 'required', message: 'Sort order is required.' },
    ],
    category: [
      { type: 'required', message: 'Category is required.' },
    ],
    details: [
      { type: 'required', message: 'Details is required.' },
    ],
    status: [
      { type: 'required', message: 'Status is required.' },
    ],
  }
  public categoryArr: any;
  errorSlug = false;

  constructor(
    private fb: FormBuilder,
    private cmsService: CmsService,
    private globalService: GlobalService,
  ) { }

  ngOnInit() {
    this.getData();
  }

  public ionViewDidEnter(): void { }

  public ionViewDidLeave () {
    this.subscriptions.map(subscriptions => {
      subscriptions.unsubscribe();
    })
  }

  public getData(): void {
    this.subscriptions.push(
      this.cmsService.getAllCmsData('about').subscribe(data => {
        if (data && data.length) {
          this.cmsData = data[0];
          this.crateForm();
        }
      }, error => {
        console.log('error', error);
      })
    );
  }

  public crateForm() {
    this.count = 0;
    if (this.openForm === 'Edit') {
      this.selectedPhoto = this.cmsData.cmsImage;
      this.isImageSaved = true;
    }
    this.cmsForm = this.fb.group({
      title: [this.cmsData?.title ? this.cmsData?.title : '', Validators.required],
      urlSlug: [this.cmsData?.urlSlug ? this.cmsData?.urlSlug : '', Validators.required],
      details: [this.cmsData?.details ? this.cmsData?.details : '', Validators.required],
    })
  }

  public updateCmsInfo() {
    this.loading = true;
    this.cmsForm.value.type = this.urlType;
    if (this.openForm !== 'Add') {
      if (this.tackNewImg === false) {
        const obs = {
          formData: this.cmsForm.value,
        }
        this.editCms(obs, this.cmsData.docId);
      } else {
        const obs = {
          formData: this.cmsForm.value,
          selectedPhoto: this.selectedPhoto,
        }
        this.editCms(obs, this.cmsData.docId);
      }
    }
  }

  public editCms(data, cmsId): void {
    this.globalService.showLoading();
    if (data.selectedPhoto) {
      this.cmsService.updateAdvCover(data.selectedPhoto, cmsId, 'edit', data).then(res => {
        if (res) {
          this.globalService.showToastMessage('Data update successfuly');
          this.globalService.hideLoading();
          this.loading = false;
        } else {
          this.globalService.hideLoading();
          this.loading = false;
        }
      })
    } else {
      this.subscriptions.push(
        this.cmsService.updateCmsData(data, cmsId).subscribe(res => {
          if (res.status === 200) {
            this.globalService.showToastMessage('Data update successfuly');
            this.loading = false;
            this.globalService.hideLoading();
          } else {
            this.loading = false;
            this.globalService.hideLoading();
          }
        })
      );
    }
  }


  public async takePhotoFromCamera(type: any) {
    const comeraOptions: CameraOptions = {
      allowEditing: false,
      correctOrientation: true,
      direction: CameraDirection.Front,
      quality: 95,
      resultType: CameraResultType.Base64,
      source: null,
      saveToGallery: true
    };
    if (type === 'camera') {
      comeraOptions.source = CameraSource.Camera;
    } else {
      comeraOptions.source = CameraSource.Photos;
    }
    await Camera.getPhoto(comeraOptions).then(async selectedPhoto => {
      this.journalImageInput = null;
      let imageArr: any;
      const img = new Image();
      img.src = 'data:image/jpeg;base64,' + selectedPhoto.base64String;
      img.onload = () => {
        const MAX_WIDTH = 1024;
        const MAX_HEIGHT = 1024;
        const canvas = document.createElement('canvas');
        const IMAGE_WIDTH = img.width;
        const IMAGE_HEIGHT = img.height;
        const scale = Math.min((MAX_WIDTH / IMAGE_WIDTH), (MAX_HEIGHT / IMAGE_HEIGHT));
        const iwScaled = IMAGE_WIDTH * scale;
        const ihScaled = IMAGE_HEIGHT * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        this.journalImageInput = canvas.toDataURL('image/jpeg', 0.5);
        imageArr = this.journalImageInput.split(',');
        this.selectedPhoto = imageArr;
        this.isImageSaved = true;
        this.tackNewImg = true;
      };
    }, (error: any) => {
      console.log('error', error);
    });
  }

  public viewDoc(data) {
    window.open(data.cmsImage);
  }

}
