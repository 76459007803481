import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { GlobalService } from 'src/app/services/global.service';
import { HttpClient } from '@angular/common/http';
import firebse from 'firebase/compat/app'
import { AngularFireFunctions } from '@angular/fire/compat/functions';
// import { AngularFireFunctions } from '@angular/fire/functions';

@Injectable({
  providedIn: 'root'
})
export class JobsService {
  private jobTypeValue: any;
  private experienceTypeValue: any;

  constructor(
    private db: AngularFirestore,
    private globalService: GlobalService,
    private angularFireFunctions: AngularFireFunctions,

  ) { }
  // public getAllData(fieldName, tableName, jobData): Observable<any> {
  //   return this.db.collection(tableName, ref => ref.where(fieldName, '==', jobData)).snapshotChanges()
  //     .pipe(
  //       map((actions) => {
  //         return actions.map(doc => {
  //           const data: any = doc.payload.doc.data();
  //           const docId = doc.payload.doc.id;
  //           // console.log('data~~~~~~~~~~~~~~~~~~',data)
  //           return { docId, ...data, };
  //         });
  //       })
  //     );
  // }

  public addJobsData(data: any): Observable<any> {
    data.createdDate = new Date();
    const obs = new Observable((observer) => {
      this.db.collection('Jobs').add(data).then(async (res: any) => {
        observer.next({
          status: 200,
          id: res.id
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public getAdminJobData(docId): Observable<any> {
    return this.db.collection('Jobs', ref => ref.where('adminId', '==', docId).where('expireJob', '==', false).orderBy('createdDate', 'desc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            data.timeAgo = this.globalService.calTime(data.createdDate);
            const docId = doc.payload.doc.id;
            return { docId, ...data, };
          });
        })
      );
  }
  public getExpairedJobData(docId): Observable<any> {
    return this.db.collection('Jobs', ref => ref.where('adminId', '==', docId).where('expireJob', '==', true).orderBy('createdDate', 'desc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            data.timeAgo = this.globalService.calTime(data.createdDate);
            const docId = doc.payload.doc.id;
            return { docId, ...data, };
          });
        })
      );
  }
  public getAllExpairedJobData(): Observable<any> {
    return this.db.collection('Jobs', ref => ref.where('expireJob', '==', true).orderBy('createdDate', 'desc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            data.timeAgo = this.globalService.calTime(data.createdDate);
            const docId = doc.payload.doc.id;
            return { docId, ...data, };
          });
        })
      );
  }

  public getAllJobData(): Observable<any> {
    return this.db.collection('Jobs', ref => ref.orderBy('createdDate', 'desc').where('expireJob', '==', false)).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            data.timeAgo = this.globalService.calTime(data.createdDate);
            const docId = doc.payload.doc.id;
            return { docId, ...data, };
          });
        })
      );
  }

  public updateJobData(data, docId): Observable<any> {
    const obj = new Observable((observer) => {
      this.db.collection('Jobs').doc(docId).update(data).then(res => {
        observer.next({
          status: 200,
          message: 'jobData update successfully',
        })
      }).catch((error => {
        observer.next({
          status: 400,
          message: 'error'
        })
      }))
    })
    return obj
  }

  public applyFilterData(data): Observable<any> {
    const expTy = data.experienceType.values();
    for (const data of expTy) {
      this.experienceTypeValue = data;
    }
    return this.db.collection('Jobs', ref => ref.where('jobType', 'in', data.jobType)
      .where('experience', '==', this.experienceTypeValue)
      .where('province', '==', data.province)
      .where('country', '==', data.country)
    ).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // console.log('data~~~~~~~~~~~~~~~~~~',docId)
            return { docId, ...data, };
          });
        })
      );
  }

  public applyExtraFilterData(userId, filterId, data): Observable<any> {
    const obs = new Observable((observer) => {
      this.db.collection('Users').doc(userId).collection('Jobs-filter').doc(filterId).update(data).then(async (res: any) => {
        // console.log('<<<<<<--------------', res);
        observer.next({
          status: 200,
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public addApplicantJob(DocId, data: any): Observable<any> {

     const obj = new Observable(obs => {
       this.db.collection('Jobs').doc(DocId).collection('Applicants').add(data).then(res => {
         
        //  console.log('-----------res-------', res.id);
        obs.next({
          status: 200,
          message: 'Applicants add successfully in job',
          id:res.id
        })
      }).catch((error => {
        obs.next({
          status: 400,
          message: 'error'
        })
      }))
    });

    return obj;
  }

  

  public updateApplicantJob(DocId, data: any,id): Observable<any> {

     const obj = new Observable(obs => {
       this.db.collection('Jobs').doc(DocId).collection('Applicants').doc(id).update(data).then(res => {
         
        //  console.log('-----------res-------', res.id);
        obs.next({
          status: 200,
          message: 'Applicants add successfully in job',
          // id:res.id
        })
      }).catch((error => {
        obs.next({
          status: 400,
          message: 'error'
        })
      }))
    });

    return obj;
  }
  public addMessageJob(DocId, data: any, id): Observable<any> {
    // console.log('---------------', DocId, data, id);

     const obj = new Observable(obs => {
       this.db.collection('Jobs').doc(DocId).collection('Applicants').doc(id).collection('Messages').add(data).then(res => {
         
        //  console.log('------sssss-----res-------', res.id);
        obs.next({
          status: 200,
          message: 'Applicants add successfully in job',
          id:res.id
        })
      }).catch((error => {
        obs.next({
          status: 400,
          message: 'error'
        })
      }))
    });

    return obj;
  }
  // public updateMessageJob(DocId, data: any, id, msgid): Observable<any> {

  //    const obj = new Observable(obs => {
  //      this.db.collection('Jobs').doc(DocId).collection('Applicants').doc(id).collection('Messages').doc(msgid).update(data).then(async (res: any) => {
         
  //       //  console.log('-----------res-------', res.id);
  //       obs.next({
  //         status: 200,
  //         message: 'Applicants add successfully in job',
  //         id:res.id
  //       })
  //     }).catch((error => {
  //       obs.next({
  //         status: 400,
  //         message: 'error'
  //       })
  //     }))
  //   });

  //   return obj;
  // }


  public getApplyJob(DocId, userDocId): Observable<any> {
    return this.db.collection('Jobs').doc(DocId).collection('Applicants',ref =>ref.where('currentUserDocId', '==', userDocId)).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // console.log('data~~~~~~~~~~~~~~~~~~',data)
            return { docId, ...data, };
          });
        })
        );
      }


  // public applyExtraFilterData(data): Observable<any> {
  //   console.log('-----------SERVICE-------', data);
  //   return;
  //   if (data) {
  //     const eventAllData = [];
  //     return this.db.collection('Jobs', ref => ref.
  //       where('jobType', 'in', data.jobType)
  //       .where('country', '==', data.country)
  //       .where('province', '==', data.province)
  //       ).snapshotChanges().pipe(
  //         mergeMap(res => {
  //           res.map(eventM => {
  //             const eventData: any = eventM.payload.doc.data();
  //             console.log('-----------++++++++++', eventData)
  //             eventAllData.push(eventData);
  //           })
  //           return this.getOrganisations(eventAllData);
  //         })
  //       )
  //   }
  //   return this.getOrganisations();
  // }
  // private getOrganisations(allData?:any[]): Observable<any> {
  //   console.log('==============>>>>>>>>', allData);
  //   return this.db.collection('organisation', ref => {
  //     let query: firebase.default.firestore.CollectionReference | firebase.default.firestore.Query = ref;

  //     query = query.where('experience', 'in', allData)
  //     return query
  //   }).snapshotChanges().pipe(map((actions: any) => {
  //     return {
  //       status: 200,
  //       message: 'Get all organisation successfully.',
  //       data: actions.map((doc: any) => {
  //         const data = doc.payload.doc.data();
  //         data.docId = doc.payload.doc.id;
  //         console.log('YOOOOOOOOOOOOOOOOOO', ...data);
  //         return { ...data }
  //       })
  //     }
  //   }));
  // }



  public getJobById(id): Observable<any> {
    // console.log('jobDocIdin service',id)
    // return this.db.collection('Jobs', ref => ref.where('docId', '==', id).where('expireJob', '==', false)).snapshotChanges()
    //   .pipe(
    //     map((actions) => {
    //       return actions.map(doc => {
    //         const data: any = doc.payload.doc.data();
    //         const docId = doc.payload.doc.id;
    //         return { docId, ...data, };
    //       });
    //     })
    //   );

      return this.db.collection('Jobs', ref => ref.where('expireJob','==', false)).doc(id).snapshotChanges()
      .pipe(
        map((actions) => {
          const data: any = actions.payload.data();
          const docId = actions.payload.id;
          return { docId, ...data}
        })
      );
  }

  public async viewJobByUser(jobData) {
    const viewJob = this.angularFireFunctions.httpsCallable('viewJob');
    await viewJob({ jobData: jobData }).toPromise();
    return viewJob;

  }

}