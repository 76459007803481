
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ModalController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


@Injectable({
  providedIn: 'root'
})
export class CmsCategoryService {

  public response: { status: number; message: string; data: any; };

  constructor(
    private db: AngularFirestore,
    public modalController: ModalController,
  ) { }

  public getAllCmsCategoryData(type): Observable<any> {
    return this.db.collection('CmsCategory', ref => ref.where('categoryType', '==', type).orderBy('sortIndex', 'desc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            return { docId, ...data, };
          });
        })
      );
  }

  public addCmsCategoryData(data: any): Observable<any> {
    data.createdDate = new Date();
    const obs = new Observable((observer) => {
      this.db.collection('CmsCategory').add(data).then(async (res: any) => {
        observer.next({
          status: 200,
          id: res.id
        });
      }).catch((error) => {
        console.log('error', error);
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public updateCmsCategoryData(data: any, csmId): Observable<any> {
    data.updatedDate = new Date();
    const obs = new Observable((observer) => {
      this.db.collection('CmsCategory').doc(csmId).update(data).then(async (res: any) => {
        observer.next({
          status: 200,
          id: res.id
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public deleteCmsCategory(docId): Observable<any> {
    const obs = new Observable((observer) => {
      this.db.collection('CmsCategory').doc(docId).delete().then(async () => {
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

}
