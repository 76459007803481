import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


@Injectable({
  providedIn: 'root'
})
export class UserHealthAndSeftyService {

  constructor(
    private db: AngularFirestore,

  ) { }

  public getAllHealthAndSefty(userId): Observable<any> {
    return this.db.collection('Users').doc(userId).collection('HealthAndSefty').snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            return { docId, ...data, };
          });
        })
      );
  }

  public addHealthAndSefty(data: any): Observable<any> {
    data.createdDate = new Date();
    const obs = new Observable((observer) => {
      this.db.collection('Users').doc(data.userId).collection('HealthAndSefty').add(data).then(async (res: any) => {
        observer.next({
          status: 200,
          id: res.id
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }


  public updateHealthAndSefty(data): Observable<any> {
    const obj = new Observable( (observer) => {
      this.db.collection('Users').doc(data.userId).collection('HealthAndSefty').doc(data.docId).update(data).then(res => {
        observer.next({
          status : 200,
          message : 'Health and sefty update successfully'
        })
      }).catch((error => {
        observer.next({
          status : 400,
          message : 'error'
        })
      }))
    })
    return obj
  }



}