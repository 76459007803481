import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ActionSheetController } from '@ionic/angular';

@Component({
  selector: 'app-mobile-tabs',
  templateUrl: './mobile-tabs.component.html',
  styleUrls: ['./mobile-tabs.component.scss'],
})
export class MobileTabsComponent implements OnChanges {
  @Input() user: any;
  arr = [];
  activeMore = false;
  constructor(
    private actionSheetController: ActionSheetController,
    private router: Router
  ) { }

  public ngOnChanges() {
    this.arr = [];
    if (this.user && this.user.userType === 'user') {
      this.arr = [
        {
          text: 'About',
          icon: 'caret-forward-outline',
          cssClass: 'settingActionSheet',
          handler: () => {
            this.navigateTo('/about');
          }
        },
        {
          text: 'FAQs',
          icon: 'caret-forward-outline',
          cssClass: 'settingActionSheet',
          handler: () => {
            this.navigateTo('/FAQs');
          }
        },
        // {
        //   text: 'Help',
        //   icon: 'caret-forward-outline',
        //   cssClass: 'settingActionSheet',
        //   handler: () => {
        //     this.navigateTo('/help');
        //   }
        // },
        {
          text: 'Wellbeing',
          icon: 'caret-forward-outline',
          cssClass: 'settingActionSheet',
          handler: () => {
            this.navigateTo('/mental-health');
          }
        }, 
        {
          text: 'Support',
          icon: 'caret-forward-outline',
          cssClass: 'settingActionSheet',
          handler: () => {
            this.navigateTo('/support');
          }
        }, 
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ];
    } else if (this.user && this.user.userType === 'admin') {
      this.arr = [
        {
          text: 'Post Jobs',
          icon: 'caret-forward-outline',
          cssClass: 'settingActionSheet',
          handler: () => {
            this.navigateTo('/post-jobs');
          }
        },
        {
          text: 'Post Trainings',
          icon: 'caret-forward-outline',
          cssClass: 'settingActionSheet',
          handler: () => {
            this.navigateTo('/post-training');
          }
        },
        {
          text: 'About',
          icon: 'caret-forward-outline',
          cssClass: 'settingActionSheet',
          handler: () => {
            this.navigateTo('/about');
          }
        },
        {
          text: 'FAQ',
          icon: 'caret-forward-outline',
          cssClass: 'settingActionSheet',
          handler: () => {
            this.navigateTo('/FAQs');
          }
        },
        // {
        //   text: 'Help',
        //   icon: 'caret-forward-outline',
        //   cssClass: 'settingActionSheet',
        //   handler: () => {
        //     this.navigateTo('/help');
        //   }
        // },
        {
          text: 'Wellbeing',
          icon: 'caret-forward-outline',
          cssClass: 'settingActionSheet',
          handler: () => {
            this.navigateTo('/mental-health');
          }
        },
        {
          text: 'Support',
          icon: 'caret-forward-outline',
          cssClass: 'settingActionSheet',
          handler: () => {
            this.navigateTo('/support');
          }
        },
        {
          text: 'Cancel',
          role: 'cancel',
          data: {
            action: 'cancel',
          },
        },
      ];

    } else if (this.user && this.user.userType === 'superAdmin') {
      this.arr = [
      {
          text: 'Job Types',
        icon: 'caret-forward-outline',
        cssClass: 'settingActionSheet',
        handler: () => {
          this.navigateTo('/admin/manage-jobs');
        }
      },
      {
          text: 'Experience',
        icon: 'caret-forward-outline',
        cssClass: 'settingActionSheet',
        handler: () => {
          this.navigateTo('/admin/manage-experience');
        }
      },
      {
          text: 'Health and Safety',
        icon: 'caret-forward-outline',
        cssClass: 'settingActionSheet',
        handler: () => {
          this.navigateTo('/admin/manage-health-safety');
        }
      },
      {
        text: 'Skill Set',
        icon: 'caret-forward-outline',
        cssClass: 'settingActionSheet',
        handler: () => {
          this.navigateTo('/admin/manage-skills');
        }
      },
      {
        text: 'Contact us',
        icon: 'caret-forward-outline',
        cssClass: 'settingActionSheet',
        handler: () => {
          this.navigateTo('/admin/cms/contact-us');
        }
      },
      {
        text: 'Cancel',
        role: 'cancel',
        data: {
          action: 'cancel',
        },
      }
      ];
    }
  }


    userArr = [
    {
        text: 'Pending Employers and Training Providers',
      icon: 'caret-forward-outline',
      cssClass: 'settingActionSheet',
      handler: () => {
        this.navigateTo('/admin/pending-admin');
      }
    },
    {
        text: 'Employers and Training Providers',
      icon: 'caret-forward-outline',
      cssClass: 'settingActionSheet',
      handler: () => {
        this.navigateTo('/admin/admins');
      }
    },
    {
        text: 'Job Seeker',
      icon: 'caret-forward-outline',
      cssClass: 'settingActionSheet',
      handler: () => {
        this.navigateTo('/admin/Users');
      }
    },
    {
      text: 'Cancel',
      role: 'cancel',
      data: {
        action: 'cancel',
      },
    }
    ];

  public navigateTo(url) {
    this.router.navigate([url]);
  }


  async selectActionSheet(val) {
    if(val){
      this.activeMore = true;
      const actionSheet = await this.actionSheetController.create({
        header: 'Action heading',
        mode: 'ios',
        // subHeader: 'Select any of action below to proceed.',
        // cssClass: 'basicActionSheet',
        cssClass: 'left-align-buttons',
        buttons: this.arr,
        backdropDismiss: false
      });
      await actionSheet.present();
    } else {
      this.activeMore = false;
    }
  }

  async selectUserAction(val) {
    if(val){
      this.activeMore = true;
      const actionSheet = await this.actionSheetController.create({
        header: 'Action heading',
        mode: 'ios',
        // subHeader: 'Select any of action below to proceed.',
        // cssClass: 'basicActionSheet',
        cssClass: 'left-align-buttons',
        buttons: this.userArr,
        backdropDismiss: false
      });
      await actionSheet.present();
    } else {
      this.activeMore = false;
    }
  }
}
