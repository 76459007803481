import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { CmsService } from '../../api-services/cms/cms.service';

@Component({
  selector: 'app-cms-detail',
  templateUrl: './cms-detail.component.html',
  styleUrls: ['./cms-detail.component.scss'],
})
export class CmsDetailComponent implements OnInit {

  public cmsData: any;
  public type: any;
  public slag: any;
  private subscriptions: Subscription[] = [];

  constructor(
    private cmsService: CmsService,
    private activatedRoute: ActivatedRoute,
    private globalService: GlobalService,
    private router : Router,
    private sanitizer: DomSanitizer
  ) { }

  ionViewWillEnter() {
    this.globalService.hideLoading();
    this.subscriptions.push(
      this.activatedRoute.paramMap.subscribe(
        (params: ParamMap) => {
          this.globalService.hideLoading();
          this.slag = params.get('slag');
          this.type = params.get('type');
          this.getData(this.type, this.slag);
        },
      )
    );

  }

  public ionViewDidLeave(): void {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    });
  }

  ngOnInit() { }

  getData(type, slag) {
    // this.globalService.showLoading();
    this.subscriptions.push(
      this.cmsService.getCmsDataBySlag(type, slag).subscribe(res => {
        if (res.length) {
          this.cmsData = res[0];
          this.globalService.hideLoading();
        } else {
          this.router.navigate(['page-not-found/cms']);
          this.globalService.hideLoading();
        }
      }, error => {
        this.globalService.hideLoading();
        console.log('error', error);
      })
    );

  }

  transformYourHtml(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

  public goBack(type): void {
    this.router.navigate([`${type}`]);
  }


}
