import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class DocumentWalletService {
  docRes: any;
  file: any;
  docImage: any;

  constructor(
    private db: AngularFirestore,
    private storage: AngularFireStorage
  ) { }

  async uploadFile(selectedPhoto, id, data){
    console.log(selectedPhoto, id,data);
    const storageRef = firebase.storage().ref();
    return new Promise(function (resolve, reject) {
      let filePath = ``;
      if(data?.formData) {
        if(data?.formData.docType === 'education') {
          filePath = `${'Users'}/${'Education'}/${id}`
        } else if(data?.formData.docType === 'company') {
          filePath = `${'Users'}/${'Company'}/${id}`
        } else if(data?.formData.docType === 'other') {
          filePath = `${'Users'}/${'Other'}/${id}`
        }
      } else {
        if(data?.docType === 'education') {
          filePath = `${'Users'}/${'Education'}/${id}`
        } else if(data?.docType === 'company') {
          filePath = `${'Users'}/${'Company'}/${id}`
        } else if(data?.docType === 'other') {
          filePath = `${'Users'}/${'Other'}/${id}`
        }

      }
      const task = storageRef.child(filePath).put(selectedPhoto);

      task.on(
        "state_changed",
        function progress(snapshot) {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('-----------%', percentage);
          // resolve(percentage);
        },
        

        function error(err) {
          reject(err);
        },

        async function complete() {
          function progress(snapshot) {
            const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('-----------%', percentage);
            // resolve(percentage);
          }

          const imageURL = await task.snapshot.ref.getDownloadURL();
          resolve(imageURL);
          // console.log('---------+++++++++++++---------', imageURL)
        }
      );
    });
  }
  
  public updateImageOnDetails(docimage, documentId, userId): Observable<any>{
    // console.log('-------------', docimage, documentId, userId);
    const obj = new Observable( (observer) => {
      this.db.collection('Users').doc(userId).collection('User-document').doc(documentId).update(docimage).then(res => {
        observer.next({
          status : 200,
          message : 'Eductions document update successfully'
        })
      }).catch((error => {
        observer.next({
          status : 400,
          message : 'error'
        })
      }))
    })
    return obj
  }
  
  public addDocData(data: any, id): Observable<any> {
    // console.log('**************', data, id);
    if(data.formData.docType === 'education'){
      delete data.formData['companyName'];
      data.formData.educationId = data.educationDocId;
    } else if(data.formData.docType === 'company') {
      delete data.formData['educationName'];
      data.formData.companyId = data.companyDocId;
    } else{
      delete data.formData['companyName'];
      delete data.formData['educationName'];
      
      data.formData.otherId = data.otherDocId;
    }
    data.formData.createdDate = new Date();
    const obs = new Observable((observer) => {
      this.db.collection('Users').doc(id).collection('User-document').add(data.formData).then(res => {
        observer.next({
          status: 200,
          id: res.id
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public getDocumentData(id): Observable<any> {
    return this.db.collection('Users').doc(id).collection('User-document').snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // console.log('data~~~~~~~~~~~~~~~~~~',data)
            return { docId, ...data, };
          });
        })
        );
  }

  public deleteDocData(id, data): Observable<any>{
    // console.log('******DELETE DATA*******',id, data);
    const obs = new Observable((observer) => {
     this.db.collection('Users').doc(id).collection('User-document').doc(data.docId).delete().then(async () => {

      let basePath = ``;
      if(data?.docType === 'education') {
        basePath = `${'Users'}/${'Education'}/${id}`
      } else if(data?.docType === 'company') {
        basePath = `${'Users'}/${'Company'}/${id}`
      } else if(data?.docType === 'other') {
        basePath = `${'Users'}/${'Other'}/${id}`
      }
      
        firebase.storage().ref().child(basePath).delete();

     }).catch((error) => {
       observer.next({
         message: error.message ? error.message : 'There is some issue...',
         status: error.code ? error.code : 400,
       });
     });
   });
   return obs;
 }

 public updateDocData(data): Observable<any> {
  // console.log('------------', data);
  data.updatedDate = new Date();
  const obs = new Observable((observer) => {
    this.db.collection('Users').doc(data.userId).collection('User-document').doc(data.documentId).update(data.formData).then(async (res: any) => {
      observer.next({
        status: 200,
      });
    }).catch((error) => {
      observer.next({
        message: error.message ? error.message : 'There is some issue...',
        status: error.code ? error.code : 400,
      });
    });
  });
  return obs;
}




}
