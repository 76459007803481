import { Component, OnDestroy, OnInit, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CmsService } from '../api-services/cms/cms.service';
import { GlobalService } from '../services/global.service';


@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {

  public aboutUsData: any;
  private subscriptions: Subscription[] = [];
  html: any;

  constructor(
    private cmsService: CmsService,
    private sanitizer: DomSanitizer,
    private globalService: GlobalService
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.cmsService.getAllCmsData('about').subscribe(res => {
        if (res) {
          this.globalService.hideLoading();
          this.aboutUsData = res[0];
        } else {
          this.globalService.hideLoading();
        }
        // this.html = this.sanitizer.bypassSecurityTrustHtml(this.aboutUsData.details);
      }, error => {
        console.log('error', error);
      })
    );
  }

  public ionViewDidLeave() {
    this.subscriptions.map(subscriptions => {
      subscriptions.unsubscribe();
    })
  }

  transformYourHtml(htmlTextWithStyle) {
    return this.sanitizer.bypassSecurityTrustHtml(htmlTextWithStyle);
  }

}
