import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ModalController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ManageTableService {
  public authenticationStatus: Observable<any>;
  authState = new BehaviorSubject(false);
  response: { status: number; message: string; data: any; };
  user: any;

  constructor(
    private db: AngularFirestore,
    public modalController: ModalController,
    private http: HttpClient
  ) { }

  public addTableData(data: any, tableName): Observable<any> {
    data.createdDate = new Date();
    const obs = new Observable((observer) => {
      this.db.collection(tableName).add(data).then(async (res: any) => {
        observer.next({
          status: 200,
          id: res.id
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public updateTableData(data: any, tableName): Observable<any> {
    console.log('data------------->>>', data, tableName);
    data.updatedDate = new Date();
    const obs = new Observable((observer) => {
      this.db.collection(tableName).doc(data.docId).update(data).then(async (res: any) => {
        observer.next({
          status: 200,
          id: res.id
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public getAllData(tableName): Observable<any> {
    console.log('tableName----+++++++++++-----', tableName);
      return this.db.collection(tableName, ref => ref.orderBy('sortIndex', 'asc')).snapshotChanges()
        .pipe(
          map((actions) => {
            return actions.map(doc => {
              const data: any = doc.payload.doc.data();
              const docId = doc.payload.doc.id;
              // console.log('data~~~~~~~~~~~~~~~~~~',data)
              return { docId, ...data, };
            });
          })
        );
    
    }

  
  public getAllQualificationData(tableName): Observable<any> {
    return this.db.collection(tableName).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // console.log('data~~~~~~~~~~~~~~~~~~',data)
            return { docId, ...data, };
          });
        })
      );
  }

  public async updateAdvCover(selectedPhoto, id, tablename, type?: any, formData?: any): Promise<any> {
    console.log('selectedphot', selectedPhoto);
    const response = await fetch(selectedPhoto);
    const blob = await response.blob();

    const metadata = {
      contentType: 'image/jpeg',
    };

    const photoUrl = `${tablename}/${id}.jpeg`;
    const ref = firebase
      .storage()
      .ref()
      .child(photoUrl);
    ref.put(blob, metadata).then(obj => {
      ref.getDownloadURL().then(async (url) => {
        const data = {
          photo: selectedPhoto ? url : '',
        };
        // if(type === 'add'){
        // }
        if (type === 'add') {
          await this.updateImageOnDetails(id, data, tablename).subscribe(res => {
            console.log('----------------', res);
          });
          // await this.updateJobAdminId( id, tablename).then();
        } else {
          formData.photo = url;

          console.log('formDAtga', formData);
          this.updateTableData(formData, tablename).subscribe(res => {

          }, error => {
            console.error('error', error);
          })
          // this.updateTableData()
        }
      });
    });
  }

  private updateImageOnDetails(userDocId, data, tableName): Observable<any> {
    // console.log('----------------', userDocId, data, tableName);
    const obj = new Observable((observer) => {
      this.db.collection(tableName).doc(userDocId).update(data).then(res => {
        observer.next({
          status: 200,
          message: 'image update successfully'
        })
      }).catch((error => {
        observer.next({
          status: 400,
          message: 'error'
        })
      }))
    })
    return obj
  }

  public async updateJobAdminId(id, tablename: any) {
    this.db.collection(tablename).doc(id).update({ docId: id }).then((res) => {

    }, error => {
      console.log(error, 'error');
      return error.message;
    });
  }

  public deleteData(data, tablename: any): Observable<any> {
    // console.log('******DELETE DATA*******', data, tablename);
    const obs = new Observable((observer) => {
      this.db.collection(tablename).doc(data.docId).delete().then(async () => {
        const basePath = `${tablename}/${data.docId}.jpeg`;
        firebase.storage().ref().child(basePath).delete();
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }


  public getSubHealthAndSafetyDataById(tableName, id): Observable<any> {
    return this.db.collection(tableName, ref => ref.where('healthAndSafetyDocId', '==', id).orderBy('sortIndex', 'asc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // console.log('data~~~~~~~~~~~~~~~~~~',data)
            return { docId, ...data, };
          });
        })
      );
  }

  public getSubSkillDataById(tableName, id): Observable<any> {
    return this.db.collection(tableName, ref => ref.where('skillDocId', '==', id).orderBy('sortIndex', 'asc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // console.log('data~~~~~~~~~~~~~~~~~~',data)
            return { docId, ...data, };
          });
        })
      );
  }



  public getDataByJobType(tableName, id): Observable<any> {
    return this.db.collection(tableName, ref => ref.where('jobsDocId', '==', id).orderBy('sortIndex', 'asc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // console.log('data~~~~~~~~~~~~~~~~~~',data)
            return { docId, ...data, };
          });
        })
      );
  }


  public getAllSelectedData(tableName, selectedJobType): Observable<any> {
    return this.db.collection(tableName, ref => ref.where('jobType', '==', selectedJobType).orderBy('sortIndex', 'asc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // console.log('data~~~~~~~~~~~~~~~~~~',data)
            return { docId, ...data, };
          });
        })
      );
  }
  public getAllSelectedFilterData(tableName, selectedOption, selectedOptionType, selectedJobType): Observable<any> {
    return this.db.collection(tableName, ref => ref.where(selectedOption, '==', selectedOptionType).where('jobType', '==', selectedJobType).orderBy('sortIndex', 'asc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // console.log('data~~~~~~~~~~~~~~~~~~',data)
            return { docId, ...data, };
          });
        })
      );
  }


}
