import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-payment-error',
  templateUrl: './payment-error.component.html',
  styleUrls: ['./payment-error.component.scss']
})
export class PaymentErrorComponent implements OnInit {
  height: number;

  constructor(
    private router: Router,

  ) { 
    this.height = window.innerHeight - 100;
  }

  ngOnInit(): void {
  }

  public goToHome(){
    this.router.navigate(['start']);
  }

}
