import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from '../api-services/users/users.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard implements CanActivate {

  constructor(
    public userService: UsersService,
    public router: Router,
  ) { }

  
  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    return this.userService.user$.pipe(
      map((user: any) => {
        if (next && user && user.userType === 'admin') {
          return true;
        } else {
          this.router.navigate(['/start']);
          return false;
        }
      }),
    );
  }
  
  
}
