/* eslint-disable @typescript-eslint/member-ordering */
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertController, PopoverController} from '@ionic/angular';
import { UsersService } from 'src/app/api-services/users/users.service';
import {Location } from '@angular/common';
interface widgetCount {
  item: string,
  navigateTo: string,
  cssClass?:boolean
}
@Component({
  selector: 'app-webmenu',
  templateUrl: './webmenu.page.html',
  styleUrls: ['./webmenu.page.scss'],
  providers: [Location]
})
export class TemplatePage implements OnInit {
  
  @Input() title: string;
  @Input() user: any;
  @Input() superStates: any;
  dropdown = false;
  
  @ViewChild('productbtn', { read: ElementRef }) productbtn: ElementRef;
  @ViewChild('popover') popover: { event: string | Event; };
  @ViewChild('popoverFirst') popoverFirst: { event: string | Event; };
  @ViewChild('popoverUser') popoverUser: { event: string | Event; };

  public isOpen = false;
  public isOpenFirst = false;
  public showUrl = false;
  public getUrlFirst: any;
  public getUrl: any;
  public currentUrl: any;
  public getUserUrl: any;
  public isOpenUser = false;

  public listItem: widgetCount[] = [
    // {
    //   item: 'Manage Contact ',
    //   navigateTo: '/admin/cms/contact-us'
    // },
    {
      item: 'Manage About',
      navigateTo: '/admin/cms/about',
      cssClass: false
    },
    // {
    //   item: 'Manage Help',
    //   navigateTo: '/admin/cms/help',
    //   cssClass: false
    // },
    {
      item: 'Manage FAQs',
      navigateTo: '/admin/cms/FAQs',
      cssClass: false
    },
    {
      item: 'Manage Wellbeing',
      navigateTo: '/admin/cms/mental-health',
      cssClass: false
    },
    {
      item: 'CMS category',
      navigateTo: '/admin/cms-category',
      cssClass: false
    },
  ];

  public listManage: widgetCount[] = [
    {
      item: 'Job Type',
      navigateTo: '/admin/manage-jobs',
      cssClass: false
    },
    {
      item: 'Experience',
      navigateTo: '/admin/manage-experience',
      cssClass: false
    },
    {
      item: 'Health and Safety',
      navigateTo: '/admin/manage-health-safety',
      cssClass: false
    },
    {
      item: 'Skill Set',
      navigateTo: '/admin/manage-skills',
      cssClass: false
    },
   
    {
      item: 'Qualification',
      navigateTo: '/admin/manage-qualification',
      cssClass: false
    },
    {
      item: 'Sector',
      navigateTo: '/admin/manage-sector',
      cssClass: false
    },
    {
      item: 'Custom message',
      navigateTo: '/admin/costome-message',
      cssClass: false
    },
  ];


  public listUser: widgetCount[] = [
    {
      item: 'Pending Employers and Training Providers',
      navigateTo: '/admin/pending-admin',
      cssClass: false
    },
    {
      item: 'Employers and Training Providers',
      navigateTo: '/admin/admins',
      cssClass: false
    },
    {
      item: 'Job Seeker',
      navigateTo: '/admin/Users',
      cssClass: false
    }
  ];

  public selectedPopup = '';
 
  
  constructor(
    private usersService: UsersService,
    private alertCtr: AlertController,
    private popoverController: PopoverController,
    private router: Router,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        this.currentUrl = val.url;
        this.listItem.map(res => {
          if(res.navigateTo === val.url){
            res.cssClass = true;
          } else {
            res.cssClass = false;
          }
        })
        this.listManage.map(res => {
          if(res.navigateTo === val.url){
            res.cssClass = true;
          } else {
            res.cssClass = false;
          }
        })
        this.listUser.map(res => {
          if(res.navigateTo === val.url){
            res.cssClass = true;
          } else {
            res.cssClass = false;
          }
        })
      }
    });
  }

  hideDropdown(event) {
    const xTouch = event.clientX;
    const yTouch = event.clientY;

    const rect = this.productbtn.nativeElement.getBoundingClientRect();
    const topBoundary = rect.top + 2;
    const leftBoundary = rect.left + 2;
    const rightBoundary = rect.right - 2;

    if (xTouch < leftBoundary || xTouch > rightBoundary || yTouch < topBoundary) {
      this.dropdown = false;
    }
  }

  async logout(){

    const alert = await this.alertCtr.create({
      header: '',
      mode: 'ios',
      message: 'Logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Okay',
          handler: () => {
            this.usersService.logout();
          }
        }
      ]
    });
    return await alert.present();



  }

  public presentPopover(e: Event, type): void {

    this.selectedPopup = type;
    this.currentUrl = this.currentUrl;
    this.showUrl = true;
    if ( type === 'manage') {
      this.popoverFirst.event = e;
      this.isOpenFirst = true;
      this.isOpen = false;
      this.ref.detectChanges();
    } else if ( type === 'setting') {
      this.popover.event = e;
      this.isOpen = true;
      this.isOpenFirst = false;
      this.ref.detectChanges();
    } else if ( type === 'user') {
      this.popoverUser.event = e;
      this.isOpen = false;
      this.isOpenUser = true;
      this.isOpenFirst = false
      this.ref.detectChanges();
    }
  }

  removePopup(){
    this.selectedPopup = '';
  }

  public ActiveTopMenuFirst(url): void {
    this.showUrl = false;
    this.getUrlFirst = '';
    if (url.navigateTo) {
      this.getUrlFirst = url.navigateTo;
    }
    return this.getUrlFirst;
  }
  public ActiveTopUserMenu(url): void {
    // this.showUrl = false;
    this.getUserUrl = '';
    if (url.navigateTo) {
      this.getUserUrl = url.navigateTo;
    }
    return this.getUserUrl;
  }

  public ActiveTopMenu(url): void {
    this.getUrl = '';
    if (url.navigateTo) {
      this.getUrl = url.navigateTo;
    }
    return this.getUrl;
  }


  public async navigatTo(url) {
    this.router.navigate([url.navigateTo]);
    await this.popoverController.dismiss();
  }
 
  public redirectTo() {
    if(this.user && this.user.userType === 'superAdmin') {
      this.router.navigate(['admin/dashboard']);
    } else {
      this.router.navigate(['/news-feed']);
    }
  }

  gotTowebsite(){
    window.location.replace('https://trainmehireme.com');
  }
  
}
