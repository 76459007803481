import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrderService } from 'src/app/api-services/order/order.service';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})

export class ThankyouComponent implements OnInit {
  public sessionId: any = '';
  public orderData: any;
  private subscriptions: Subscription[] = [];
  public user: any;
  public invoicResStatus = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private angularFireFunctions: AngularFireFunctions,
    private orderService: OrderService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(
      (params: ParamMap) => {
        this.sessionId = params.get('sessionId');
        this.goCheckInvoice();
      },
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      if (sub && !sub.closed) {
        sub.unsubscribe();
      }
    });
  }

  public goCheckInvoice(): void {
    this.subscriptions.push(
      this.orderService.getOrdersById(this.sessionId).subscribe(res => {
        this.orderData = res;

        if (!res.paymentStatus) {
          this.checkInvoice(res.sessionId, res);
        }
      }, error => {
        console.error('error', error);
      })
    );
  }


  checkInvoice(id: any, order: any) {

    const callable = this.angularFireFunctions.httpsCallable('checkInvoice');
    const obs = callable({
      sessionId: id,
      orderId: this.sessionId,
      orderData: order,
    });
    obs.subscribe(async resp => {
      console.log(resp);
      if(resp){
        this.invoicResStatus = true;
      }
    }, err => {
      console.error(err);
    });
  }

  goToDashboard() {
    this.router.navigate(['start']);
  }

  public goPostJob(): void {
    this.router.navigate(['post-jobs']);
  }
  
  public goPostTraining(): void {
    this.router.navigate(['post-training']);
  }
}