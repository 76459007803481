import { Component,OnDestroy} from '@angular/core';
import { UsersService } from './api-services/users/users.service';
import { GlobalService } from './services/global.service';
import { TranslateConfigService } from './services/translate-config.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { Subscription } from 'rxjs';
import { SplashScreen } from '@capacitor/splash-screen';
import { AlertController, ModalController } from '@ionic/angular';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements  OnDestroy {
  selectedLanguage: string;
  user: any;
  checkUser = true;
  message: any;
  public superStates: any;
  private subscriptions: Subscription[] = [];
  
  constructor(
    private translateConfigService: TranslateConfigService,
    private userService: UsersService,
    private globalService: GlobalService,
    private angularFireMessaging: AngularFireMessaging,
    private alertController: AlertController,
    private modalCtrl: ModalController
    ) {

      this.listen();
    this.globalService.showLoading();
    this.userService.user$.subscribe(res => {
      this.globalService.hideLoading();
      if(res){
        if (res.userType === 'admin') {
          if (res.activeStatus === true) {
            this.user = res;
            this.alertController.dismiss();
            this.modalCtrl.dismiss();
          } else if (res.activeStatus === false && res.companyStatus === 'reject') {
            // this.router.navigate(['start']);
            this.user = '';
            this.showNotActiveAlertToAdmin();
          }
        } else if(res && res.userType === 'superAdmin') {
          this.user = res;
          this.subscriptions.push(
          this.userService.getSuperAdminStates().subscribe( states => {
              this.superStates = states;
            }, error => {
              console.log('error', error);
            })
          );
        } else {
          this.user = res;
        }
      } else {
        this.user = '';
        this.globalService.hideLoading();
      }
      this.checkUser = true;
      // this.router.navigate(['']);
    }, error => {
      console.error('error', error);
      this.globalService.hideLoading();
      this.checkUser = true;
      this.user = '';
    });
    this.selectedLanguage = this.translateConfigService.getDefaultLanguage();
    SplashScreen.hide();
  }

  listen() {


    this.angularFireMessaging.messages.subscribe((payload: any) => {
      // console.log("new message received. ", payload);
      // this.currentMessage.next(payload);
      this.message = payload;

      const title = payload.notification.title;

      const option = {
        body: payload.notification.body,
        icon: payload.notification.icon,

        time: new Date(Date.now()).toString(),
        click_action: payload.notification.click_action,
      };
      console.log("option==>", option, title);
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.map( subscriber => {
      subscriber.unsubscribe();
    }); 
  }

  public async showNotActiveAlertToAdmin() {
    this.modalCtrl.dismiss();
    this.alertController.dismiss();
    const alert = await this.alertController.create({
      header: 'Account under review',
      message: 'You will be able to login after your account is approved by the SuperAdmin Please contact admin@trainmehireme.com for help.',
      mode: 'ios',
      backdropDismiss: false,
      cssClass: 'not-approve confMsg',
    });
    await alert.present();
  }

}
export class FileReaderA extends window.FileReader {
  constructor() {
    super();
    const zoneOriginalInstance = (this as any)['__zone_symbol__originalInstance'];
    return zoneOriginalInstance || this;
  }
}
window.FileReader = FileReaderA;