import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AllAdminsComponent } from './admin/all-admins/all-admins.component';
import { AdminGuard } from './guard/admin.guard';
import { AuthGuard } from './guard/auth.guard';
import { PaymentOptionComponent } from './shared-ui-components/payment-option/payment-option.component';
import { StartComponent } from './start/start.component';
import { HelpComponent } from '././help/help.component';
import { FAQsComponent} from '././faqs/faqs.component';
import { MentalHealthComponent} from '././mental-health/mental-health.component';
import { AboutUsComponent} from '././about-us/about-us.component';
import { CmsDetailComponent } from './shared-ui-components/cms-detail/cms-detail.component';
import { ManageAboutComponent } from './admin/cms/manage-about/manage-about.component';
import { PageNotFoundComponent } from './shared-ui-components/page-not-found/page-not-found.component';
import { PaymentErrorComponent } from './shared-ui-components/payment-error/payment-error.component';
import { ThankyouComponent } from './shared-ui-components/thankyou/thankyou.component';
import { ApplicantProfileComponent } from './applicant-profile/applicant-profile.component';
import {ProfileGuard} from '././guard/profile.guard'

const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  { path: 'start', component: StartComponent },
  {
    path: 'support',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    title:'Support | Train me hire me'
  },
  {
    path: 'find-jobs',
    loadChildren: () => import('./find-jobs/find-jobs.module').then(m => m.FindJobsPageModule),
    canActivate: [AuthGuard],
    title:'Find jobs | Train me hire me'
  },
  {
    path: 'find-jobs/:type/:applicantId',
    loadChildren: () => import('./find-jobs/find-jobs.module').then(m => m.FindJobsPageModule),
    canActivate: [AuthGuard],
    title:'Find jobs | Train me hire me'
  },
  {
    path: 'find-jobs/:type',
    loadChildren: () => import('./find-jobs/find-jobs.module').then(m => m.FindJobsPageModule),
    title:'Find jobs | Train me hire me'
  },
  {
    path: 'news-feed',
    loadChildren: () => import('./news-feed/news-feed.module').then(m => m.NewsFeedPageModule),
    canActivate: [AuthGuard],
    title:'News feed | Train me hire me'
  },
  { path: 'find-training',
    loadChildren: () => import('./training/training.module').then(m => m.TrainingPageModule),
    canActivate: [AuthGuard],
    title:'Find training | Train me hire me'
  },
  { path: 'find-training/:type',
    loadChildren: () => import('./training/training.module').then(m => m.TrainingPageModule),
  },
  { path: 'find-training/:type/:applicantId',
    loadChildren: () => import('./training/training.module').then(m => m.TrainingPageModule),
    canActivate: [AuthGuard],
    title:'Find training | Train me hire me'
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard && AdminGuard],
  },
  // {
  //   path: 'message',
  //   loadChildren: () => import('./message/message.module').then( m => m.MessagePageModule)
  // },
  {
    path: 'post-jobs',
    loadChildren: () => import('./post-jobs/post-jobs.module').then( m => m.PostJobsPageModule),
    canActivate: [AuthGuard],
    title:'Post jobs | Train me hire me'
  },
  {
    path: 'applicant-profile/:type',
    // loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    component: ApplicantProfileComponent,
    title:'Applicant profile | Train me hime me',
    canActivate: [ProfileGuard],
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard],
    title:'Profile | Train me hire me'
  },
  {
    path: 'registration/:type',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationPageModule),
    title:'Registration | Train me hire me'
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationPageModule),
    title:'Registration | Train me hire me'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    title:'Login | Train me hire me'
  },
  {
    path: 'login/:type',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    title:'Login | Train me hire me'
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./forgotpassword/forgotpassword.module').then(m => m.ForgotpasswordPageModule),
    title:'Forgotpassword | Train me hire me'
  },

  {
    path: 'message',
    loadChildren: () => import('./message/message.module').then( m => m.MessagePageModule),
    title:'Message | Train me hire me'
  },
  {
    path: 'post-jobs',
    loadChildren: () => import('./post-jobs/post-jobs.module').then( m => m.PostJobsPageModule),
    canActivate: [AuthGuard],
    title:'Post jobs | Train me hire me'
  },

  {
    path: 'payment-option',
    component: PaymentOptionComponent,
    title:'Payment option | Train me hire me'
  },
  {
    path: 'admins',
    component : AllAdminsComponent,
    canActivate: [AuthGuard && AdminGuard],
    title:'Admin | Train me hire me'
  },
  {
    path: 'mange-about',
    component : ManageAboutComponent,
    canActivate: [AuthGuard && AdminGuard],
    title:'Mange about | Train me hire me'
  },
  {
    path: 'pending-admin',
    loadChildren: () => import( './admin/panding-admin/panding-admin.module').then(m => m.PandingAdminPageModule),
    canActivate: [AuthGuard && AdminGuard],
    title:'Pending admin | Train me hire me'
  },
  {
    path: 'dashboard',
    loadChildren: () => import( './dashboard/dashboard.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuard && AdminGuard],
    title:'Dashboard | Train me hire me'
  },
  {
    path: 'users',
    loadChildren: () => import( './admin/all-users/all-users.module').then(m => m.AllUsersPageModule),
    canActivate: [AuthGuard && AdminGuard],
    title:'Users | Train me hire me'
  },
  {
    path: 'post-training',
    loadChildren: () => import('./post-training/post-training.module').then( m => m.PostTrainingPageModule),
    canActivate: [AuthGuard],
    title:'Post training | Train me hire me'
  },
  {
    path: 'help',
    component: HelpComponent,
    title:'Help | Train me hire me'
  },
  {
    path: 'about',
    component: AboutUsComponent,
    title:'About | Train me hire me'
  },
  {
    path: 'FAQs',
    component: FAQsComponent,
    title:'FAQs | Train me hire me'
  },
  {
    path: 'mental-health',
    component: MentalHealthComponent,
    title:'Mental health | Train me hire me'
  },
  {
    path: 'cms-detail/:type/:slag',
    component: CmsDetailComponent,
    title:'Cms detail | Train me hire me'
  },
  {
    path: 'applicants',
    loadChildren: () => import('./shared-ui-components/applicants/applicants.module').then( m => m.ApplicantsPageModule),
    canActivate: [AuthGuard],
    title:'Applicants | Train me hire me'
  },
  {
    path: 'applicants-data',
    loadChildren: () => import('./shared-ui-components/applicants-data/applicants-data.module').then( m => m.ApplicantsDataPageModule),
    canActivate: [AuthGuard],
    title:'Applicants data | Train me hire me'
  },
  {
    path: 'page-not-found/:type',
    component: PageNotFoundComponent,
    title:'Page not found | Train me hire me'
  },
  {
    path: 'applicants-training',
    loadChildren: () => import('./shared-ui-components/applicants-training/applicants-training.module').then( m => m.ApplicantsTrainingPageModule),
    canActivate: [AuthGuard],
    title:'Applicants training | Train me hire me'
  },
  {
    path: 'applicants-training-data',
    loadChildren: () => import('./shared-ui-components/applicants-training-data/applicants-training-data.module').then( m => m.ApplicantsTrainingDataPageModule),
    canActivate: [AuthGuard],
    title:'Applicants training data | Train me hire me'
  },
  {
    path: 'payment-error',
    component: PaymentErrorComponent, 
    title:'Payment error | Train me hire me'
  },
  { 
    path: 'thank-you/:sessionId',
    component: ThankyouComponent, 
    title:'Thank you | Train me hire me'
   },
  {
    path: 'manage-sector',
    loadChildren: () => import('./admin/manage-sector/manage-sector.module').then( m => m.ManageSectorPageModule)
  },


];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
