import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LoadingController, ToastController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})

export class GlobalService {
  private loadingTime = 1000;
  public loadingTimeSource = new BehaviorSubject(this.loadingTime);
  public hideloadingTime = this.loadingTimeSource.asObservable();
  public submitLoading: any;
  public changePasswrodSource = new BehaviorSubject(null);
  public showTabSource = this.changePasswrodSource.asObservable();

  constructor(
    public loadingController: LoadingController,
    public toastController: ToastController,
  ) {
    this.loadingTimeSource.next(null);
  }

  async showLoading() {
    console.log('Calling show loading....')
    this.hideloadingTime.subscribe(async loadTime => {
      this.submitLoading = await this.loadingController.create({
        spinner: 'lines-sharp-small',
        message: 'Please Wait...',
        translucent: true,
        cssClass: 'custom-class custom-loading',
        mode: 'ios',
        keyboardClose: true,
        animated:true
      });
      this.submitLoading.duration = loadTime;
      return await this.submitLoading.present();
    });
  }

  async hideLoading() {
    console.log('Calling hide loading....')
    this.submitLoading = await this.loadingController.dismiss();
  }




  async showTabsAfterChange() {
    this.changePasswrodSource.next(true);
  }

  async showToastMessage(msg: any, type?: any) {
    // let toastMessage = '';
    // this.translate.get(msg).subscribe((text: string) => {
    //   toastMessage = text;
    // });
    // message: toastMessage,
    const toast = await this.toastController.create({
      message: msg,
      color: type === 'error' ? 'danger' : 'success' ,
      duration: 4000,
      cssClass: type === 'error' ? 'tostError' : 'tostVaild',
      position: 'bottom',
    });
    toast.present();
  }

  calTime(value){
    const newTime = new Date(value.seconds * 1000);
    const d = new Date(newTime);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));

    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return 'a few seconds ago';
    } else if (seconds <= 90) {
      return 'a minute ago';
    } else if (minutes <= 45) {
      return minutes + ' minutes ago';
    } else if (minutes <= 90) {
      return 'an hour ago';
    } else if (hours <= 22) {
      return hours + ' hours ago';
    } else if (hours <= 36) {
      return 'a day ago';
    } else if (days <= 25) {
      return days + ' days ago';
    } else if (days <= 45) {
      return 'a month ago';
    } else if (days <= 345) {
      return months + ' months ago';
    } else if (days <= 545) {
      return 'a year ago';
    } else {
      return years + ' years ago';
    }
  }

  selectFile(event: any): Observable<any> {
    const obs = new Observable( observer => {
      const selectedFiles = event.target.files;
      console.log('----selectedFiles----', event.timeStamp);
      if (selectedFiles) {
        const file = selectedFiles.item(0);
        // this.type = file.type;
        // this.currentFileUpload = file;
        observer.next({
          status: 200,
          file: file
        })
      }

    })
    return obs;
    // this.choosenFile = event.target.files[0].name;
  }


}
