import { ApplicationModule, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { PaymentOptionComponent } from './payment-option/payment-option.component';
import { ManageSavedJobsComponent } from './manage-saved-jobs/manage-saved-jobs.component';
import { ManageSavedTrainingComponent } from './manage-saved-training/manage-saved-training.component';
import { IonicModule } from '@ionic/angular';
import { UserEditComponent } from './user-edit/user-edit.component';
import { JobViewComponent} from './job-view/job-view.component'
import { CmsDetailComponent } from './cms-detail/cms-detail.component';
import { ApplicantsDataPage } from './applicants-data/applicants-data.page';
import { PaymentErrorComponent } from './payment-error/payment-error.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { CommonFilterComponent } from './common-filter/common-filter.component';
// import {CommonFilterComponent} from './common-filter/common-filter.component'
import { MapComponent } from './map/map.component';


@NgModule({
  declarations: [
    PaymentOptionComponent,
    ManageSavedJobsComponent,
    ManageSavedTrainingComponent, 
    UserEditComponent,
    JobViewComponent,
    CmsDetailComponent,
    ApplicantsDataPage,
    PaymentErrorComponent,
    ThankyouComponent,
    CommonFilterComponent,
    MapComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    ReactiveFormsModule,
  ],
  exports: [JobViewComponent,CommonFilterComponent,MapComponent]
  
})
export class SharedUiComponentsModule { }
