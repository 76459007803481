
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ModalController } from '@ionic/angular';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class CmsService {
  public authenticationStatus: Observable<any>;
  public authState = new BehaviorSubject(false);
  public response: { status: number; message: string; data: any; };

  constructor(
    private db: AngularFirestore,
    public modalController: ModalController,
  ) { }

  public getAllCmsData(type): Observable<any> {
    return this.db.collection('Cms', ref => ref.where('type', '==', type).where('status' , '==', 'published').orderBy('sortOrder', 'desc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            return { docId, ...data, };
          });
        })
      );
  }

  public addCmsData(data: any): Observable<any> {
    console.log(data);
    data.formData.createdDate = new Date();
    const obs = new Observable((observer) => {
      this.db.collection('Cms').add(data.formData).then(async (res: any) => {
        observer.next({
          status: 200,
          id: res.id
        });
      }).catch((error) => {
        console.log('error', error);
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public updateCmsData(data: any, csmId): Observable<any> {
    data.updatedDate = new Date();
    const obs = new Observable((observer) => {
      this.db.collection('Cms').doc(csmId).update(data.formData).then(async (res: any) => {
        observer.next({
          status: 200,
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public deleteCms(docId): Observable<any> {
    const obs = new Observable((observer) => {
      this.db.collection('Cms').doc(docId).delete().then(async () => {
        // const basePath = `${tablename}/${data.docId}.jpeg`;
        // firebase.storage().ref().child(basePath).delete();
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  // async uploadFile(selectedPhoto, id,){
  //   // console.log(selectedPhoto, id);
  //   const storageRef = firebase.storage().ref();
  //   return new Promise(function (resolve, reject) {
  //     const task = storageRef.child(`${'Cms'}/${id}/document`).put(selectedPhoto);

  //     task.on(
  //       "state_changed",
  //       function progress(snapshot) {
  //         const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //       },

  //       function error(err) {
  //         reject(err);
  //       },

  //       async function complete() {
  //         const imageURL = await task.snapshot.ref.getDownloadURL();
  //         resolve(imageURL);
  //       }
  //     );
  //   });
  // }

  public async updateAdvCover(selectedPhoto, id,type?:any ,formData?:any,  ): Promise<any> {
    const response = await fetch(selectedPhoto);
    const blob = await response.blob();

    const metadata = {
      contentType: 'image/jpeg',
    };

    const photoUrl = `${'Cms'}/${id}/cms-data.jpg`;
    const ref = firebase
      .storage()
      .ref()
      .child(photoUrl);
    ref.put(blob, metadata).then(obj => {
      ref.getDownloadURL().then(async (url) => {
        const data = {
          cmsImage: url,
        };
        // if(type === 'add'){
          // }
          if(type === 'add'){
            await this.updateImageOnCmsDetails(id, data).subscribe(res =>{
              // console.log('----------------', res);
            }, error => {
              console.log('error', error);
            });
        }else {
          formData.cmsImage = url;
          this.updateCmsData( formData, id).subscribe(res => {
            console.log('serverresss',res);
          }, error => {
            console.error('error', error);
          })
        }
      });
    });
  }

  private updateImageOnCmsDetails(documentId, cmsImage): Observable<any>{
      // console.log('-------------', cmsImage, documentId);
    const obj = new Observable( (observer) => {
      this.db.collection('Cms').doc(documentId).update(cmsImage).then(res => {
        observer.next({
          status : 200,
          message : 'image update successfully'
        })
      }).catch((error => {
        observer.next({
          status : 400,
          message : 'error'
        })
      }))
    })
    return obj
  }

  // public updateImageOnCmsDetails(cmsImage, documentId): Observable<any>{
  //   const obj = new Observable( (observer) => {
  //     this.db.collection('Cms').doc(documentId).update(cmsImage).then(res => {
  //       observer.next({
  //         status : 200,
  //         message : 'Cms added successfully'
  //       })
  //     }).catch((error => {
  //       observer.next({
  //         status : 400,
  //         message : 'error'
  //       })
  //     }))
  //   })
  //   return obj
  // }


  public getCmsDataBySlag(type, slag): Observable<any> {
    return this.db.collection('Cms', ref => ref.where('type', '==', type).where('urlSlug', '==', slag)).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            return { docId, ...data, };
          });
        })
      );
  }

}
