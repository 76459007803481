import { Component, Input, EventEmitter, Output, } from '@angular/core';
@Component({
  selector: 'app-common-filter',
  templateUrl: './common-filter.component.html',
  styleUrls: ['./common-filter.component.scss'],
})
export class CommonFilterComponent  {

  @Input() jobsTypeData: any;
  @Input() forTrainingComponent: any;
  @Input() experienceTypeData: any;
  @Input() healthSafetyTypeData: any;
  @Input() healthSafetySubTypeData: any;
  // @Input() skillTypeData: any;
  // @Input() skillTypeSubData: any;
  @Input() showButton: boolean;
  @Input() countyList: any[];
  @Input() provincesList: any[];
  @Input() countryList: any[];
  @Input() province: any;
  @Input() showFilter: any;
  @Input() buttonShow: any;
  @Input() findJobForm: any;
  @Input() filterData: any;
  @Input() showCommonFilter: any;

  @Output() getSelectedJobs: EventEmitter<any> = new EventEmitter<any>();
  @Output() getSelectedEmploys: EventEmitter<any> = new EventEmitter<any>();
  @Output() getSelectedExperiences: EventEmitter<any> = new EventEmitter<any>();
  @Output() getSelectedHealthSafetys: EventEmitter<any> = new EventEmitter<any>();
  // @Output() getSelectedSkillss: EventEmitter<any> = new EventEmitter<any>();
  @Output() getSelectedStartDates: EventEmitter<any> = new EventEmitter<any>();
  @Output() getSelectedEndDates: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCountyChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() onprovinceChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCountryChanges: EventEmitter<any> = new EventEmitter<any>();
  @Output() buttonDisablesIonic: EventEmitter<any> = new EventEmitter<any>();
  @Output() buttonDisables: EventEmitter<any> = new EventEmitter<any>();
  @Output() applyLabels: EventEmitter<any> = new EventEmitter<any>();
  @Output() showfilters: EventEmitter<any> = new EventEmitter<any>();

  employmentList = [ 
    {
      type: 'Part-time'
    },
    {
      type: 'Full-time'
    },
    {
      type: 'Self-employed'
    },
    {
      type: 'Freelance'
    },
    {
      type: 'Internship'
    },
    {
      type: 'Trainee'
    },
  ]

  public startDate: any;
  public endDate: any;
  // public showCheckBoxSubHS: boolean = false;
  // public showCheckBoxSubSkill: boolean = false;
  public showProvince: boolean = false;


  public getSelectedJob(event) {
    // console.log('event-----',event.target.value)
    // this.showCheckBoxSubHS = false;
    // this.showCheckBoxSubSkill = false;
    this.getSelectedJobs.emit(event.target.value);

  }
  public getSelectedEmploye(event) {
    // console.log('event-----',event.target.value)
    this.getSelectedEmploys.emit(event.target.value);

  }

  public getSelectedExperience(event, index) {
    const expObj = { event:event, experience: index }
    this.getSelectedExperiences.emit(expObj);
  }

  public getSelectedHealthSafety(event) {
    // if (event.target.value) {
    //   this.showCheckBoxSubHS = true;
    // } else {
    //   this.showCheckBoxSubHS = false;
    // }
    this.getSelectedHealthSafetys.emit(event.target.value);
  }

  // public getSelectedSkills(event) {
  //   if (event.target.value) {
  //     this.showCheckBoxSubSkill = true;
  //   } else {
  //     this.showCheckBoxSubSkill = false;
  //   }
  //   this.getSelectedSkillss.emit(event.target.value);
  // }

  public getSelectedStartDate(event) {
    this.getSelectedStartDates.emit(event);
  }

  public getSelectedEndDate(event) {
    console.log('@@@@@@@@@@', event);
    this.getSelectedEndDates.emit(event);
  }

  public onCountryChange(event) {
    this.findJobForm.get('province').setValue('');
    this.findJobForm.get('county').setValue('');
    this.showProvince = true;
    // console.log('country event---------------',event.target.value)
    this.onCountryChanges.emit(event.target.value);
  }
  
  public onprovinceChange(event) {
    this.findJobForm.get('county').setValue('');
    this.onprovinceChanges.emit(event.target.value);
  }
  
  public onCountieChange(event) {
    this.onCountyChanges.emit(event.target.value);
  }

  public buttonDisableIonic(event) {
    this.buttonDisablesIonic.emit(event);
  }
  public buttonDisable(event) {
    this.buttonDisables.emit(event.target.value);
  }

  public applyLabel() {
    this.showProvince = false;
    this.applyLabels.emit();
  }

  public showfilter() {
    this.showfilters.emit();
  }
}
