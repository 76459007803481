// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  emulators: false,

  // firebase: {
  //   apiKey: "AIzaSyB3hf3BuqiF4f091JeWf28Dgxl1dcCWzzw",
  //   authDomain: "trainmehireme-dev.firebaseapp.com",
  //   projectId: "trainmehireme-dev",
  //   storageBucket: "trainmehireme-dev.appspot.com",
  //   messagingSenderId: "759436764911",
  //   appId: "1:759436764911:web:b6ef0dccb8c8e2400f1d1a",
  //   measurementId: "G-SHVLMS7350"
  // }
  firebase: {
    apiKey: "AIzaSyAvz7RfcsVlJj1aD86KPyDFuDDL6JR-R_E",
    authDomain: "trainmehireme.firebaseapp.com",
    projectId: "trainmehireme",
    storageBucket: "trainmehireme.appspot.com",
    messagingSenderId: "251725100673",
    appId: "1:251725100673:web:f48091849e1edfaca73f80",
    measurementId: "G-QS5Z73X2F9"
  }

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
