import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CmsService } from '../api-services/cms/cms.service';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent {
  
  public helpData: any;
  public allHelpData = [];
  private subscriptions: Subscription[] = [];

  constructor(
    private cmsService: CmsService,
    private router: Router
  ) { }

  public ionViewDidEnter(): void {
    this.subscriptions.push(
      this.cmsService.getAllCmsData('help').subscribe( res => {
        this.allHelpData = res;
        this.helpData = {};
        res.map(data => {
          if (!this.helpData[data.category]) {
            this.helpData[data.category] = []
          }
          this.helpData[data.category].push(data);
        })
      }, error => {
        console.log('error',error);
      })
    );
  }

  public ionViewDidLeave(): void {
    this.subscriptions.map( subscriber => {
      subscriber.unsubscribe();
    })
  }

  public nevigateTo(data): void{
    const path = `cms-detail/${data.type}/${data.urlSlug}`;
    this.router.navigate([path])
  }
  

}
