import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ConstantVariables } from 'src/const/constant';
import { Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { UsersService } from 'src/app/api-services/users/users.service';
import { GlobalService } from 'src/app/services/global.service';
import { AlertController } from '@ionic/angular';
@Component({
  selector: 'app-all-admins',
  templateUrl: './all-admins.component.html',
  styleUrls: ['./all-admins.component.scss'],
})
export class AllAdminsComponent implements OnInit {

  @ViewChild('paginatorPageSize') paginator!: MatPaginator;
  public dataSourceEmpty: any;
  public dataSource: MatTableDataSource<any>;
  public displayedColumns: string[] = ['index', 'admin', 'email', 'status', 'reason', 'editAdmin'];
  public pageSizes = [10, 50, 100];
  public segmentValue = 'approve';
  public approveAdmins: any;

  private subscriptions: Subscription[] = [];

  constructor(
    private usersService: UsersService,
    private constantVariable: ConstantVariables,
    private ref: ChangeDetectorRef,
    private globalService: GlobalService,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
    this.getApproveData();
  }

  public ionViewDidLeave () {
    this.subscriptions.map(subscriptions => {
      subscriptions.unsubscribe();
    })
  }


  public segmentChanged(event) {
    if (event.detail.value === 'reject') {
      this.segmentValue = 'reject';
      this.getRejectData();
    } else {
      this.getApproveData();
    }
  }

  private getApproveData() {
    this.subscriptions.push(
      this.usersService.getApproveAdmins(this.constantVariable.users, 'admin').subscribe(res => {
        this.approveAdmins = res;
        // console.log('--------REs', this.approveAdmins)
        this.dataSourceEmpty = res;
        this.dataSource = new MatTableDataSource(res);
        this.ref.detectChanges();
        this.dataSource.paginator = this.paginator;
      }, error => {
        console.error('error', error);
      })
    );
  }
  
  private getRejectData() {
    this.subscriptions.push(
      this.usersService.getRejectAdmins(this.constantVariable.users, 'admin').subscribe(res => {
        this.approveAdmins = res;
        this.dataSourceEmpty = res;
        this.dataSource = new MatTableDataSource(res);
        this.ref.detectChanges();
        this.dataSource.paginator = this.paginator;
      }, error => {
        console.error('error', error);
      })
    );
  }

  public adminFilter(searchItem) {
    const filterValue = (searchItem.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public async updataStatusPopup(action, type) {
    console.log('============', action,type);
    const alert = await this.alertCtrl.create({
      header:type === 'reject' ? 'Approve' : 'Reject',
      message: type === 'reject' ? 'Are you sure you want to approve this company.' : 'Are you sure you want to reject this company.',
      mode: 'ios',
      cssClass: type !== 'reject' ? 'no-approve' : 'alertCtrl',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          handler: () => {

          }
        },
        {
          text: type === 'reject' ? 'Approve' : 'Reject',
          // handler: () => {
            // }
            handler: (alertData) => {
              console.log('alertData', alertData);
                this.updataStatusAdmin(action, type, alertData.adminMessage);
          }
        }
      ],
      inputs: [
        {
          type: 'textarea',
          placeholder: 'Message',
          value: action.adminMessage,
          name:'adminMessage'
        },
      ],
    });
    await alert.present();
  }

  private updataStatusAdmin(adminData, type, adminMess) {
    if (type === 'reject') {
      const obj ={
        adminMessage: adminMess,
        activeStatus: true,
        companyStatus: 'approve',
        docId: adminData.docId
      };
      this.globalService.showLoading();
      this.subscriptions.push(
        this.usersService.updateUser(obj).subscribe(res => {
          if(res.status === 200){
            this.globalService.showToastMessage('Approve admin successfully');
            this.globalService.hideLoading();
            this.segmentValue = 'approve';
          } else {
            this.globalService.showToastMessage('Error in approve admin', 'error');
            this.globalService.hideLoading();
          }
        }, error => {
          console.error('error', error);
        })
      );
    } else {

      const obj ={
        adminMessage: adminMess,
        activeStatus: false,
        companyStatus: 'reject',
        docId: adminData.docId
      };
      // adminData.activeStatus = false;
      // adminData.companyStatus = 'reject';
      this.globalService.showLoading();
      this.subscriptions.push(
        this.usersService.updateUser(obj).subscribe(res => {
          if(res.status === 200) {
            this.globalService.hideLoading();
            this.globalService.showToastMessage('Reject admin successfully');
            this.segmentValue = 'reject';
          } else {
            this.globalService.hideLoading();
            this.globalService.showToastMessage('Error in reject admin');
          }
        }, error => {
          console.error('error', error);
        })
      );
    }

  }

}
