import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateConfigService {
  defLng = 'en';

  constructor(
    private translate: TranslateService
  ) { }

  getDefaultLanguage() {
    // const language = this.translate.getBrowserLang();
    // this.translate.setDefaultLang(language);
    // return language;

    let language = this.translate.getBrowserLang();
    if (language !== 'en' && language !== 'hi') {
      language = 'en';
    }

    this.translate.setDefaultLang(language);
    return language;
  }

  setLanguage(setLang) {
    this.translate.use(setLang);
    this.defLng = setLang;
  }

  getLanguage() {
    return this.defLng;
  }
}
