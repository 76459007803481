import { Component, Input, Output, ViewChild, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent  {
  @Input() type: any;
  @Input() jobsData: any;
  @Input() singalJob: any;
  @Input() listView: any;
  @ViewChild('map') mapElement: any;
  
  @Output() viewDetails = new EventEmitter();


  map: google.maps.Map;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;
  currentLat: number;
  currentLong: number;
  marker: any;
  cords: GeolocationPosition;
  public latLonData: any;
  

  public ngOnChanges(): void {
    setTimeout(() => { this.lodeMapData(); });
    navigator.geolocation.getCurrentPosition((position) => {
      console.log(position.coords.latitude)
      this.getAddress(position.coords.latitude,  position.coords.longitude);
    });
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          console.log('this.address', this.address)
        } else {
          // window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    
    });
  }

  public ionViewDidEnter(): void {
  }




  private lodeMapData(): void {
    
    // if(  this.type !== "training"){
    //   const latt = Number(this.jobsData[0]?.latitude);
    //   const log = Number(this.jobsData[0]?.longitude);
    //   const mapProperties = {
    //     center: new google.maps.LatLng(latt, log),
    //     zoom: 14,
    //     mapTypeId: google.maps.MapTypeId.ROADMAP
    //   };
    //   this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    // }else{
      this.latLonData = this.jobsData.find( res => res.latitude);

      const latt = Number(this.latLonData.latitude);
      const log = Number(this.latLonData.longitude);
      const mapProperties = {
        center: new google.maps.LatLng(latt, log),
        zoom: 14,
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };
      this.map = new google.maps.Map(this.mapElement.nativeElement, mapProperties);
    // }
  
    
    // const location = { lat: latt, lng: log }
    // const marker = new google.maps.Marker({
    //   position: location,
    //   map: this.map,
    // });

    this.jobsData.map(job => {
      const infowindow = new google.maps.InfoWindow();
      let marker;
      marker = new google.maps.Marker({
        position: new google.maps.LatLng(Number(job.latitude), Number(job.longitude)),
        map: this.map,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          scale: 8,
          strokeWeight: 5,
          strokeColor: 'orange',
          fillOpacity: 5,
        },
        draggable: false,
        animation: google.maps.Animation.DROP,
        title: job?.jobTitle ? job.jobTitle : job.trainingTitle,
      });
      marker.addListener('click', () => {
        infowindow.open(this.map, marker);
      });

      const title = job?.jobTitle ? job.jobTitle : job.trainingTitle;

      let content = '<div id="content" style="max-width: 225px;text-align:left">' +
        // tslint:disable-next-line: max-line-length
        '<div style="border-bottom: 1px solid #80808030;text-align:center;padding-bottom:5px;font-size:12px;font-weight: bold;">' + title + '</div>'
        +'<div id="bodyContent" >'+ job.description + '</div>'
        ;

        if(this.listView){
          content = '<div id="content" style="max-width: 225px;text-align:left">' +
        // tslint:disable-next-line: max-line-length
        '<div style="border-bottom: 1px solid #80808030;text-align:center;padding-bottom:5px;font-size:12px;font-weight: bold;">' + title + '</div>'
        +'<div id="bodyContent" >'+ job.description + '</div>'
        + '<div style="text-align: center; margin-top: 5px;"> <button style="background-color:#000; color:#fff; border-radius: 5px; height: 32px; min-width: 60px;"  id="btn" >View details</button> </div>'
        ;
        }

      this.makeInfoWindowEvent(this.map, infowindow, content, marker);
      infowindow.addListener('domready', () => {
        document.getElementById('btn').addEventListener('click', () => {
          this.viewDetails.emit(job);
        });
      });


    });
  }

  private makeInfoWindowEvent(map, infowindow, contentString, marker) {
    google.maps.event.addListener(marker, 'click', () => {
      infowindow.setContent(contentString);
      infowindow.open(map, marker);
    });
  }

  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.showPosition(position);
      });
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  }

  showPosition(position) {
    this.currentLat = this.cords.coords.latitude;
    this.currentLong = this.cords.coords.longitude;

    let location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    this.map.panTo(location);

    if (!this.marker) {
      this.marker = new google.maps.Marker({
        position: location,
        map: this.map,
        title: 'Got you!'
      });
    }
    else {
      this.marker.setPosition(location);
    }
  }

}
