import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-capture-modal',
  templateUrl: './capture-modal.component.html',
  styleUrls: ['./capture-modal.component.scss'],
})
export class CaptureModalComponent {
  @ViewChild('videoElement', { read: ElementRef }) videoElement: ElementRef<HTMLVideoElement>;

  public videoStream: MediaStream;
  public blob: any;
  public streamVideo: any;
  progress: number;
  interval: NodeJS.Timeout;
  private recordTimer: NodeJS.Timeout;
  public deviceNotFound: string;


  constructor(
    private ref: ChangeDetectorRef,
    public modalCtrl: ModalController,
    public mediaCapture: MediaCapture,
  ) { }

  ionViewDidEnter(){
    if(this.streamVideo === 'stream'){
      this.captureVideoStream();
    }else {
      console.log('Nothing done!');
    }
  }

  public captureVideoStream(): void {
    navigator.mediaDevices.getUserMedia({ video: true })
    .then(stream => {
        this.startCamera();
        this.startTimer();
        const options = {
          mimeType: 'video/webm'
        };
        const mediaRecorder = new MediaRecorder(stream, options);
        const chunks = [];
        
        mediaRecorder.addEventListener('dataavailable', event => {
          chunks.push(event.data);
        });
        
        mediaRecorder.addEventListener('stop', () => {
          this.blob = new Blob(chunks, { type: 'video/webm' });
          console.log('this.blob', this.blob)
          if(this.blob){
            this.modalCtrl.dismiss(this.blob, 'blob');
          }
          this.ref.detectChanges();
        });
        
        mediaRecorder.start();
        
        this.recordTimer = setTimeout(() => {
          mediaRecorder.stop();
        }, 30000);
      })
      .catch(error => {
        this.deviceNotFound = 'Requested device not found';
        this.stopCamera();
        this.clearTimer();
        this.progress = 0;
        console.log('Error capturing video:', error);
        this.ref.detectChanges();
      });
    }
    
    public startCamera(): void {
      navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then((stream: MediaStream) => {
        console.log('<<<<<stream>>>>>', stream);
        this.videoElement.nativeElement.srcObject = stream;
        this.videoStream = stream;
        this.ref.detectChanges();
      })
      .catch((error: any) => console.error('Error accessing webcam', error));
    }
    
    public stopCamera(): void {
      this.blob = '';
      this.clearTimer();
      clearTimeout(this.recordTimer);
      if (this.videoStream) {
        this.videoStream.getTracks().forEach((track: MediaStreamTrack) => {
          track.stop();
          this.videoStream = null;
          this.videoElement.nativeElement.srcObject = null;
          this.modalCtrl.dismiss(this.blob, 'cancel');
      });
    }
  }

  startTimer() {
    this.progress = 30;
    this.interval = setInterval(() => {

      this.progress = this.progress - 1;
      console.log('this.progress', this.progress)
      this.ref.detectChanges();
      if (this.progress <= 0) {
        this.clearTimer();
        this.ref.detectChanges();
      }
    }, 1000);
  }

  clearTimer() {
    clearInterval(this.interval);
    this.progress = 0;
  }

}
