export class ConstantVariables {
    public liveUrl= 'https://trainmehireme-dev.web.app';
    public manageExperience = 'Manage-experience';
    public manageSkills = 'Manage-skills';
    public manageJob = 'Manage-Jobs';
    public manageHealthSafety = 'Manage-Health-Safety';
    public manageHealthSafetySubCategory = 'Manage-Health-Safety-Sub-category';
    public manageSkillSetSub = 'Manage-Skill-Set-Sub';
    public manageQualification = 'Manage-Qualification';
    public manageSector = 'Manage-Sector';
    public jobs = 'Jobs';
    public trainings = 'Trainings';
    public users = 'Users';

    public cuntry = ['Ireland','United Kingdom', 'European Union'];
    public country = [
        {
            name: 'Ireland',
            lat: 53.412910,
            lon: -8.243890
        },
        {
            name: 'United Kingdom',
            lat: 55.378052,
            lon: -3.435973
        },
        {
            name: 'European Union',
            lat: 9.044760,
            lon: 7.460290
        },
    ];
    
    public provinces = ['Ulster','Connaught','Leinster','Munster'];
    public ukProvinces = ['East of England','East Midlands','London','North East England','North West England','South East England', 'South West England', 'West Midlands', 'Yorkshire and the Humber', 'Scotland', 'Wales'];
    public euProvinces = ['Austria', 'Belgium', 'Bulgaria', 'Croatia', 'Republic Of Cyprus', 'Czech Republic', 'Denmark', 'Estonia', 'Finland', 'France', 'Germany', 'Greece', 'Hungary', 'Italy', 'Latvia', 'Lithuania', 'Luxembourg', 'Malta', 'Netherlands', 'Poland', 'Portugal', 'Romania', 'Slovakia', 'Slovenia', 'Spain', 'Sweden'];
    
    public Ulster = ['Antrim','Armagh','Cavan','(London)Derry','Donegal','Down','Fermanagh','Monaghan','Tyrone'];
    public Connaught = ['Galway','Leitrim','Mayo','Roscommon','Sligo'];
    public Leinster = ['Carlow', 'Dublin', 'Kildare', 'Kilkenny', 'Laois/Leix(aka Queens)', 'Longford', 'Louth', 'Meath', 'Offaly (aka Kings)', 'Westmeath', 'Wexford', 'Wicklow'];
    public Munster = ['Clare', 'Cork', 'Kerry', 'Limerick', 'Tipperary', 'Waterford' ];


    public EastofEngland = ['Thurrock U.A.','Southend-on-Sea U.A.','Essex','Hertfordshire','Luton U.A.','Bedford U.A.','Central Bedfordshire U.A.','Cambridgeshire','Peterborough U.A.','Norfolk', 'Suffolk'];
    public EastMidlands = ['Derbyshire','Derby U.A.','Nottinghamshire','Nottingham U.A.','Lincolnshire','Leicestershire','Leicester U.A.','Rutland U.A.','North Northamptonshire U.A.','West Northamptonshire U.A.'];
    public London = ['City of Westminster','Kensington and Chelsea','Hammersmith and Fulham','Wandsworth','Lambeth','Southwark','Tower Hamlets','Hackney','Islington','Camden','Brent','Ealing', 'Hounslow','Richmond','Kingston upon Thames','Merton', 'Sutton', 'Croydon', 'Bromley', 'Lewisham', 'Greenwich','Bexley','Havering','Barking and Dagenham', 'Redbridge', 'Newham', 'Waltham Forest', 'Haringey', 'Enfield', 'Barnet', 'Harrow', 'Hillingdon','City of London'];
    public NorthEastEngland = ['Northumberland U.A.', 'Tyne and Wear ',  'Durham U.A.', 'Darlington U.A.', 'Hartlepool U.A.', 'Stockton-on-Tees U.A.', 'Redcar and Cleveland U.A.', 'Middlesbrough U.A.'];
    public NorthWestEngland = ['Cheshire East U.A.', 'Cheshire West and Chester U.A.', 'Halton U.A.', 'Warrington U.A.', 'Cumbria', 'Greater Manchester','Lancashire','Blackpool U.A.','Blackburn with Darwen U.A.','Merseyside'];
    public SouthEastEngland = ['Berkshire', 'Buckinghamshire U.A.', 'Milton Keynes U.A.', 'East Sussex', 'Brighton & Hove U.A.','Hampshire', 'Southampton U.A.','Portsmouth U.A.','Isle of Wight U.A.','Kent','Medway U.A.','Oxfordshire','Surrey','West Sussex' ];
    public SouthWestEngland = ['Bath and North East Somerset U.A.', 'North Somerset U.A.', 'Somerset', 'Bristol U.A.', 'South Gloucestershire U.A.', 'Gloucestershire','Swindon U.A.','Wiltshire U.A.','Dorset U.A.',' Bournemouth, Christchurch and Poole U.A.','Devon','Torbay U.A.','Plymouth U.A.',' Isles of Scilly sui generis U.A.','Cornwall U.A.'];
    public WestMidlands = ['Herefordshire U.A.', 'Shropshire U.A.', 'Telford and Wrekin U.A.', 'Staffordshire', 'Stoke-on-Trent U.A.', 'Warwickshire','West Midlands','Worcestershire' ];
    public YorkshireandtheHumber = ['South Yorkshire', 'West Yorkshire', 'North Yorkshire', 'York U.A.', 'East Riding of Yorkshire U.A.', 'Kingston upon Hull U.A.','North Lincolnshire U.A.','North East Lincolnshire U.A.'];
    public Scotland = ['Aberdeen City', 'Aberdeenshire', 'Angus', 'Argyll and Bute', 'Clackmannanshire', 'Dumfries and Galloway', 'Dundee City', 'East Ayrshire', 'East Dunbartonshire', 'East Lothian', 'East Renfrewshire', 'Edinburgh', 'City of Falkirk', 'Fife', 'Glasgow City', 'Highland', 'Inverclyde', 'Midlothian', 'Moray', 'Na h-Eileanan Siar', 'North Ayrshire', 'North Lanarkshire', 'Orkney Islands', 'Perth and Kinross', 'Renfrewshire', 'Scottish Borders', 'Shetland Islands', 'South Ayrshire', 'South Lanarkshire', 'Stirling', 'West Dunbartonshire', 'West Lothian'];
    public Wales = ['Blaenau Gwent', 'Bridgend', 'Caerphilly', 'Cardiff', 'Carmarthenshire', 'Ceredigion', 'Conwy', 'Denbighshire', 'Flintshire', 'Gwynedd', 'Isle of Anglesey', 'Merthyr Tydfil', 'Monmouthshire', 'Neath Port Talbot', 'Newport', 'Pembrokeshire', 'Powys', 'Rhondda Cynon Taf', 'Swansea', 'Torfaen', 'Vale of Glamorgan', 'Wrexham'];


    public Austria = ['Burgenland', 'Carinthia', 'ower Austria', 'Upper Austria', 'Salzburg', 'Styria', 'Tyrol', 'Vorarlberg', 'Vienna'];
    public Belgium = ['Antwerp', 'East Flanders', 'Flemish Brabant', 'Hainaut', 'Liège', 'Limburg', 'Luxembourg', 'Namur', 'Walloon Brabant', 'West Flanders'];
    public Bulgaria = ['Blagoevgrad', 'Burgas', 'Dobrich', 'Gabrovo', 'Haskovo', 'Kardzhali', 'Kyustendil', 'Lovech', 'Montana', 'Pazardzhik', 'Pernik', 'Pleven', 'Plovdiv', 'Razgrad', 'Ruse', 'Shumen', 'Silistra', 'Sliven', 'Smolyan', 'Sofia', 'Sofia Province' , 'Stara Zagora', 'Targovishte', 'Varna', 'Veliko Tarnovo', 'Vidin', 'Vratsa', 'Yambol'];
    public Croatia = ['Bjelovar-Bilogora', 'Brod-Posavina', 'Dubrovnik-Neretva', 'Istria', 'Karlovac', 'Koprivnica-Križevci', 'Krapina-Zagorje', 'Lika-Senj', 'Međimurje', 'Osijek-Baranja', 'Požega-Slavonia', 'Primorje-Gorski Kotar', 'Šibenik-Knin', 'Sisak-Moslavina', 'Split-Dalmatia', 'Varaždin', 'Virovitica-Podravina', 'Vukovar-Syrmia', 'Zadar', 'Zagreb'];
    public RepublicOfCyprus = ['Nicosia', 'Famagusta', 'Kyrenia', 'Larnaca', 'Limassol', 'Paphos District'];
    public CzechRepublic = ['Prague', 'Central Bohemian Region', 'South Bohemian Region', 'Plzeň Region', 'Karlovy Vary Region', 'Ústí nad Labem Region', 'Liberec Region', 'Hradec Králové Region', 'Pardubice Region', 'Olomouc Region', 'Moravian-Silesian Region', 'South Moravian Region', 'Zlín Region', 'Vysočina Region'];
    public Denmark = ['Copenhagen', 'Frederiksborg', 'Roskilde',' Holbæk', 'Vestsjælland', 'Storstrøm', 'Bornholm', 'Fyn', 'South Jutland', 'Ribe', 'Vejle', 'Ringkjøbing', 'North Jutland'];
    public Estonia = ['Harju', 'Hiiu', 'Ida-Viru', 'Järva', 'Jõgeva', 'Lääne', 'Lääne-Viru', 'Põlva', 'Pärnu', 'Rapla', 'Saare', 'Tartu', 'Valga', 'Viljandi', 'Võru' ];
    public Finland = ['Uusimaa', 'Southwest Finland', 'Satakunta', 'Kanta-Häme', 'Pirkanmaa', 'Päijät-Häme', 'Kymenlaakso', 'South Karelia', 'Etelä-Savo', 'Pohjois-Savo', 'North Karelia', 'Central Finland', 'South Ostrobothnia', 'Ostrobothnia', 'Central Ostrobothnia', 'North Ostrobothnia', 'Kainuu', 'Lapland', 'Åland'];
    public France = ['Ain', 'Aisne', 'Allier', 'Alpes-de-Haute-Provence', 'Hautes-Alpes', 'Alpes-Maritimes', 'Ardèche', 'Ardennes', 'Ariège', 'Aube', 'Aude', 'Aveyron', 'Bouches-du-Rhône', 'Calvados', 'Cantal', 'Charente', 'Charente-Maritime', 'Cher', 'Corrèze', 'Corse-du-Sud', 'Haute-Corse', 'Creuse', 'Dordogne', 'Doubs', 'Drôme', 'Eure', 'Eure-et-Loir', 'Finistère', 'Gard', 'Haute-Garonne', 'Gers', 'Gironde', 'Hérault', 'Ille-et-Vilaine', 'Indre', 'Indre-et-Loire', 'Isère', 'Jura', 'Landes', 'Loir-et-Cher', 'Loire', 'Haute-Loire', 'Loire-Atlantique', 'Loiret', 'Lot', 'Lot-et-Garonne', 'Lozère', 'Maine-et-Loire', 'Manche', 'Marne', 'Haute-Marne', 'Mayenne', 'Meurthe-et-Moselle', 'Meuse', 'Morbihan', 'Moselle', 'Nièvre', 'Nord', 'Oise', 'Orne', 'Pas-de-Calais', 'Puy-de-Dôme', 'Pyrénées-Atlantiques', 'Hautes-Pyrénées', 'Pyrénées-Orientales', 'Bas-Rhin', 'Haut-Rhin', 'Rhône', 'Haute-Saône', 'Saône-et-Loire', 'Sarthe', 'Savoie', 'Haute-Savoie', 'Paris', 'Seine-et-Marne', 'Yvelines', 'Deux-Sèvres', 'Somme', 'Tarn', 'Tarn-et-Garonne', 'Var', 'Vaucluse', 'Vendée', 'Vienne', 'Haute-Vienne', 'Vosges', 'Yonne', 'Territoire de Belfort', 'Essonne', 'Hauts-de-Seine', 'Seine-Saint-Denis', 'Val-de-Marne', 'Guadeloupe', 'Martinique', 'French Guiana', 'Réunion', 'Mayotte'];
    public Germany = ['Baden-Württemberg', 'Bavaria', 'Berlin', 'Brandenburg', 'Bremen', 'Hamburg', 'Hesse', 'Mecklenburg-Vorpommern', 'Lower Saxony', 'North Rhine-Westphalia', 'Rhineland-Palatinate', 'Saarland', 'Saxony', 'Saxony-Anhalt', 'Schleswig-Holstein', 'Thuringia'];
    public Greece = ['Attica', 'Central Greece', 'Central Macedonia', 'Crete', 'East Macedonia and Thrace', 'Epirus', 'Ionian Islands', 'North Aegean', 'Peloponnese', 'South Aegean', 'Thessaly', 'West Greece', 'West Macedonia'];
    public Hungary = ['Bács-Kiskun', 'Baranya', 'Békés', 'Borsod-Abaúj-Zemplén', 'Budapest', 'Csongrád-Csanád', 'Fejér', 'Győr-Moson-Sopron', 'Hajdú-Bihar', 'Heves', 'Jász-Nagykun-Szolnok', 'Komárom-Esztergom', 'Nógrád', 'Pest', 'Somogy', 'Szabolcs-Szatmár-Bereg', 'Tolna', 'Vas', 'Veszprém', 'Zala'];
    public Italy = ['Abruzzo', 'Basilicata', 'Calabria', 'Campania', 'Emilia-Romagna', 'Friuli-Venezia Giulia', 'Lazio', 'Liguria', 'Lombardy', 'Marche' , 'Molise', 'Piedmont', 'Apulia', 'Sardinia', 'Sicily', 'Tuscany', 'Trentino-Alto Adige/Südtirol', 'Umbria', 'Aosta Valley', 'Veneto'];
    public Latvia = ['Daugavpils', 'Jēkabpils', 'Jelgava', 'Jūrmala', 'Liepāja', 'Rēzekne', 'Riga', 'Valmiera', 'Ventspils'];
    public Lithuania = ['Alytus', 'Kaunas', 'Klaipėda', 'Marijampolė', 'Panevėžys', 'Šiauliai', 'Tauragė', 'Telšiai', 'Utena', 'Vilnius'];
    public Luxembourg = ['Diekirch', 'Clervaux', 'Vianden', 'Wiltz', 'Grevenmacher', 'Echternach', 'Remich', 'Luxembourg', 'Capellen', 'Esch-sur-Alzette', 'Mersch', 'Redange'];
    public Malta = ['Gozo', 'North Eastern', 'Southern Harbour', 'South Eastern', 'Western'];
    public Netherlands = ['Drenthe', 'Flevoland', 'Friesland', 'Gelderland', 'Groningen', 'Limburg', 'North Brabant', 'North Holland', 'Overijssel', 'South Holland', 'Utrecht', 'Zeeland'];
    public Poland = ['Lower Silesia', 'Kuyavian-Pomeranian', 'Lublin', 'Lubusz', 'Łódź', 'Lesser Poland', 'Masovian', 'Opole', 'Subcarpathian', 'Podlaskie', 'Pomeranian', 'Silesian', 'Holy Cross', 'Warmian-Masurian', 'Greater Poland', 'West Pomeranian'];
    public Portugal = ['Aveiro', 'Beja', 'Braga', 'Bragança', 'Castelo Branco', 'Coimbra', 'Évora', 'Faro', 'Guarda', 'Leiria', 'Lisbon', 'Portalegre', 'Porto', 'Santarém', 'Setúbal', 'Viana do Castelo', 'Vila Real', 'Viseu' ];
    public Romania = ['Alba', 'Arad', 'Argeș', 'Bacău', 'Bihor', 'Bistrița-Năsăud', 'Botoșani', 'Brașov', 'Brăila', 'Buzău', 'Caraș-Severin', 'Călărași', 'Cluj', 'Constanța', 'Covasna', 'Dâmbovița', 'Dolj', 'Galați', 'Giurgiu', 'Gorj', 'Harghita', 'Hunedoara', 'Ialomița', 'Iași', 'Ilfov', 'Maramureș', 'Mehedinți', 'Mureș', 'Neamț', 'Olt', 'Prahova', 'Satu Mare', 'Sălaj', 'Sibiu', 'Suceava', 'Teleorman', 'Timiș', 'Tulcea', 'Vaslui', 'Vâlcea', 'Vrancea'];
    public Slovakia = ['Bratislava', 'Trnava', 'Trenčín', 'Nitra', 'Žilina', 'Banská Bystrica', 'Prešov', 'Košice'];
    public Slovenia = ['Pomurska', 'Podravska', 'Koroška', 'Savinjska', 'Zasavska', 'Spodnjeposavska', 'Jugovzhodna Slovenija', 'Osrednjeslovenska', 'Gorenjska', 'Goriška', 'Obalno-kraška', 'Notranjsko-kraška'];
    public Spain = ['Andalusia', 'Aragon', 'Asturias', 'Balearic Islands', 'Basque Country', 'Canary Islands', 'Cantabria', 'Castile-La Mancha', 'Castile and León', 'Catalonia', 'Extremadura', 'Galicia', 'La Rioja', 'Madrid', 'Murcia', 'Navarre', 'Valencia'];
    public Sweden = ['Blekinge', 'Dalarna', 'Gotland', 'Gävleborg', 'Halland', 'Jämtland', 'Jönköping', 'Kalmar', 'Kronoberg', 'Norrbotten', 'Örebro', 'Östergötland', 'Skåne', 'Södermanland', 'Stockholm', 'Uppsala', 'Värmland', 'Västerbotten', 'Västernorrland', 'Västmanland', 'Västra Götaland'];
    // public 'pushNoti'fi'cationServerKey' =' "key=AAAAsNH1Zu8:APA91bE5ZaQBAtpChfBgprYT'-4'3u86t_Ewhwv_D6QDkuP3A1tqtOL3Ise8RT4wjbBejf7Xqau7VnIB0SkI5wAsJ1itb'-w'WudgnT8LiJx'-l'rlgH6YUO3yzws_rtXZgN_ux8b8HLebInma'";'
    public pushNotificationServerKey = "AAAAOpv8NoE:APA91bFx8pWaNRePSCmf2Q5fAHGp-iwA1R1wSABTJVGj_b4_dnSDDKVIzjxa_8j-f59pTAjXHOWUpepku8uj4L0vIcKwEsftgK4lsjY5OWiOD0kQBoVYTFXXZi-yDqd6lAgSlx6598qf";

    public priceId = {
        local:{
            Intro: 'price_1NBGxCD4KTPDaIL6f1G5uFXU',
            Base: 'price_1NBGyND4KTPDaIL6tCp4Kzij',
            Popular: 'price_1NBGyvD4KTPDaIL6NmZW8hJf',
        }, 
        live:{
            Intro: 'price_1NBH1WD4KTPDaIL6lAQXrSip',
            Base: 'price_1NBH2ED4KTPDaIL69NIQks0d',
            Popular: 'price_1NBH2mD4KTPDaIL6uQODt0ys',
        }
    }
    
    public vatPrice = 1.23;
}

    

