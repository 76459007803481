import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class ApplicantsService {

  constructor(
    private db: AngularFirestore,
  ) { }

  public getApplicantData(id): Observable<any> {
    return this.db.collection('Jobs').doc(id).collection('Applicants', ref => ref.orderBy('lastMessageDatetime','desc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // console.log('data~~~~~~~~~~~~~~~~~~',data)
            return { docId, ...data, };
          });
        })
      );
    }

  public getApplicantMessages(jobId, applicantId): Observable<any> {
    // console.log('+++++++++++++', jobId, applicantId);
    return this.db.collection('Jobs').doc(jobId).collection('Applicants').doc(applicantId).collection('Messages', ref => ref.orderBy('messageDate', 'asc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // console.log('data~~~~~~~~~~~~~~~~~~',data)
            return { docId, ...data, };
          });
        })
      );
  }

  public updateApplicantTraining(DocId, data: any,id): Observable<any> {

    const obj = new Observable(obs => {
      this.db.collection('Trainings').doc(DocId).collection('Applicants').doc(id).update(data).then(res => {
        
       //  console.log('-----------res-------', res.id);
       obs.next({
         status: 200,
         message: 'Applicants add successfully in job',
         // id:res.id
       })
     }).catch((error => {
       obs.next({
         status: 400,
         message: 'error'
       })
     }))
   });

   return obj;
 }
}
