import { Component, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UsersService } from '../api-services/users/users.service';
import { AlertController, ModalController } from '@ionic/angular';
import { UserEditComponent } from '../shared-ui-components/user-edit/user-edit.component'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ManageTableService } from '../api-services/manage-jobs/manage-table.service';
import { ExperienceService } from '../api-services/experience/experience.service';
import { UserHealthAndSeftyService } from '../api-services/userHealtAndSefty/user-health-and-sefty.service';
import { EducationService } from '../api-services/education/education.service';
import { ExperienceCompanysService } from '../api-services/experienceCompanys/experience-companys.service';
import { GlobalService } from '../services/global.service';
import { DocumentWalletService } from '../api-services/document-wallet/document-wallet.service';
import { ExperienceDetailsComponent } from '../registration/experience-details/experience-details.component';
import { UserSkillSetService } from '../api-services/userSkillSet/user-skill-set.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Camera, CameraDirection, CameraOptions, CameraResultType, CameraSource } from '@capacitor/camera';
import { ConstantVariables } from 'src/const/constant';


@Component({
  selector: 'app-applicant-profile',
  templateUrl: './applicant-profile.component.html',
  styleUrls: ['./applicant-profile.component.scss'],
})
export class ApplicantProfileComponent implements OnInit {

 
  public user: any;
  public educationsDetails: any;
  public experienceCompanyDetails: any;
  
  public skillForm: FormGroup;
  public jobsData: any;
  public valueJob: any;
  public experienceData: any = [];
  public userAge: string;
  public addEduData: any;
  public allDocData: any;
  healthAndSafety: any;
  displayHealthAndSafetyData: any;
  skillSet: any;
  displayskillSetData: {};
  healthAndSafetyAllData: any;
  skillSetAllData: any;
  addExperienceData: any;
  typeDoc: string;
  progress: number;
  public applicantId: any;
  
  public selectedPhoto: any;
  public isImageSaved: boolean;
  public tackNewImg: boolean;
  
  public get formDataConfiguration(): FormArray { return this.skillForm.get('experience') as FormArray; }
  public get healthAndSafetyConfiguration(): FormArray { return this.skillForm.get('healthAndSafety') as FormArray; }
  public displayExperinceData: any;

  public clickExp = false;
  public clickHealthSefty = false;
  public clickSkill = false;
  
  public showBtn = false;
  
  private subscriptions: Subscription[] = [];
  private parmSub: Subscription;
  constructor(
    private usersService: UsersService,
    private modalCtrl: ModalController,
    // private formBuilder: FormBuilder,
    private constantVariable: ConstantVariables,
    private manageTableService: ManageTableService,
    private experienceService: ExperienceService,
    private userHealthAndSeftyService: UserHealthAndSeftyService,
    private alertCtrl: AlertController,
    private educationService: EducationService,
    private experienceCompanysService: ExperienceCompanysService,
    private globalService: GlobalService,
    private docService: DocumentWalletService,
    private userSkillSetService: UserSkillSetService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
  }
  
  public async ionViewDidEnter() {
    this.subscriptions.push(
      this.parmSub = this.activatedRoute.paramMap.subscribe(
        (params: ParamMap) => {
          const type = params.get('type');
          this.applicantId = type;
          if (type) {
            this.usersService.getUserById(type).subscribe(res => {
              this.user = res;
              this.showBtn = true;
              if(this.user && this.user.userType === 'user'){
                this.getUserEducationData();
                this.getUserExpData();
                this.getUserHealtSeftyData();
                this.getUserSkillSet();
                this.getUserExperienceCompanyData();
                this.getAge(this.user.dob);
                this.getAllDocumentData();
        
              }
               console.log('user',this.user)
            }, error => {
              console.error('error', error);
            })
          }
        },
    )); 
    this.subscriptions.push(
    );
  
    this.subscriptions.push(
      this.manageTableService.getAllData(this.constantVariable.manageJob).subscribe(res => {
        this.jobsData = res;
      }, error => {
        console.log('error', error);
      })
    )

  }

  ngOnDestroy() {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe(); 
    });
}

  public getAge(dateString) {
    var today = new Date();
    var DOB = new Date(dateString);
    var totalMonths = (today.getFullYear() - DOB.getFullYear()) * 12 + today.getMonth() - DOB.getMonth();
    totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
    var years = today.getFullYear() - DOB.getFullYear();
    if (DOB.getMonth() > today.getMonth())
      years = years - 1;
    else if (DOB.getMonth() === today.getMonth())
      if (DOB.getDate() > today.getDate())
        years = years - 1;

    var days;
    var months;

    days = today.getDate() - DOB.getDate();
    if (DOB.getMonth() === today.getMonth())
      months = (totalMonths % 12);
    else
      months = (totalMonths % 12);
    // const age = years + ' years ' + months + ' months ' + days + ' days';
    const age = years + ' years';
    this.userAge = age;
    // console.log('---------AGE', this.userAge)
  }

  public ionViewDidLeave(): void {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
      this.user = '';
    });
  }

  public async editUserInfo() {
    const modal = await this.modalCtrl.create({
      component: UserEditComponent,
      cssClass: this.user.userType === 'user' ? 'aboutYouProfile' : 'companyForm',
      mode: 'md',
      backdropDismiss: false,
      componentProps: { userInfo: this.user }
    });
    return await modal.present();
  }

  public getUserEducationData() {
    this.subscriptions.push(
      this.educationService.getAllEductions(this.user.docId).subscribe(res => {
        this.educationsDetails = res;
        // console.log('-------------', this.educationsDetails);
      }, error => {
        console.log('error', error);
      })
    );
  }

  public getUserExpData() {
    this.subscriptions.push(
      this.experienceService.getAllExperience(this.user.docId).subscribe(res => {
        if (res) {
          console.log(' this.experienceData',  res);
          this.addExperienceData = res[0];
          this.experienceData = res[0].experience;
          this.displayExperinceData = {};
          this.experienceData.map(data => {
            if (!this.displayExperinceData[data.jobType]) {
              this.displayExperinceData[data.jobType] = [];
            }
            this.displayExperinceData[data.jobType].push(data);
            console.log('display', this.displayExperinceData);
          })
          // console.log('this.displayExperinceData ---------',  this.displayExperinceData)
        }
      }, error => {
        console.log('error', error);
      })
    );
  }

  public getUserHealtSeftyData() {
    this.subscriptions.push(
      this.userHealthAndSeftyService.getAllHealthAndSefty(this.user.docId).subscribe(res => {
        if (res) {
          if (res[0]?.healthAndSafety && res[0]?.healthAndSafety.length) {
            // this.setHelthSeftyData(res[0].healthAndSafety);
            this.healthAndSafetyAllData = res[0];
            this.healthAndSafety = res[0].healthAndSafety;
            this.displayHealthAndSafetyData = {};

            this.healthAndSafety.map(data => {
              if (!this.displayHealthAndSafetyData[data.jobType]) {
                this.displayHealthAndSafetyData[data.jobType] = []
              }
              this.displayHealthAndSafetyData[data.jobType].push(data);
            })

            const keysMain = Object.keys(this.displayHealthAndSafetyData);
            keysMain.map(key => {
              const filteredData = {}
              this.displayHealthAndSafetyData[key].map(skey => {
                if (!filteredData[skey.healthAndSafety]) {
                  filteredData[skey.healthAndSafety] = []
                }
                filteredData[skey.healthAndSafety].push(skey.healthAndSafetySub);
              })
              this.displayHealthAndSafetyData[key] = filteredData;
            })
            // console.log('this.displayHealthAndSafetyData ---------',  this.displayHealthAndSafetyData)
          }
        }
      }, error => {
        console.log('error', error);
      })
    );
  }

  public getUserSkillSet() {
    this.subscriptions.push(
      this.userSkillSetService.getAllSkillSet(this.user.docId).subscribe(res => {
        if (res) {
          // console.log('res-------------', res);
          if (res[0].skillSet && res[0].skillSet.length) {
            // this.setHelthSeftyData(res[0].skillSet);
            // console.log(' this.experienceData',  res[0].experience)
            this.skillSetAllData = res[0];
            this.skillSet = res[0].skillSet;
            this.displayskillSetData = {};
            this.skillSet.map(data => {
              if (!this.displayskillSetData[data.jobType]) {
                this.displayskillSetData[data.jobType] = []
              }
              this.displayskillSetData[data.jobType].push(data);
            })
            const keysMain = Object.keys(this.displayskillSetData);
            keysMain.map(key => {
              const filteredData = {}
              this.displayskillSetData[key].map(skey => {
                if (!filteredData[skey.skillSet]) {
                  filteredData[skey.skillSet] = []
                }
                filteredData[skey.skillSet].push(skey.skillSetSub);
              })
              this.displayskillSetData[key] = filteredData;
            })
            // console.log('this.displayExperinceData ---------',  this.displayExperinceData)
          }
        }
      }, error => {
        console.log('error', error);
      })
    );
  }

  public getUserExperienceCompanyData() {
    this.subscriptions.push(
      this.experienceCompanysService.getAllExperienceCompanys(this.user.docId).subscribe(data => {
        this.experienceCompanyDetails = data;
      }, error => {
        console.log('error', error);
      })
    );
  }

  public async addEditModalEducation(type, data) {
  }

  private addEducationData(data) {
  }

  private editEducation(data, educationId) {
    data.userId = this.user.docId;
    this.subscriptions.push(
      this.educationService.updateEductions(educationId, data).subscribe(res => {
        if (res.status === 200) {
          this.globalService.showToastMessage('Edit education successfully.');
        } else {
          this.globalService.showToastMessage('Error edit education', 'error');
        }
      }, error => {
        console.error('error', error);
      })
    );
  }

  public async deleteEducationAlert(item) {
    const alert = await this.alertCtrl.create({
      message: `Are you sure you want to delete <b>"${item.education}"</b>`,
      mode: 'ios',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
          },
        },
        {
          text: 'Delete',
          role: 'confirm',
          handler: () => {
            this.deleteEducation(item);
          },
        },
      ],
    });

    await alert.present();
  }

  private deleteEducation(item) {
    this.subscriptions.push(
      this.educationService.deleteEducationData(this.user.docId, item).subscribe(res => {
        this.globalService.showToastMessage('Delete education successfully.');
      }, error => {
        console.log('error', error);
      })
    )
  }

  public async addEditModalexprience(type, data) {
    const modal = await this.modalCtrl.create({
      component: ExperienceDetailsComponent,
      cssClass: 'aboutExp',
      backdropDismiss: false,
      componentProps: {
        openForm: type,
        expData: data ? data : ''
      }
    });
    modal.onDidDismiss().then((res: any) => {
      if (res.role === 'Add') {
        this.addExprienceData(res.data);
      } else if (res.role === 'Edit') {
        res.data.docId = data.docId;
        this.editExprience(res.data);
      } else {
        console.log('Nothing chnaged!');
      }
    })
    return await modal.present();
  }

  private addExprienceData(data) {
    data.userId = this.user.docId;
    this.subscriptions.push(
      this.experienceCompanysService.addExperienceCompanys(data).subscribe(res => {
        console.log('res', res);
        if (res.status === 200) {
          this.globalService.showToastMessage('Add exprience successfully.');
        } else {
          this.globalService.showToastMessage('Error add exprience', 'error');
        }
      }, error => {
        console.error('error', error);
      })
    )
  }

  private editExprience(data) {
    data.userId = this.user.docId;
    this.subscriptions.push(
      this.experienceCompanysService.updateExperienceCompanys(data).subscribe(res => {
        if (res.status === 200) {
          this.globalService.showToastMessage('Edit education successfully.');
        } else {
          this.globalService.showToastMessage('Error edit education', 'error');
        }
      }, error => {
        console.log('error', error);
      })
    );
  }

  public async deleteExprience(item) {
    const alert = await this.alertCtrl.create({
      message: `Are you sure you want to delete <b>"${item.lastCompany}"</b> exprience`,
      mode: 'ios',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
          },
        },
        {
          text: 'Delete',
          role: 'confirm',
          handler: () => {
            this.deleteExprienceData(item);
          },
        },
      ],
    });

    await alert.present();
  }

  private deleteExprienceData(item) {
    this.subscriptions.push(
      this.experienceCompanysService.deleteExperienceData(this.user.docId, item).subscribe(res => {
        this.globalService.showToastMessage('Delete Exprience successfully.');
      }, error => {
        console.error('error', error);
      })
    )
  }

  public async addEditSkills(type) {
  }


  private updateExperience(data) {
    data.userId = this.user.docId;
    this.subscriptions.push(
      this.experienceService.updateExperience(data).subscribe(res => {
        if (res.status === 200) {
          this.globalService.showToastMessage('Experience update successfully.');
          this.clickExp = false;
        }
      }, error => {
        console.error('error', error)
      })
    )
  }

  private updateHealtSefty(data) {
    data.userId = this.user.docId;
    // console.log('data+++++++++++++++++', data);
    this.subscriptions.push(
      this.userHealthAndSeftyService.updateHealthAndSefty(data).subscribe(res => {
        if (res.status === 200) {
          this.globalService.showToastMessage('Health and sefty update successfully.');
          this.clickHealthSefty = false;
          // console.log('res', res);
        }
      }, error => {
        console.error('error', error)
      })
    )
  }

  private updateSkillSet(data) {
    data.userId = this.user.docId;
    this.subscriptions.push(
      this.userSkillSetService.updateSkillSet(data).subscribe(res => {
        if (res.status === 200) {
          this.globalService.showToastMessage('Skill add successfully.');
          this.clickSkill = false;
        }
      }, error => {
        console.error('error', error)
      })
    )
  }


  private addExperience(data) {
    data.userId = this.user.docId;
    this.subscriptions.push(
      this.experienceService.addExperience(data).subscribe(res => {
        if (res.status === 200) {
          this.globalService.showToastMessage('Experience add successfully.');
          this.getUserExpData();
          this.clickExp = false;
        }
      }, error => {
        console.error('error', error)
      })
      )
    }
    
    private addHealtSefty(data) {
      data.userId = this.user.docId;
      console.log('data', data);
      this.subscriptions.push(
        this.userHealthAndSeftyService.addHealthAndSefty(data).subscribe(res => {
          if (res.status === 200) {
            this.globalService.showToastMessage('Health and sefty add successfully.');
            this.getUserHealtSeftyData();
            this.clickHealthSefty = false;
        }
      }, error => {
        console.error('error', error)
      })
    )
  }

  private addSkillSet(data) {
    data.userId = this.user.docId;
    this.subscriptions.push(
      this.userSkillSetService.addSkillSet(data).subscribe(res => {
        if (res.status === 200) {
          this.getUserSkillSet();
          this.globalService.showToastMessage('Skill add successfully.');
          this.clickSkill = false;
        }
      }, error => {
        console.error('error', error)
      })
    )
  }


  public downloadDoc(item){
    // console.log('===========++++++++++', item);
    window.open(item.docImage);
  }



public async addDocument(type: any, data){

}

public getAllDocumentData(){
  this.subscriptions.push(
    this.docService.getDocumentData(this.user.docId).subscribe(res =>{
      // console.log('-------All Doc-------', res);
      this.allDocData = res;
      }, error => {
        console.error('error', error);
      })
    )
  }

  public uploadNewDocData(item){
    // console.log('----DATA----', item.data.companyDocId);
    this.globalService.showLoading();
    // item.educationId = item.data.educationDocId;
    this.subscriptions.push(
      this.docService.addDocData(item.data, this.user.docId).subscribe(res =>{
        console.log('***********', item.data.selectedPhoto);
        if(res.status === 200){
          this.docService.uploadFile(item.data.selectedPhoto, res.id, item.data.formData).then(val =>{
            console.log('-------val--------', val);
            // this.progress = (progressEvent.lengthComputable) ?  
            // Math.floor(progressEvent.loaded / progressEvent.total * 100) : -1;
            if(val){
              const data = {
                docImage: val,
              }
              this.subscriptions.push(
                this.docService.updateImageOnDetails(data, res.id, this.user.docId).subscribe(e =>{
                  // console.log('============', e);
                  if(res.status === 200){
                    
                    if(item.data.formData.docType === 'education'){
                      const obs = {
                        docWalletId: res.id,
                        userId: this.user.docId,
  
                      }
                      this.subscriptions.push(
                        this.educationService.updateDocWalletId('Eductions', item.data.educationDocId, obs).subscribe(i =>{
                          // console.log('***********', i);
                        }, error =>{
                          console.error('error', error);
                        })
                      )
                    } else if(item.data.formData.docType === 'company') {
                      const obj = {
                        docWalletId: res.id,
                        userId: this.user.docId,
  
                      }
                      this.subscriptions.push(
                        this.educationService.updateDocWalletId('ExperienceCompanys', item.data.companyDocId, obj).subscribe(i =>{
                          // console.log('***********', i);
                        }, error => {
                          console.error('error', error);
                        })
                      )
                    }
                  }
                  
                  this.globalService.hideLoading();
                  this.modalCtrl.dismiss();
                }, error => {
                  console.error('error', error);
                })
              )
            } else {
              this.globalService.hideLoading();
              this.modalCtrl.dismiss();
            }
          })
        } else {
          this.globalService.hideLoading();
        }
      }, error => {
         console.error('error', error);
      })
    )
  }

public updateDoc(data){
  // console.log('======res======', data);
  this.globalService.showLoading();
  data.userId = this.user.docId;
  this.subscriptions.push(
    this.docService.updateDocData(data).subscribe(res =>{
      // console.log('=====RES=======', res);
      if(res){
        this.globalService.hideLoading();
        this.globalService.showToastMessage('Document updated successfully.')
      } else {
        this.globalService.hideLoading();
        this.globalService.showToastMessage('Somthing went wrong!', 'error')
      }
    }, error => {
      console.error('error', error);
    })
  )
  }

  
  public async deleteAlert(data) {
    const alert = await this.alertCtrl.create({
      header: '',
      mode: 'ios',
      message: `Are you sure you want to delete <b>"${data.documentName}"</b>`,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Delete',
          handler: () => {
            this.deleteDoc(data);
          }
        }
      ]
    });
    return await alert.present();
  }

  public deleteDoc(data) {
    // console.log(data);
    this.subscriptions.push(
      this.docService.deleteDocData(this.user.docId, data).subscribe(res => {
        // console.log('*****************', res);
      }, error => {
        console.error('error', error);
      })
    )
  }

  public async shareAlert(data){
    const alert = await this.alertCtrl.create({
      header: 'Please copy link to share',
      buttons: [
        {
          text: 'Copy',
          role: 'confirm',
          handler: (alertData) => {
            this.copyUrl(data.docImage);
            this.globalService.showToastMessage('Url copied to clipboard');
          },
        },
      ],
      mode: 'ios',
      inputs: [
        {
          placeholder: 'Name',
          value: data.docImage,
          disabled: true,
        },
      ],
    });

    await alert.present();
  }

  private copyUrl(val){
    // console.log(val);
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public async logout() {

    const alert = await this.alertCtrl.create({
      header: '',
      mode: 'ios',
      message: 'Logout?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Okay',
          handler: () => {
            // console.log('+++++++++++++++++');
            this.subscriptions.push(
              this.usersService.logout().subscribe(res => {
                // console.log('res+++++++++++++++++++', res);
                this.user = '';
                this.router.navigate(['/support']);
              },  error => {
                console.error('error', error);
              })
            );
          }
        }
      ]
    });
    return await alert.present();
  }


  public async takePhotoFromCamera(type) {
    const comeraOptions: CameraOptions = {
      allowEditing: false,
      correctOrientation: true,
      direction: CameraDirection.Front,
      quality: 95,
      resultType: CameraResultType.Base64,
      source: null,
      saveToGallery: true
    };
    if (type === 'camera') {
      comeraOptions.source = CameraSource.Camera;
    } else {
      comeraOptions.source = CameraSource.Photos;
    }
    await Camera.getPhoto(comeraOptions).then(async selectedPhoto => {
      let journalImageInput = null;
      let imageArr: any;
      const img = new Image();
      img.src = 'data:image/jpeg;base64,' + selectedPhoto.base64String;
      img.onload = () => {
        const MAX_WIDTH = 1024;
        const MAX_HEIGHT = 1024;
        const canvas = document.createElement('canvas');
        const IMAGE_WIDTH = img.width;
        const IMAGE_HEIGHT = img.height;
        const scale = Math.min((MAX_WIDTH / IMAGE_WIDTH), (MAX_HEIGHT / IMAGE_HEIGHT));
        const iwScaled = IMAGE_WIDTH * scale;
        const ihScaled = IMAGE_HEIGHT * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        journalImageInput = canvas.toDataURL('image/jpeg', 0.5);
        imageArr = journalImageInput.split(',');
        this.selectedPhoto = imageArr;
        this.isImageSaved = true;
        this.tackNewImg = true;
        if(this.tackNewImg) {
          // this.router.navigate(['/profile']);
          this.updateAdminPhoto();
          
        }
      };
    }, (error: any) => {
      console.log('error', error);
      this.modalCtrl.dismiss(null, 'cancel');
    });
  }

  private async updateAdminPhoto() {
    const alert = await this.alertCtrl.create({
      header: '',
      mode: 'ios',
      message: 'Are you sure change profile photo?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            // console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Okay',
          handler: () => {
            // console.log('+++++++++++++++++');
            // this.subscriptions.push(
              this.usersService.updateAdminPhoto(this.selectedPhoto, this.user.docId).then( res => {
                this.router.navigate(['/profile']);
                console.log('update photo ',res);
              })
            // );
          }
        }
      ]
    });
    return await alert.present();
  }
  

  public async editEmailAndMobile(): Promise<any> {

  }

  public cancel() {
    this.router.navigate(['news-feed']);
  }

  public shareProfile() {
    navigator.clipboard.writeText(window.location.href);
    this.globalService.showToastMessage('Url copied to clipboard')
  }


}
