/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable arrow-body-style */
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Observable, Subject, of, BehaviorSubject, observable } from 'rxjs';
import { switchAll, switchMap, map, finalize } from 'rxjs/operators';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { GlobalService } from 'src/app/services/global.service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { Action } from 'rxjs/internal/scheduler/Action';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/compat/storage';

@Injectable({
  providedIn: 'root'
})

export class UsersService {
  public authenticationStatus: Observable<any>;
  public user$: Observable<any>;
  public userInfo: any;
  public validOldPassword = false;
  authState = new BehaviorSubject(false);

  private authenticationStatusSubject = new Subject<Observable<any>>();
  private tableName: string | null = null;
  response: { status: number; message: string; data: any };
  docId: any;
  task: AngularFireUploadTask;
  percentage: Observable<number>;
  url: any;
  blob: any;
  chunks: BlobPart[];

  downloadVideoUrlSource = new BehaviorSubject(null);
  downloadVideoUrl = this.downloadVideoUrlSource.asObservable();



  constructor(
    private afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private router: Router,
    // public location: Location,
    public modalController: ModalController,
    private globalService: GlobalService,
    private storage: AngularFireStorage
  ) {
    this.authenticationStatus = this.authenticationStatusSubject.pipe(
      switchAll()
    );
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          switch ('Users') {
            case 'Users':
              this.tableName = 'Users';
              return this.db.collection(this.tableName).doc(user.uid).valueChanges().pipe(map(res => {
                return res;
              }));
          }
        } else {
          return of(null);
        }
      })
    );
  }

  public sendConversation(data, jobId): Observable<any> {
    const obj = new Observable((observer) => {
      this.db.collection('Jobs').doc(jobId).collection('Conversation').add(data).then(async (res: any) => {
        observer.next({
          status: 200,
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obj;
  }

  public updateUser(userData: any,): Observable<any> {
    const obs = new Observable((observer) => {
      this.db.collection('Users').doc(userData.docId).update(userData).then(async () => {
        observer.next({
          message: 'Profile Edit successfuly.',
          status: 200,
        });
      }).catch((error) => {
        observer.next({
          message: error.Error,
          status: 500,
        });
      });
    });
    return obs;
  }

  public logout(): Observable<any> {
    const obs = new Observable((observer) => {
      firebase.auth().signOut().then(async () => {
        observer.next({
          message: 'Logout successfuly.',
          status: 200,
        });
      }).catch((error) => {
        observer.next({
          message: error.Error,
          status: 500,
        });
      });
    });
    return obs;
  }

  public sendForgotPasswordLink(email: string): Observable<any> {
    if (email) { email = email.toLowerCase(); }
    const obs = new Observable(observable => {
      this.afAuth.sendPasswordResetEmail(email)
        .then(async () => {
          observable.next({
            status: true,
          });
        }).catch((error) => {
          observable.next({
            status: false,
            message: error
          });
        });
    });
    return obs;
  }


  async checkUserExist(phone: any): Promise<any> {
    // alert(phone)
    return this.db.collection('Users', ref => ref.where('phone', '==', phone).limit(1)).get().toPromise().then(response => {
      return response.empty;
    }, error => {
      console.log('error', error);
      return error;
    });
  }

  async checkAdminExist(email: any): Promise<any> {
    return this.db.collection('Users', ref => ref.where('email', '==', email).limit(1)).get().toPromise().then(response => {
      return response.empty;
    }, error => {
      console.log('error', error);
      return error;
    });
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public sendOtp(num: any, appVerifier: any): Observable<any> {
    const obs = new Observable((observer) => {
      firebase.auth().signInWithPhoneNumber(num, appVerifier)
        .then(async (res) => {
          const expiryTime = new Date().toISOString();
          localStorage.setItem('OTPGeneratedTime', expiryTime);
          observer.next({
            message: 'OTP sent successfuly.',
            status: 200,
            data: res
          });
        }).catch((error) => {
          console.error('error', error);
          observer.next({
            message: error.Error,
            status: 500,
          });
        });
    });
    return obs;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public signupUser(windowRef: any, signinForm: any): Observable<any> {
    const obj = new Observable((observer) => {
      windowRef.confirmationResult.confirm(signinForm.otp).then((data) => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            signinForm.docId = data.user.uid;
            signinForm.cratedDate = new Date();
            signinForm.phone = '+' + signinForm.countryCode + signinForm.mobile;
            signinForm.userType = 'user';
            signinForm.nextStep = 1;
            delete signinForm.otp;
            this.addUserInUserCollection(signinForm).subscribe();
          })
          .catch((error) => {
            observer.next({
              message: error.message,
              status: 500,
            });
          });
      }, error => {
        console.error('error register unction in service', error);
        observer.next({
          message: error,
          status: 500,
        });
      });
    });
    return obj;
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  public login(windowRef: any, signinForm: any): Observable<any> {
    const obj = new Observable((observer) => {
      windowRef.confirmationResult.confirm(signinForm.otp).then((data) => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            observer.next({
              status: 200,
              message: 'Login successfuly.',
              data: null
            });
          })
          .catch((error) => {
            observer.next({
              message: error,
              status: 500,
            });
          });
      }, error => {
        console.error('error register unction in service', error);
        observer.next({
          message: error,
          status: 500,
        });
      });
    });
    return obj;
  }


  public setTempCompanyData(data: any): Observable<any> {
    delete data.companyName;
    delete data.password;
    // const otp = Math.floor((Math.random()*1000000)+1);
    const otp = Math.floor(100000 + Math.random() * 900000);
    data.otp = otp;
    const obs = new Observable((observer) => {
      this.db.collection('tempCompany').doc(data.email).set(data, this.docId).then(async (res: any) => {
        observer.next({
          status: 200,
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  async matchOtp(email: any): Promise<any> {
    try {
      // tslint:disable-next-line: max-line-length
      return this.db.collection('tempCompany', ref => ref.where('email', '==', email)).get().toPromise().then(res => {
        return res.docs.map(data => {
          const id = data.id;
          const info = data.data();
          return { docId: id, ...info as {} };
        });
      });
    } catch (e) {
      return e;
    }
  }

  public registerAdmins(value: any): Observable<any> {
    const obj = new Observable((observer) => {
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password).then((data: any) => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            value.docId = data.user.uid;
            value.cratedDate = new Date();
            value.registerDate = new Date(value.cratedDate).toLocaleString('en-au', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' });
            value.userType = 'admin';
            value.activeStatus = false;
            value.paymentStatus = false;
            value.companyStatus = 'pending';
            this.db.collection('Users').doc(data.user.uid).set(value).then(async () => {
              observer.next({
                status: 200,
                message: 'Account created successfuly.',
                data: null
              });
            }).catch((error) => {
              return error.message;
            });
          })
          .catch((error) => {
            observer.next({
              message: error.message,
              status: 500,
            });
          });
      }, error => {
        console.error('error register unction in service--', error);
        observer.next({
          message: error.message,
          status: 500,
        });
      });
    });
    return obj;
  }

  public loginWithEmail(value): Observable<any> {
    const obj = new Observable((observer) => {
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(() => {
          firebase.auth().signInWithEmailAndPassword(value.email, value.password).then(data => {
            if (data) {
              observer.next({
                message: data,
                status: 200,
              });
            }
          }, error => {
            observer.next({
              message: error,
              status: 500,
            });
          });
        })
        .catch((error) => {
          observer.next({
            message: error.message,
            status: 500,
          });
        });
    });
    return obj;
  }


  public getPendingAdmins(tableName, type: string) {
    // eslint-disable-next-line max-len
    return this.db.collection(tableName, ref => ref.where('userType', '==', type).where('companyStatus', '==', 'pending').orderBy('cratedDate', 'desc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            return { docId, ...data };
          });
        })
      );
  }

  public deleteTempAdminData(email: any): Observable<any> {
    const obs = new Observable((observer) => {
      this.db.collection('tempCompany').doc(email).delete().then(async (res: any) => {
        observer.next({
          status: 200,
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public getApproveAdmins(tableName, type: string) {
    // eslint-disable-next-line max-len
    return this.db.collection(tableName, ref => ref.where('userType', '==', type).where('companyStatus', '==', 'approve').orderBy('cratedDate', 'desc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            return { docId, ...data };
          });
        })
      );
  }

  public getRejectAdmins(tableName, type: string) {
    // eslint-disable-next-line max-len
    return this.db.collection(tableName, ref => ref.where('userType', '==', type).where('companyStatus', '==', 'reject').orderBy('cratedDate', 'desc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            return { docId, ...data };
          });
        })
      );
  }

  public addFavourite(userDocId, data: any, subCollection): Observable<any> {
    let sendObj = {};
    if(subCollection === 'favouriteJobs'){
        sendObj = { jobDocId: data.docId, userDocId: userDocId }  
      } else {
        sendObj = { trainingDocId: data.docId, userDocId: userDocId }
    }
    const obj = new Observable(obs => {
      this.db.collection('Users').doc(userDocId).collection(subCollection).doc(data.docId).set(sendObj).then(res => {
        obs.next({
          status: 200,
          message: 'favourite jobs add successfully'
        })
      }).catch((error => {
        obs.next({
          status: 400,
          message: 'error'
        })
      }))
    });
    return obj;

  }


  public getAllFavourite(docId, subCollection) {
    // const obj = new Observable((observe ) => {

    return this.db.collection('Users').doc(docId).collection(subCollection).snapshotChanges()
      .pipe(
        map((action) => {
          return action.map(doc => {
            const docId = doc.payload.doc.id;
            const data: any = doc.payload.doc.data();
            return { docId, ...data }
          })
        })
      )
    // })

  }


  public deleteFavourite(userDocId, favDocId, subCollection): Observable<any> {
    const obj = new Observable(obs => {
      this.db.collection('Users').doc(userDocId).collection(subCollection).doc(favDocId).delete().then(res => {
        obs.next({
          status: 200,
          message: 'favourite jobs delete successfully'
        })
      }).catch((error => {
        obs.next({
          status: 400,
          message: 'error'
        })
      }))
    });

    return obj;
  }

  public saveFilterData(data, docId, filterSubCollection): Observable<any> {
    const obj = new Observable((observer) => {
      data.userDocId = docId;
      this.db.collection('Users').doc(docId).collection(filterSubCollection).add(data).then(async (res: any) => {
        observer.next({
          status: 200,
          id: res.id
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obj;
  }

  public deleteVideo(docId): Observable<any> {
    const deleteObj = {
      verifiedUser: false,
      video: ''
    }
    const obs = new Observable((observer) => {
      this.db.collection('Users').doc(docId).update(deleteObj).then(async () => {
         const basePath = `${'Users'}/${'Users-video'}/${docId}.mp4`
         firebase.storage().ref().child(basePath).delete();
         this.downloadVideoUrlSource.next('')
      }).catch((error) => {
        observer.next({
          message: error.
          message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }
  public deleteVideoFromMobile(docId): Observable<any> {
    const deleteObj = {
      verifiedUser: false,
      video: ''
    };
    const obs = new Observable((observer) => {
      this.db.collection('Users').doc(docId).update(deleteObj).then(async () => {
         const basePath = `${'Users'}/${'Users-video'}/${docId}.mp4`;
         firebase.storage().ref().child(basePath).delete();
         this.downloadVideoUrlSource.next('')
          observer.next({
          message: 'videol delelete succfully',
          status: 200,
        });
      }).catch((error) => {
        console.log('videol delelete error', error);

        observer.next({
          message: error.
          message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }


  // public saveTrainingFilterData(data, docId): Observable<any> {
  //   // console.log('========', data, docId);
  //   // const senderInfo = data;
  //   const obj = new Observable((observer) => {
  //     data.docId = docId;
  //     this.db.collection('Users').doc(docId).collection('Training-filter').add(data).then(async (res: any) => {
  //       observer.next({
  //         status: 200,
  //       });
  //     }).catch((error) => {
  //       observer.next({
  //         message: error.message ? error.message : 'There is some issue...',
  //         status: error.code ? error.code : 400,
  //       });
  //     });
  //   });
  //   return obj;
  // }

  public getSaveFilter(id): Observable<any> {
    return this.db.collection('Users').doc(id).collection('Jobs-filter').snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            return { docId, ...data, };
          });
        })
      );
  }
  public getTrainingSaveFilter(id): Observable<any> {
    return this.db.collection('Users').doc(id).collection('Training-filter').snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            return { docId, ...data, };
          });
        })
      );
  }

  public deleteSavedFilter(id) {
    const obs = new Observable((observer) => {
      this.db.collection('Users').doc(id.userDocId).collection('Jobs-filter').doc(id.docId).delete().then(async () => {
        observer.next({
          status: 200,
        })
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public deleteSavedTrainingFilter(id) {
    const obs = new Observable((observer) => {
      this.db.collection('Users').doc(id.userDocId).collection('Training-filter').doc(id.docId).delete().then(async () => {
        observer.next({
          status: 200,
        })
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public userAdminEditInfo(id, data): Promise<any> {
     return this.db.collection('Users').doc(id).update(data).then( () => {
      }).catch((error) => {
        console.error('error', error);
      });
  }

  public updateAdminEmail(id , data): Observable<any> {
    const obs = new Observable((observer) => {
      const user = firebase.auth().currentUser;
      this.reAuthenticateEmail().then(async () => {
       return  user.updateEmail(data.email).then( res => { 
        this.db.collection('Users').doc(id).update(data).then(async (res: any) => {
          observer.next({
            status: 200,
          });
        }).catch((error) => {
          observer.next({
            message: error.message ? error.message : 'There is some issue...',
            status: error.code ? error.code : 400,
          });
        });
       })
     });
    });
    return obs;
  }

  async reAuthenticateEmail(): Promise<any> {
    const user = firebase.auth().currentUser;
    const cred = firebase.auth.EmailAuthProvider.credential(
      user.email, user.providerData[0].providerId);
      return user.reauthenticateWithCredential(cred).then( res => {
      }, error => {
        console.error('error',error);
      });

  }

  public async updateUserMobile(otp, data, id, recapt) {
      const verificationId = recapt.confirmationResult.verificationId;
      const phoneCredential = firebase.auth.PhoneAuthProvider.credential(verificationId, otp);
      return firebase.auth().currentUser.updatePhoneNumber(phoneCredential)
      .then(()=>{
        this.db.collection('Users').doc(id).update(data).then(()=>{ //update my user database
        });
      });
  }

  public updateMobileOtp(mobile , recapt) {
   return firebase.auth().currentUser.reauthenticateWithPhoneNumber(mobile,recapt)
    .then(result => {
      const confirmationResult = result;//firebase.auth.ConfirmationResult
      return  confirmationResult
    })
    .catch(error=>{
      console.error('error',error)
    });
  }

  public getSuperAdminStates(): Observable<any> {
    return this.db.collection('superAdminStats').snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            return { docId, ...data, };
          });
        })
      );
  }

  public getAllUsers(): Observable<any> {
    return this.db.collection('Users', ref => ref.where('userType', '==', 'user')).snapshotChanges()
      .pipe(
        map(action => {
          return action.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            return { docId, ...data };
          });
        })
      );
  }

  public async updateAdvCover(selectedPhoto, id, formData?:any): Promise<any> {
    const response = await fetch(selectedPhoto);
    const blob = await response.blob();

    const metadata = {
      contentType: 'image/jpeg',
    };

    const photoUrl = `${'Users'}/${'Users-profile'}/${id}.jpeg`;
    const ref = firebase
      .storage()
      .ref()
      .child(photoUrl);
      ref.put(blob, metadata).then(obj => {
      ref.getDownloadURL().then(async (url) => {
          formData.photo = url;
          this.userAdminEditInfo( id , formData).then(() => {
          }, error => {
            console.error('error', error);
          })
      });
    });
  }

  async uploadFile(selectedVideo, id){
    const storageRef = firebase.storage().ref();
    return new Promise(function (resolve, reject) {
      let filePath = `${'Users'}/${'Users-video'}/${id}.mp4`;
      
      const task = storageRef.child(filePath).put(selectedVideo.file);
      
      task.on(
        "state_changed",
        function progress(snapshot) {
          const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // resolve(percentage);
        },
        

        function error(err) {
          reject(err);
        },

        async function complete() {
          function progress(snapshot) {
            const percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          }

          const imageURL = await task.snapshot.ref.getDownloadURL().then();
          resolve(imageURL);
        }
      );
    });
  }

  uploadFileVideo(selectedPhoto, id): Observable<any>  {
    const file = selectedPhoto.file;
    const filePath = `${'Users'}/${'Users-video'}/${id}.mp4`;
    const fileRef = this.storage.ref(filePath);
    this.task = this.storage.upload(filePath, file);

    let percentage:any = ''
    this.task.percentageChanges().subscribe(snapshot => {
      percentage = snapshot
      if(percentage === 100){
        this.globalService.showLoading();
      }
    })
    return this.task.snapshotChanges().pipe(
      finalize(() => {
        const downloadUrl = fileRef.getDownloadURL().subscribe(res => {
          this.url = res;
          if(res){
            const data: any = {
              docId: id,
              video: res,
              verifiedUser: true
            };
            this.updateUser(data).subscribe(res => {
              this.globalService.hideLoading();
            }, error => {
              this.globalService.hideLoading();
              console.error('error', error);
            });
          }else {

          }
        });
      })
    );
  }
  uploadFileVideoForMobile(selectedPhoto, id): Observable<any>  {
    const file = selectedPhoto;
    const filePath = `${'Users'}/${'Users-video'}/${id}.mp4`;
    const fileRef = this.storage.ref(filePath);
    this.task = this.storage.upload(filePath, file);

    let percentage: any = '';
    this.task.percentageChanges().subscribe(snapshot => {
      percentage = snapshot;
      if(percentage === 100){
        this.globalService.showLoading();
      }
    })
    return this.task.snapshotChanges().pipe(
      finalize(() => {
        const downloadUrl = fileRef.getDownloadURL().subscribe(res => {
          this.url = res;
          if(typeof res === 'string' && percentage === 100){
            console.log('IN SERVICE INNER===', res);
            const completeurl = {
              url: res
            };
            this.globalService.hideLoading();

            this.getDownloadurl(completeurl)
            return completeurl;
          }else {
            const completeurl = {
              url: res
            };
            this.globalService.hideLoading();
            return completeurl;
          }
        });
        const videoUrl = {
          url: downloadUrl
        }
        this.globalService.hideLoading();
        return videoUrl;
      })
    );
  }

  

  uploadCaptureVideo(capturedVideo, id){
    // const blob = new Blob(this.chunks, { type: 'video/webm' });
      const filePath = `${'Users'}/${'Users-video'}/${id}.mp4`;
      const ref = this.storage.ref(filePath);
      const task:any = ref.put(capturedVideo);

      task.snapshotChanges().pipe(
        finalize(() => {
          ref.getDownloadURL().subscribe(url => {
            const data = {
              video: url,
              docId: id,
              verifiedUser: true
            }
            this.updateUser(data).subscribe(res => {
              this.globalService.hideLoading();
            }, error => {
              this.globalService.hideLoading();
              console.error('error', error);
            });
          });
        })
      ).subscribe();
  }

  public async updateAdminPhoto(selectedPhoto , id) {
    const response = await fetch(selectedPhoto);
    const blob = await response.blob();

    const metadata = {
      contentType: 'image/jpeg',
    };

    const photoUrl = `${'Users'}/${'Users-profile'}/${id}.jpeg`;
    const ref = firebase
      .storage()
      .ref()
      .child(photoUrl);
    ref.put(blob, metadata).then(obj => {
      ref.getDownloadURL().then(async (url) => {
        const data = {
          photo: url,
        };
          this.userAdminEditInfo( id , data).then(res => {

          }, error => {
            console.error('error', error);
          })
      });
    });
  }

  public getJobQue(): Observable<any> {
    return this.db.collection('jobQueue',ref => ref.where('type' , '==' , 'old').where('over' , '==' , false).orderBy('dateAdded','desc')).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // this.getJobFavUser(doc[0].docId) 
            return { docId, ...data, };
          });
        })
      );
  }

  public getJobFavUser(jobDocId): Observable<any> {
    return this.db.collectionGroup('favouriteJobs',ref => ref.where('jobDocId' , '==' , jobDocId)).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // this.getJobFavUser(doc[0].docId) 
            return { docId, ...data, };
          });
        })
      );
  }

  public getJobApplyUser(jobDocId): Observable<any> {
    return this.db.collectionGroup('Applicants',ref => ref.where('jobDocId' , '==' , jobDocId)).snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map(doc => {
            const data: any = doc.payload.doc.data();
            const docId = doc.payload.doc.id;
            // this.getJobFavUser(doc[0].docId) 
            return { docId, ...data, };
          });
        })
      );
  }

  public sendNotification(notiData): Observable<any> { 
    const obj = new Observable((observer) => {
     this.db.collection('Users').doc(notiData.userDocId).collection('notification').add(notiData).then( res => {
      observer.next({
        status: 200
      })
    }).catch(( error) => {
      observer.next({
        status: 400
      })
    })
  })
  return obj
  }

  public updateJobQueue(id): Observable<any> {
    // data.updatedDate = new Date();
    const obs = new Observable((observer) => {
      this.db.collection('jobQueue').doc(id).update({over: true}).then(async (res: any) => {
        observer.next({
          status: 200,
        });
      }).catch((error) => {
        observer.next({
          message: error.message ? error.message : 'There is some issue...',
          status: error.code ? error.code : 400,
        });
      });
    });
    return obs;
  }

  public getUserById(userId:any): Observable<any> {
    return this.db.collection('Users').doc(userId).valueChanges();
  }

  async updateUserPushSessionId( userid: string, data: any):Promise<any> {
    try {
      return this.db.collection('Users').doc(userid).update(data).then( res => {
        return res;
      })
    } catch(e) {
      return e;
    }
  }

  public signupIOSUser(authUser, data) {
    return this.db.collection('Users').doc(authUser.uid).set(data).then(async () => {
    }).catch((error) => {
      return error.message;
    });
  }

  public getDownloadurl(downloadUrl) {
    this.globalService.hideLoading();
    this.downloadVideoUrlSource.next(downloadUrl)
  }

  public checkUserProfileUrl(userInfo): Observable<any> {
    const filePath = `Users/${userInfo.id}/_profile.jpeg`
    const storageFileRef = this.storage.ref(filePath); // Get a reference to the storage file
    const obs = new Observable(observer => {

      storageFileRef
      .getMetadata() // Use getMetadata to check if the file exists
      .subscribe(
        () => {
          observer.next({
            status: 200,
            fileExists: true,
          });
        },
        (error) => {
          if (error.code === 'storage/object-not-found') {
            observer.next({
              status: 500,
              fileExists: false,
            });
          } else {
            console.error('Error checking file existence:', error);
          }
        }
      );
    })
    return obs;
  }

  public deleteUserAccount(userData: any): Observable<any> {
    const obs = new Observable((observer) => { 
      this.db.collection('Users').doc(userData.docId).delete().then(() => {
        if (userData.video && userData.video !== '') {
          const basePath = `${'Users'}/${'Users-video'}/${userData.docId}.mp4`;
          firebase.storage().ref().child(basePath).delete();
        } 
        if (userData.photo && userData.photo !== '') {
          const basePath = `${'Users'}/${'Users-profile'}/${userData.docId}.jpeg`;
          firebase.storage().ref().child(basePath).delete();
        }
        observer.next({
          status: 200,
          message: 'Account created successfuly.',
          data: null
        });
      }).catch((error) => {
        return error.message;
      });
    })
    return obs;
  }

  public addUserInUserCollection(data: any): Observable<any>{
    const obj = new Observable((observer) => {
      this.db.collection('Users').doc(data.docId).set(data).then(async () => {
        observer.next({
          status: 200,
          message: 'Account created successfuly.',
          data: null
        });
  
      }).catch((error) => {
        return error.message;
      });
    })
    return obj;
  }

}

