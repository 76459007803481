import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/api-services/users/users.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-manage-saved-training',
  templateUrl: './manage-saved-training.component.html',
  styleUrls: ['./manage-saved-training.component.scss'],
})
export class ManageSavedTrainingComponent implements OnInit {

  
  @Input() savedTraining: any;
  
  private subscriptions: Subscription[] = [];

  constructor(
    private usersService: UsersService,
    private alertCtrl: AlertController,
    private globalService: GlobalService,
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() {
  }
  
  public ionViewDidEnter(): void {
    this.close();

  }

  public ionViewDidLeave(): void {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    });
  }

public async confDelete(id, i){
  const alert = await this.alertCtrl.create({
    message: `Are you sure you want to delete <b>"${id.filterLabel}"</b>`,
    backdropDismiss: false,
    cssClass: 'no-approve',
    mode: 'ios',
    buttons: [
      {
        text: 'Cancel',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked!')
        },
      },
      {
        text: 'Delete',
        role: 'confirm',
        handler: () => {
          this.deleteSavedTraining(id, i);
        },
      },
    ],
  });
  await alert.present();
}

  public deleteSavedTraining(id, i){
    this.globalService.showLoading();
    this.subscriptions.push(
      this.usersService.deleteSavedTrainingFilter(id).subscribe(res =>{
        // console.log('***********', res);
        if(res){
          this.savedTraining.splice(i, 1);
          this.close();
          this.globalService.hideLoading();
          this.globalService.showToastMessage('Delete filter successfully..');
        }
      }, error => {
        console.error('error', error);
      })   
    );
  }

  public cancel(){
    this.modalCtrl.dismiss('close');
  }

  public close(){
    // console.log('---------------', this.savedTraining.length);
    if(this.savedTraining.length === 0){
      this.cancel();
    } else {
      
    }
  }

}
