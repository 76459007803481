import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConstantVariables } from 'src/const/constant';

@Component({
  selector: 'app-experience-details',
  templateUrl: './experience-details.component.html',
  styleUrls: ['./experience-details.component.scss'],
})
export class ExperienceDetailsComponent implements OnInit {
  public openForm;
  public expData;
  public aboutExpForm: FormGroup = new FormGroup({
    lastCompany: new FormControl(''),
    country: new FormControl(''),
    province: new FormControl(''),
    county: new FormControl(''),
    // fromYear: new FormControl(''),
    // toYear: new FormControl(''),
  });
  private subscriptions: Subscription[] = [];
  public loading = false;
  public dob: string;
  public countiesList = [];
  public provincesList = [];
  public cuntryList = [];

  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private constantVariable: ConstantVariables
  ) {
    this.cuntryList = this.constantVariable.cuntry;

    // this.provincesList = this.constantVariable.provinces;
  }

  ngOnInit() {
    if(this.expData){
      console.log('this.expData', this.expData)
      if (this.expData?.country === 'Ireland') {
        this.provincesList = this.constantVariable.provinces;
      } else if(this.expData?.country === 'United Kingdom'){
        this.provincesList = this.constantVariable.ukProvinces;
      } else if(this.expData?.country === 'European Union'){
        this.provincesList = this.constantVariable.euProvinces;
      }
      const selectedVal = (this.expData?.province).split(" ").join("");
      this.countiesList = this.constantVariable[selectedVal];
    }

    this.aboutExpForm = this.formBuilder.group({
      lastCompany: [this.expData && this.expData?.lastCompany ? this.expData?.lastCompany : ''],
      country: [this.expData && this.expData?.country ? this.expData?.country : ''],
      province: [this.expData && this.expData?.province ? this.expData?.province : ''],
      county: [this.expData && this.expData?.county? this.expData?.county : ''],
      city: [this.expData && this.expData?.city ? this.expData?.city : ''],
      // fromYear: [this.expData && this.expData?.fromYear? this.expData?.fromYear : ''],
      // toYear: [this.expData && this.expData?.toYear ? this.expData?.toYear : ''],
    });
  }
  public ionViewDidLeave(): void {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    });
  }

  public close() {
    this.modalCtrl.dismiss(null, 'cancel');
  }
  
  
  public saveExpData(type) {
    this.modalCtrl.dismiss(this.aboutExpForm.value, type);
  }

  public onCountryChange(event){
    this.aboutExpForm.get('province').setValue('');
    this.aboutExpForm.get('county').setValue('');
    if (event.detail.value === 'Ireland') {
      this.provincesList = this.constantVariable.provinces;
    } else if(event.detail.value === 'United Kingdom'){
      this.provincesList = this.constantVariable.ukProvinces;
    }else if(event.detail.value === 'European Union'){
      this.provincesList = this.constantVariable.euProvinces;
    }
  }

  public onprovinceChange(event){
    this.aboutExpForm.get('county').setValue('');
    const selectedVal = (event.detail.value).split(" ").join("");
    this.countiesList = this.constantVariable[selectedVal];
  }

}
