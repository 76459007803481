import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { GlobalService } from 'src/app/services/global.service';
import { UsersService } from 'src/app/api-services/users/users.service';
import { Subscription } from 'rxjs';
import { ConstantVariables } from 'src/const/constant';
import { Camera, CameraDirection, CameraOptions, CameraResultType, CameraSource } from '@capacitor/camera';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss'],
})
export class UserEditComponent implements OnInit {

  private subscriptions: Subscription[] = [];
  public loading = false;
  public userInfo: any;
  public editUserInfoForm: FormGroup = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
  });
  public editCompanyInfoForm: FormGroup = new FormGroup({
    companyName: new FormControl(''),
    email: new FormControl(''),
  })
  availableFrom: string;
  availableUntil: string;
  currentProvincesList: any;
  provincesList: any;
  countiesList: any;
  currentCountiesList: any;
  dob: string;
  cuntryList: string[];
  public selectedPhoto: any;
  public isImageSaved: boolean;
  private tackNewImg: boolean;

  public validation_messages: any = {

    firstName: [
      { type: 'required', message: 'First name is required.' },
      { type: 'pattern', message: 'Enter a valid first name.' },
    ],
    companyName: [
      { type: 'required', message: 'Company name is required.' },
    ],
    email: [
      { type: 'required', message: 'Email is required.' },
      { type: 'email', message: 'Incorrect email.' }
    ],
    password: [
      { type: 'required', message: 'Password is required.' },
    ],
    lastName: [
      { type: 'required', message: 'Last name is required.' },
      { type: 'pattern', message: 'Enter a valid last name' },
    ],
    mobile: [
      { type: 'required', message: 'Mobile number is required.' },
      { type: 'pattern', message: 'Enter a valid mobile number.' }
    ],
    countryCode: [
      { type: 'required', message: 'Country code is required.' },
      { type: 'pattern', message: 'Enter a valid country code' }
    ],
    otp: [
      { type: 'required', message: 'OTP is required.' },
    ],
    // userType: [
    //   { type: 'required', message: 'User Type is required.' },
    // ],
  };

  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private globalService: GlobalService,
    private usersSerive: UsersService,
    private constantVariable: ConstantVariables,
    private router: Router,
  ) {}
  
  ngOnInit() {
     console.log('userinfo', this.userInfo)
     if(this.userInfo) {
      this.selectedPhoto = this.userInfo?.photo;
      this.isImageSaved = true;
     }
    this.cuntryList = this.constantVariable.cuntry;

    if(this.userInfo && this.userInfo.userType === 'user'){
      if (this.userInfo?.currentCountry === 'Ireland') {
        this.currentProvincesList = this.constantVariable.provinces;
      } else if(this.userInfo?.currentCountry === 'United Kingdom'){
        this.currentProvincesList = this.constantVariable.ukProvinces;
      } else if(this.userInfo?.currentCountry === 'European Union'){
        this.currentProvincesList = this.constantVariable.euProvinces;
      }
  
      const selectedVal = (this.userInfo?.currentProvince).split(" ").join("");
      this.currentCountiesList = this.constantVariable[selectedVal];
  
      this.editUserInfoForm = this.fb.group({
          firstName: [this.userInfo?.firstName ? this.userInfo?.firstName : '', Validators.compose([Validators.required,Validators.pattern(/^[a-zA-Z0-9 ]+$/)])],
          lastName: [this.userInfo?.lastName ? this.userInfo?.lastName : '', Validators.compose([Validators.required, Validators.pattern(/^[a-zA-Z0-9 ]+$/)])],
          // dob: [this.userInfo?.dob ? this.userInfo?.dob : '', Validators.compose([Validators.required])],
          
          currentCountry: [ this.userInfo?.currentCountry ? this.userInfo?.currentCountry : '', Validators.compose([Validators.required])],
          currentProvince: [this.userInfo?.currentProvince ? this.userInfo?.currentProvince : ''],
          currentCounty: [this.userInfo?.currentCounty ? this.userInfo?.currentCounty : ''],
          currentCity: [ this.userInfo?.currentCity ? this.userInfo?.currentCity : ''],
          // availableUntil: [this.userInfo?.availableUntil ? this.userInfo?.availableUntil : ''],
          // availableFrom: [ this.userInfo?.availableFrom ? this.userInfo?.availableFrom : ''],
      })
    } else if(this.userInfo.userType === 'admin') {
      this.editCompanyInfoForm = this.fb.group({
          companyName: [this.userInfo?.companyName ? this.userInfo?.companyName : '', Validators.compose([Validators.required])],
          email: [this.userInfo?.email ? this.userInfo?.email : '', Validators.compose([Validators.required])],
      },{updateOn: 'blur'});
    } else if (this.userInfo.userType === 'superAdmin'){
      
    }
  }

  public cancel(){
    this.modalCtrl.dismiss();
  }

  public ionViewDidLeave(): void {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    });
  }

  public updateUserInfo() {
    // console.log(this.editUserInfoForm.value);
    this.loading = true;

    // this.editUserInfoForm.value.timeStampDob = new Date(this.editUserInfoForm.value.dob);
    // const tempFrom = new Date(this.editUserInfoForm.value.availableFrom)
    // tempFrom.setHours(0);
    // tempFrom.setMinutes(0);
    // tempFrom.setSeconds(1);
    // this.editUserInfoForm.value.timeStmpavailableFrom = tempFrom;

    // const tempUntil = new Date(this.editUserInfoForm.value.availableUntil)
    // tempUntil.setHours(23);
    // tempUntil.setMinutes(59);
    // tempUntil.setSeconds(59);
    // this.editUserInfoForm.value.timeStmpavailableUntil = tempUntil;

    // this.editUserInfoForm.value.timeStmpavailableFrom = isNaN(this.editUserInfoForm.value.timeStmpavailableFrom) === false ? this.editUserInfoForm.value.timeStmpavailableFrom : '';
    // this.editUserInfoForm.value.timeStmpavailableUntil = isNaN(this.editUserInfoForm.value.timeStmpavailableUntil) === false ? this.editUserInfoForm.value.timeStmpavailableUntil : '';

    if (this.tackNewImg) {
      this.usersSerive.updateAdvCover(this.selectedPhoto, this.userInfo.docId, this.editUserInfoForm.value).then(value => {
        this.globalService.hideLoading();
        this.globalService.showToastMessage('Information updated successfully.');
        this.modalCtrl.dismiss();
      }, error => {
        this.globalService.hideLoading();
        this.globalService.showToastMessage('Something went wrong', 'error');
        this.modalCtrl.dismiss();
        console.log('error', error);
      });
    } else {
      this.usersSerive.userAdminEditInfo(this.userInfo.docId, this.editUserInfoForm.value).then(() => {
        this.globalService.hideLoading();
        this.globalService.showToastMessage('Information updated successfully.');
        this.modalCtrl.dismiss();
      }, error => {
        this.globalService.hideLoading();
        this.globalService.showToastMessage('Something went wrong', 'error');
        this.modalCtrl.dismiss();
        console.log('error', error);
      });
    };
  };

  public updateCompanyInfo() {
    // console.log(this.editCompanyInfoForm.value);
    this.loading = true;
    const updateCompanyData = {
      companyName: this.editCompanyInfoForm.value.companyName,
    };
    if (this.tackNewImg) {
      this.usersSerive.updateAdvCover(this.selectedPhoto, this.userInfo.docId, updateCompanyData).then(() => {
        this.globalService.hideLoading();
        this.globalService.showToastMessage('Information updated successfully.');
        this.modalCtrl.dismiss();
      }, error => {
        this.globalService.hideLoading();
        this.router.navigate(['/profile']);
        this.globalService.showToastMessage('Something went wrong', 'error');
        this.modalCtrl.dismiss();
        console.error('Error', error);
      });
    } else {
      this.usersSerive.userAdminEditInfo(this.userInfo.docId, updateCompanyData).then(res => {
        this.globalService.hideLoading();
        this.router.navigate(['/profile']);
        this.loading = false;
        this.globalService.showToastMessage('Information updated successfully.');
        this.modalCtrl.dismiss();
      }, error => {
        this.globalService.hideLoading();
        this.router.navigate(['/profile']);
        this.globalService.showToastMessage('Something went wrong', 'error');
        this.modalCtrl.dismiss();
        console.log('error', error);
      });
    };
  };

  dobDate() {
    this.dob = new Date(this.editUserInfoForm.value.dob).toISOString().split('T')[0];
  }
  
  availableFromDate() {
    this.availableFrom = new Date(this.editUserInfoForm.value.availableFrom).toISOString().split('T')[0];
  }

  availableUntilDate() {
    this.availableUntil = new Date(this.editUserInfoForm.value.availableUntil).toISOString().split('T')[0];
  }

  public onCurrentCountryChange(event){
    this.editUserInfoForm.get('currentProvince').setValue('');
    this.editUserInfoForm.get('currentCounty').setValue('');
    if (event.detail.value === 'Ireland') {
      this.currentProvincesList = this.constantVariable.provinces;
    } else if(event.detail.value === 'United Kingdom'){
      this.currentProvincesList = this.constantVariable.ukProvinces;
    } else if(event.detail.value === 'European Union'){
      this.currentProvincesList = this.constantVariable.euProvinces;
    }
  }

  public onCurrentprovinceChange(event){
    this.editUserInfoForm.get('currentCounty').setValue('');
    const selectedVal = (event.detail.value).split(" ").join("");
    console.log('selectedVal --------------------2 ', selectedVal);
    this.currentCountiesList = this.constantVariable[selectedVal];
  }


  public async takePhotoFromCamera(type) {
    const comeraOptions: CameraOptions = {
      allowEditing: false,
      correctOrientation: true,
      direction: CameraDirection.Front,
      quality: 95,
      resultType: CameraResultType.Base64,
      source: null,
      saveToGallery: true
    };
    if (type === 'camera') {
      comeraOptions.source = CameraSource.Camera;
    } else {
      comeraOptions.source = CameraSource.Photos;
    }
    await Camera.getPhoto(comeraOptions).then(async selectedPhoto => {
      let journalImageInput = null;
      let imageArr: any;
      const img = new Image();
      img.src = 'data:image/jpeg;base64,' + selectedPhoto.base64String;
      img.onload = () => {
        const MAX_WIDTH = 1024;
        const MAX_HEIGHT = 1024;
        const canvas = document.createElement('canvas');
        const IMAGE_WIDTH = img.width;
        const IMAGE_HEIGHT = img.height;
        const scale = Math.min((MAX_WIDTH / IMAGE_WIDTH), (MAX_HEIGHT / IMAGE_HEIGHT));
        const iwScaled = IMAGE_WIDTH * scale;
        const ihScaled = IMAGE_HEIGHT * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        journalImageInput = canvas.toDataURL('image/jpeg', 0.5);
        imageArr = journalImageInput.split(',');
        this.selectedPhoto = imageArr;
        this.isImageSaved = true;
        this.tackNewImg = true;
      };
    }, (error: any) => {
      console.log('error', error);
      this.modalCtrl.dismiss(null, 'cancel');
    });
  }


}
