import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ConstantVariables } from 'src/const/constant';
import { Camera, CameraDirection, CameraOptions, CameraResultType, CameraSource } from '@capacitor/camera';
import { Subscription } from 'rxjs';
import { CmsCategoryService } from 'src/app/api-services/cmsCategory/cms-category.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
// import { debounceTime } from 'rxjs/operators';
import { CmsService } from 'src/app/api-services/cms/cms.service';

@Component({
  selector: 'app-add-edit-cms',
  templateUrl: './add-edit-cms.component.html',
  styleUrls: ['./add-edit-cms.component.scss'],
})
export class AddEditCmsComponent implements OnInit {



  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '20rem',
    minHeight: '50',
    placeholder: 'Enter details here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    sanitize: false,
    // toolbarHiddenButtons: [
    //     [
    //     "justifyLeft",
    //     "justifyCenter",
    //     "justifyRight",
    //     "justifyFull",
    //     ]
    //   ],
  };
  

  // public editorConfig: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: '20rem',
  //   minHeight: '50',
  //   maxHeight: 'auto',
  //   width: 'auto',
  //   minWidth: '0',
  //   translate: 'yes',
  //   enableToolbar: true,
  //   showToolbar: true,
  //   placeholder: 'Enter details here...',
  //   defaultParagraphSeparator: '',
  //   defaultFontName: '',
  //   defaultFontSize: '',
  //   fonts: [
  //     { class: 'arial', name: 'Arial' },
  //     { class: 'times-new-roman', name: 'Times New Roman' },
  //     { class: 'calibri', name: 'Calibri' },
  //     { class: 'comic-sans-ms', name: 'Comic Sans MS' }
  //   ],
  //   customClasses: [
  //     {
  //       name: 'quote',
  //       class: 'quote',
  //     },
  //     {
  //       name: 'redText',
  //       class: 'redText'
  //     },
  //     {
  //       name: 'titleText',
  //       class: 'titleText',
  //       tag: 'h1',
  //     },
  //   ],
  //   uploadUrl: 'v1/image',
  //   // upload: (file: File) => {  },
  //   uploadWithCredentials: false,
  //   sanitize: true,
  //   toolbarPosition: 'top',
  //   toolbarHiddenButtons: [
  //     [],
  //   ]
  // };


  public cmsData: any;
  public urlType: any;
  public openForm: any;


  public loading = false;
  public cmsForm: FormGroup = new FormGroup({
    title: new FormControl(),
      urlSlug: new FormControl(),
      // keywordsTag: [this.cmsData?.keywordsTag ? this.cmsData?.keywordsTag : '', Validators.required],
      sortOrder: new FormControl(),
      category: new FormControl(),
      details: new FormControl(),
      status: new FormControl(),
    });
  public provincesList = [];
  public countiesList = [];
  public cuntryList = [];
  public choosenFile: any;
  public selectedFiles: any;
  public type: any;
  public currentFileUpload: any;
  public selectedPhoto = '';
  public tackNewImg = false;
  public isImageSaved = false;
  private journalImageInput: any;
  private subscriptions: Subscription[] = [];

  count = 0
  public chackingSlag = false;

  public validation_messages: any = {

    title: [
      { type: 'required', message: 'Title is required.' },
    ],
    urlSlug: [
      { type: 'required', message: 'Url slug  is required.' },
    ],
    keywordsTag: [
      { type: 'required', message: 'Keywordstag  is required.' },
    ],
    sortOrder: [
      { type: 'required', message: 'Sort order is required.' },
    ],
    category: [
      { type: 'required', message: 'Category is required.' },
    ],
    details: [
      { type: 'required', message: 'Details is required.' },
    ],
    status: [
      { type: 'required', message: 'Status is required.' },
    ],
  }
  public categoryArr: any;
  errorSlug = false;



  constructor(
    private fb: FormBuilder,
    private modalCtrl: ModalController,
    private constantVariable: ConstantVariables,
    private cmsService: CmsService,
    private cmsCategoryService: CmsCategoryService,
    private ref: ChangeDetectorRef
  ) {
    // this.cuntryList = this.constantVariable.cuntry;
  }

  ngOnInit() {
    // this.crateForm();
    console.log('cmsData', this.cmsData)
  }

  public ionViewDidLeave () {
    this.subscriptions.map(subscriptions => {
      subscriptions.unsubscribe();
    })
  }

  public ionViewDidEnter(): void {
    if (this.urlType === 'help') {
      this.subscriptions.push(
        this.cmsCategoryService.getAllCmsCategoryData('help').subscribe(res => {
          this.categoryArr = res;
          this.crateForm();
        }, error => {
          console.log('error', error);
        })
      )
    } else if (this.urlType === 'mental-health') {
      this.subscriptions.push(
        this.cmsCategoryService.getAllCmsCategoryData('Mental-Health').subscribe(res => {
          this.categoryArr = res;
          this.crateForm();
        }, error => {
          console.log('error', error);
        })
      )
    } else if (this.urlType === 'FAQs') {
      this.subscriptions.push(
        this.cmsCategoryService.getAllCmsCategoryData('faq').subscribe(res => {
          this.categoryArr = res;
          this.crateForm();
        }, error => {
          console.log('error', error);
        })
      )
    } else {
      this.crateForm();
    }
  }

  ngAfterContentChecked(): void {
    this.ref.detectChanges();
 } 


  public crateForm() {
    this.count = 0;
    if (this.openForm === 'Edit') {
      this.selectedPhoto = this.cmsData?.cmsImage;
      this.isImageSaved = this.cmsData?.cmsImage? true: false;
    }
    this.cmsForm = this.fb.group({
      title: [this.cmsData?.title ? this.cmsData?.title : '', Validators.required],
      urlSlug: [this.cmsData?.urlSlug ? this.cmsData?.urlSlug : ''],
      // keywordsTag: [this.cmsData?.keywordsTag ? this.cmsData?.keywordsTag : '', Validators.required],
      sortOrder: [this.cmsData?.sortOrder ? this.cmsData?.sortOrder : '', Validators.required],
      category: [this.cmsData?.category ? this.cmsData?.category : '', Validators.required],
      details: [this.cmsData?.details ? this.cmsData?.details : '', Validators.required],
      status: [this.cmsData?.status ? this.cmsData?.status : 'published'],
    })
  }

  public updateCmsInfo(openForm) {
    // console.log('this.cmsForm.value', this.cmsForm.value);
    // return
    if(openForm === 'Add'){
      this.subscriptions.push(
        this.cmsService.getCmsDataBySlag(this.urlType, this.cmsForm.value.urlSlug).subscribe(res => {
    
          if (res && res.length) {
            this.errorSlug = true;
          } else {
            this.loading = true;
            this.cmsForm.value.type = this.urlType;
    
            if (this.openForm === 'Add') {
              // console.log('photo',this.selectedPhoto);
              const obj = {
                formData: this.cmsForm.value,
                selectedPhoto: this.selectedPhoto
              }
              this.modalCtrl.dismiss(obj, this.openForm);
            } else {
    
              if (this.tackNewImg === false) {
                const obs = {
                  formData: this.cmsForm.value,
                }
                this.modalCtrl.dismiss(obs, this.openForm);
              } else {
                // console.log('photo',this.selectedPhoto);
                const obs = {
                  formData: this.cmsForm.value,
                  selectedPhoto: this.selectedPhoto,
                }
                this.modalCtrl.dismiss(obs, this.openForm);
    
              }
            }
          }
    
        }, error => {
          console.error('error', error);
        })
      )      
    } else {
      this.loading = true;
      this.cmsForm.value.type = this.urlType;
      if (this.openForm !== 'Add') {
        if (this.tackNewImg === false) {
          const obs = {
            formData: this.cmsForm.value,
          }
          this.modalCtrl.dismiss(obs, this.openForm);
        } else {
          // console.log('photo',this.selectedPhoto);
          const obs = {
            formData: this.cmsForm.value,
            selectedPhoto: this.selectedPhoto,
          }
          this.modalCtrl.dismiss(obs, this.openForm);

        }

      }
    }

  }

  cancel() {
    this.modalCtrl.dismiss();
  }


  public async takePhotoFromCamera(type: any) {
    const comeraOptions: CameraOptions = {
      allowEditing: false,
      correctOrientation: true,
      direction: CameraDirection.Front,
      quality: 95,
      resultType: CameraResultType.Base64,
      source: null,
      saveToGallery: true
    };
    if (type === 'camera') {
      comeraOptions.source = CameraSource.Camera;
    } else {
      comeraOptions.source = CameraSource.Photos;
    }
    await Camera.getPhoto(comeraOptions).then(async selectedPhoto => {
      this.journalImageInput = null;
      let imageArr: any;
      const img = new Image();
      img.src = 'data:image/jpeg;base64,' + selectedPhoto.base64String;
      img.onload = () => {
        const MAX_WIDTH = 1024;
        const MAX_HEIGHT = 1024;
        const canvas = document.createElement('canvas');
        const IMAGE_WIDTH = img.width;
        const IMAGE_HEIGHT = img.height;
        const scale = Math.min((MAX_WIDTH / IMAGE_WIDTH), (MAX_HEIGHT / IMAGE_HEIGHT));
        const iwScaled = IMAGE_WIDTH * scale;
        const ihScaled = IMAGE_HEIGHT * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        this.journalImageInput = canvas.toDataURL('image/jpeg', 0.5);
        imageArr = this.journalImageInput.split(',');
        this.selectedPhoto = imageArr;
        this.isImageSaved = true;
        this.tackNewImg = true;
      };
    }, (error: any) => {
      console.log('error', error);
      this.modalCtrl.dismiss(null, 'cancel');
    });
  }

  changeType(e) {
    // console.log('e', e);
    if (e.detail.value === 'published') {
      this.cmsForm.controls.status.setValidators(null);
      this.cmsForm.controls.status.updateValueAndValidity();
    } else {
      this.cmsForm.controls.status.setValidators(Validators.required);
      this.cmsForm.controls.status.updateValueAndValidity();
    }
  }


  public viewDoc(data) {
    window.open(data.cmsImage);
  }


  public createSlag(type): void {
    if(this.openForm === 'Add'){
      this.count = 0;
      const slugify = str =>
        str
          .toLowerCase()
          .trim()
          .replace(/[^\w\s-]/g, '')
          .replace(/[\s_-]+/g, '-')
          .replace(/^-+|-+$/g, '');
      const titleVal = type === 'title' ? this.cmsForm.value.title : this.cmsForm.value.urlSlug;
      const newSlag = slugify(titleVal);
      // this.cmsForm.get('urlSlug').setValue(newSlag);
      if (type === 'title') {
        this.checkExist(newSlag);
      } else {
        this.errorSlug = false;
        this.cmsForm.get('urlSlug').setValue(newSlag);
      }
    }
  }


  private checkExist(newSlag): void {
    if(this.openForm === 'Add'){
      this.errorSlug = false;
      this.subscriptions.push(
        this.cmsService.getCmsDataBySlag(this.urlType, newSlag).subscribe(res => {
          if (res && res.length) {
            if (res[0].urlSlug === newSlag) {
              const lastChar = res[0].urlSlug.substr(res[0].urlSlug.length - 1);
              if (Number(lastChar)) {
                newSlag = res[0].urlSlug.slice(0, -2);
              }
              this.count = this.count + 1;
              if (this.count < 10) {
                const newSlagVal = newSlag + `-${this.count}`;
                this.checkExist(newSlagVal);
              } else {
                this.cmsForm.get('urlSlug').setValue(newSlag);
                this.chackingSlag = false;
                this.errorSlug = true;
              }
            }
    
          } else {
            this.cmsForm.get('urlSlug').setValue(newSlag);
          }
        }, error => {
          console.log('error', error)
        })
      )
    }
  }

}
