import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ManageTableService } from 'src/app/api-services/manage-jobs/manage-table.service';
import { ConstantVariables } from 'src/const/constant';

@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss'],
})
export class SkillComponent implements OnInit {

  public jobsData: any;
  public skillForm: FormGroup;
  public valueJob: any;
  private subscriptions: Subscription[] = [];
  helthSeftyData: any;
  public get formDataConfiguration(): FormArray { return this.skillForm.get('experience') as FormArray; }
  public get healthAndSafetyConfiguration(): FormArray { return this.skillForm.get('healthAndSafety') as FormArray; }
  public get skillSetConfiguration(): FormArray { return this.skillForm.get('skillSet') as FormArray; }

  constructor(
    private manageTableService: ManageTableService,
    private constantVariable: ConstantVariables,
    private formBuilder: FormBuilder,
    private modalCtrl: ModalController,
    private ref: ChangeDetectorRef,
  ) {
    this.skillForm = this.formBuilder.group({
      experience: this.formBuilder.array([]),
      healthAndSafety: this.formBuilder.array([]),
      skillSet: this.formBuilder.array([]),
    });
    this.appendExpRow();
    this.appendHelthSeftyRow();
    this.appendSkillSetRow();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.manageTableService.getAllData(this.constantVariable.manageJob).subscribe(res => {
        this.jobsData = res;
      }, error => {
        console.error('error', error);
      })
    );
    this.subscriptions.push(
      this.manageTableService.getAllData(this.constantVariable.manageHealthSafety).subscribe(res => {
        this.helthSeftyData = res;
      }, error => {
        console.error('error', error);
      })
    );
  }

  public ionViewDidLeave(): void {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    });
  }

  public valuejobType(event, type, i) {
    this.jobsData.map(res => {
      if (res.type === event.detail.value) {
        this.valueJob = res;
        if (type == 'exp') {
          ((this.skillForm.get('experience') as FormArray).controls[i]).get('experience').setValue('');
          this.getExperience(i);
        }
        else if (type === 'skillSet') {
          this.getSkillSet(i);
        }
      }
    })
  }

  // ------------------------Experience------------------------------------------

  public appendExpRow(): void {
    const fg = new FormGroup({
      jobType: new FormControl('', Validators.required),
      jobTypeImg: new FormControl('', Validators.required),
      experience: new FormControl('', Validators.required),
      experienceImg: new FormControl('', Validators.required),

      experienceData: new FormControl('', Validators.required),
    });
    (this.skillForm.get('experience') as FormArray).push(fg);
  }

  public removeExpRow(index: number): void {

    if (this.formDataConfiguration.controls.length >= 2) {
      (this.skillForm.get('experience') as FormArray).removeAt(index);
    } else {
      ((this.skillForm.get('experience') as FormArray).controls[index]).get('jobType').setValue('');
      ((this.skillForm.get('experience') as FormArray).controls[index]).get('experience').setValue('');
      ((this.skillForm.get('experience') as FormArray).controls[index]).get('experienceData').setValue([])
    }
  }

  private getExperience(i): void {
    this.subscriptions.push(
      this.manageTableService.getDataByJobType(this.constantVariable.manageExperience, this.valueJob.docId).subscribe(res => {
        if (res && res.length) {
          ((this.skillForm.get('experience') as FormArray).controls[i]).get('jobTypeImg').setValue(this.valueJob.photo);
          ((this.skillForm.get('experience') as FormArray).controls[i]).get('experienceData').setValue(res);
          ((this.skillForm.get('experience') as FormArray).controls[i]).get('experienceImg').setValue(res[0].photo);
        }
      }, error => {
        console.log('error', error);
      })
    );
  }

  // ------------------------Health and Safety-------------------------------------

  public appendHelthSeftyRow(): void {
    const fg = new FormGroup({
      healthAndSafety: new FormControl('', Validators.required),
      healthAndSafetyImg: new FormControl('', Validators.required)
    });
    (this.skillForm.get('healthAndSafety') as FormArray).push(fg);
  }

  public removeHelthSeftyRow(index: number): void {

    if (this.healthAndSafetyConfiguration.controls.length >= 2) {
      (this.skillForm.get('healthAndSafety') as FormArray).removeAt(index);
      ((this.skillForm.get('healthAndSafety') as FormArray).controls[index]).get('healthAndSafety').setValue('');
    }
  }

  // ------------------------Skill Set-----------------------------------------------

  public appendSkillSetRow(): void {
    const fg = new FormGroup({
      jobType: new FormControl('', Validators.required),
      jobTypeImg: new FormControl('', Validators.required),
      skillSet: new FormControl('', Validators.required),
      skillSetImg: new FormControl('', Validators.required),
      skillSetData: new FormControl('', Validators.required),
    });
    (this.skillForm.get('skillSet') as FormArray).push(fg);
  }

  public removeSkillSetRow(index: number): void {

    if (this.skillSetConfiguration.controls.length >= 2) {
      (this.skillForm.get('skillSet') as FormArray).removeAt(index);
    } else {
      ((this.skillForm.get('skillSet') as FormArray).controls[index]).get('jobType').setValue('');
      ((this.skillForm.get('skillSet') as FormArray).controls[index]).get('skillSet').setValue('');
      ((this.skillForm.get('skillSet') as FormArray).controls[index]).get('skillSetData').setValue([]);
    }
  }

  private getSkillSet(i): void {
    this.subscriptions.push(
      this.manageTableService.getDataByJobType(this.constantVariable.manageSkills, this.valueJob.docId).subscribe(res => {
        ((this.skillForm.get('skillSet') as FormArray).controls[i]).get('skillSet').setValue('');
        // ((this.skillForm.get('skillSet') as FormArray).controls[i]).get('skillSetSub').setValue('');

        if (res && res.length) {
          ((this.skillForm.get('skillSet') as FormArray).controls[i]).get('skillSetData').setValue(res);

          ((this.skillForm.get('skillSet') as FormArray).controls[i]).get('jobTypeImg').setValue(this.valueJob.photo);
          ((this.skillForm.get('skillSet') as FormArray).controls[i]).get('skillSetImg').setValue(res[0].photo);
        }
      }, error => {
        console.log('error', error);
      })
    );
  }
  public skip(): void {
    this.modalCtrl.dismiss(this.skillForm.value, 'skip');
  }

  public saveSkillData(e): void {
    this.modalCtrl.dismiss(this.skillForm.value, 'save');

  }
}