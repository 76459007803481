import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { AddEditCmsComponent } from './add-edit-cms/add-edit-cms.component';

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ModalController, AlertController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/services/global.service';
import { ChangeDetectorRef } from '@angular/core';
import { CmsService } from 'src/app/api-services/cms/cms.service';


@Component({
  selector: 'app-cms',
  templateUrl: './cms.page.html',
  styleUrls: ['./cms.page.scss'],
})
export class CmsPage implements OnInit {

  @ViewChild('paginatorPageSize') paginator!: MatPaginator;


  public dataSource: MatTableDataSource<any>;
  public dataSourceEmpty: any;
  public uploadJobsData: any;
  public submitted = false;
  public displayedColumns: string[] = ['index', 'Title', 'urlSlug', 'details', 'actions'];

  public pageSizes = [10, 50, 100];
  private subscriptions: Subscription[] = [];
  urlType: string;

  constructor(
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private globalService: GlobalService,
    private ref : ChangeDetectorRef,
    private activatedRoute : ActivatedRoute,
    private cmsService : CmsService,
    private router: Router,
  ) { }

  ngOnInit() {}

  ionViewWillEnter(){
    this.urlType = '';
    this.globalService.hideLoading();
    this.subscriptions.push(
      this.activatedRoute.paramMap.subscribe(
        (params: ParamMap) => {
          this.urlType = '';
        this.urlType = params.get('type');
        if(this.urlType !== 'about'){
          this.dataSourceEmpty = null;
          this.dataSource = new MatTableDataSource(null);
          this.getData(this.urlType);
        } else {
          this.router.navigate(['admin/mange-about'])
          // mange-about
        }
        },
      )
    );
        // console.log(' this.urlType',  this.urlType);
  }

  public ionViewDidLeave(): void {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    });
  };
  

  public getData(type): void{
    this.subscriptions.push(
      this.cmsService.getAllCmsData(type).subscribe(data => {
        data.sort((a, b) => a.title < b.title ? -1 : (a.title > b.title ? 1 : 0));
        this.dataSourceEmpty = data;
        // if(type === 'about'){
        //   this.addEditCms(type, data[0]);
        // }
        this.dataSource = new MatTableDataSource(data);
        this.ref.detectChanges();
        this.dataSource.paginator = this.paginator;
      }, error => {
        console.log('error', error);
      })
    );
  }

  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  public async addEditCms(type, data) {
    // console.log('asdfasdfasd', this.urlType);
    const modal = await this.modalCtrl.create({
      component: AddEditCmsComponent,
      cssClass: 'fullscreen',
      backdropDismiss: false,
      componentProps: {
        urlType: this.urlType,
        openForm: type,
        cmsData: data ? data : ''
      }
    });
    modal.onDidDismiss().then((res: any) => {
      // console.log('>>>>>>>>>>>>>>>>>', res);

      // return
      if (res.role === 'Add') {
        console.log('Something done!')
        this.addCms(res.data);
      } else if (res.role === 'Edit') {
        console.log('Changes...');
        this.editCms(res.data, data.docId);
      } else {
        console.log('Nothing chnaged!');
      }
    })
    return await modal.present();
  }

  public addCms(data):void {
    console.log('data------------', data);
    this.globalService.showLoading();
    this.subscriptions.push(
      this.cmsService.addCmsData(data).subscribe(res => {
        if(res.status === 200){
          // console.log('>>>>>>>>>>>>>>', res);
          if(res.status === 200){
            if(data.selectedPhoto !== ''){
              this.cmsService.updateAdvCover(data.selectedPhoto, res.id , 'add').then(val =>{
                // console.log('-----------val---------', val);
                this.globalService.hideLoading();
                this.globalService.showToastMessage('Data add successfuly');
              })
            } else {
              this.globalService.showToastMessage('Data add successfuly');
              this.globalService.hideLoading();
            }
          }
        } else {
          this.globalService.showToastMessage('Error in add data.', 'error');
        }
      }, error => {
        console.error('error', error);
      })
      )
    }
    
    public editCms(data, cmsId):void {
      this.globalService.showLoading();
      if(data.selectedPhoto) {
        this.cmsService.updateAdvCover(data.selectedPhoto,cmsId, 'edit', data ).then( res => {
          this.globalService.showToastMessage('Data update successfuly');
          this.globalService.hideLoading();
          }, error => {
            console.log('error', error);
          })
      } else {
        this.subscriptions.push(
          this.cmsService.updateCmsData(data, cmsId).subscribe(res =>{
            this.globalService.hideLoading();
            if(res.status === 200) {
              this.globalService.showToastMessage('Data update successfuly');
            }
          }, error => {
            console.log('error', error);
          })
        );
      }
  }

  public async delete(data) {
    const alert = await this.alertController.create({
      message: 'Are you sure you want to delete!',
      mode: 'ios',
      cssClass : 'no-approve',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            console.log('Alert canceled');
          },
        },
        {
          text: 'Delete',
          role: 'confirm',
          handler: () => {
            console.log('Alert confirmed');
            this.subscriptions.push(
              this.cmsService.deleteCms(data.docId).subscribe(res => {
                this.globalService.showToastMessage('Delete data successfully');
              }, error => {
                console.error('error', error);
              })
            );
          },
        },
      ],
    });
    await alert.present();
  }

}
