import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { AlertController, ModalController, Platform } from '@ionic/angular';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ConstantVariables } from 'src/const/constant';
import { UsersService } from 'src/app/api-services/users/users.service';
import { GlobalService } from 'src/app/services/global.service';
import { UploadVideoComponent } from 'src/app/profile/upload-video/upload-video.component';
import { CaptureModalComponent } from './capture-modal/capture-modal.component';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { Capacitor } from '@capacitor/core';
import { MediaCapture, MediaFile, CaptureError } from '@ionic-native/media-capture/ngx';
import { CaptureVideoOptions } from '@ionic-native/media-capture/ngx';
@Component({
  selector: 'app-about-you',
  templateUrl: './about-you.component.html',
  styleUrls: ['./about-you.component.scss'],
})
export class AboutYouComponent implements OnInit {

  @Input() userDocId;

  public errorResponse: any;
  public aboutYou: FormGroup = new FormGroup({
    // dob: new FormControl(''),
  });

  public jobsData: any;
  public openFrom: any;
  public loading = false;
  public dob: any;
  public availableFrom: string;
  public availableUntil: string;

  private subscriptions: Subscription[] = [];
  public countiesList = [];
  public provincesList = [];
  public cuntryList = [];
  public currentProvincesList = [];
  public currentCountiesList = [];
  public maxDate =  new Date().toLocaleDateString('fr-CA');
 
  tackNewImg1: boolean;
  selectedPhoto: any;
  public blob: any;
  
  public webPlateForm: boolean = false;
  public videoPath = '';
  public isVideoBuffering = false;

  public progress = 0;
  public showProgress = false;
  public progressValue: number = 10;
  public progressPercent: any = .10;
  public videoData = '';
  
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private constantVariable: ConstantVariables,
    private alertCtrl: AlertController,
    private usersService: UsersService,
    private globalService: GlobalService,
    public platform: Platform,
    private mediaCapture: MediaCapture,
    private file: File,
    private ref: ChangeDetectorRef,

  ) {
    // this.provincesList = this.constantVariable.provinces;
    this.cuntryList = this.constantVariable.cuntry;
  }

  ngOnInit() {
    this.aboutYou = this.formBuilder.group({
      // dob: [ '', Validators.compose([Validators.required])],
      currentCountry: [ '', Validators.compose([Validators.required])],
      currentProvince: [ ''],
      currentCounty: [ ''],
      currentCity: [ ''],
      // availableUntil: [ ''],
      // availableFrom: [ ''],
      
      // education: [ ''],
      // schoolCollege: [ ''],
      // country: [ ''],
      // province: [ ''],
      // county: [ ''],
      // city: [ ''],
      // fromYear: [ '',],
      // toYear: [ ''],
      video:[''],
      
      aboutWork: [ '', Validators.compose([Validators.required])],
      expYear: [ ''],
    });

    if (this.platform.is('desktop')) {
      this.webPlateForm = true;
    } else {
      this.webPlateForm = false;
    }
    this.videoPath = '';
    this.isVideoBuffering = false;
    this.videoPath = '';
    this.videoData = '';
    this.aboutYou.value.video = ''
    this.showProgress = false;
    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    });
  }
  public ionViewDidLeave(): void {
    this.subscriptions.map(subscription => {
      subscription.unsubscribe();
    });
  }

  public cancel() {
    this.isVideoBuffering = false;
    this.videoPath = '';
    this.videoData = '';
    this.aboutYou.value.video = ''
    this.modalCtrl.dismiss(null, 'cancel');
  }


  public saveAboutYou() {
    // this.addDocs();

    // this.aboutYou.value.timeStampDob = new Date(this.aboutYou.value.dob);
    // const tempFrom = new Date(this.aboutYou.value.availableFrom)
    // tempFrom.setHours(0);
    // tempFrom.setMinutes(0);
    // tempFrom.setSeconds(1);
    // this.aboutYou.value.timeStmpavailableFrom = this.aboutYou.value.availableFrom ?  tempFrom : '';
    // const tempUntil = new Date(this.aboutYou.value.availableUntil)
    // tempUntil.setHours(23);
    // tempUntil.setMinutes(59);
    // tempUntil.setSeconds(59);
    // this.aboutYou.value.timeStmpavailableUntil = this.aboutYou.value.availableUntil ? tempUntil : '';

    this.modalCtrl.dismiss(this.aboutYou.value, 'cancel');

  }
    
  dobDate() {
    this.dob = new Date(this.aboutYou.value.dob).toISOString().split('T')[0];
  }
  
  availableFromDate() {
    this.availableFrom = new Date(this.aboutYou.value.availableFrom).toISOString().split('T')[0];
  }

  availableUntilDate() {
    this.availableUntil = new Date(this.aboutYou.value.availableUntil).toISOString().split('T')[0];
  }

  public onCurrentCountryChange(event){
    this.aboutYou.get('currentProvince').setValue('');
    this.aboutYou.get('currentCounty').setValue('');
    if (event.detail.value === 'Ireland') {
      this.currentProvincesList = this.constantVariable.provinces;
    } else if(event.detail.value === 'United Kingdom'){
      this.currentProvincesList = this.constantVariable.ukProvinces;
    }else if(event.detail.value === 'European Union'){
      this.currentProvincesList = this.constantVariable.euProvinces;
    }
  }

  public onCountryChange(event){
    this.aboutYou.get('province').setValue('');
    this.aboutYou.get('county').setValue('');
    if (event.detail.value === 'Ireland') {
      this.provincesList = this.constantVariable.provinces;
    } else if(event.detail.value === 'United Kingdom'){
      this.provincesList = this.constantVariable.ukProvinces;
    }else if(event.detail.value === 'European Union'){
      this.provincesList = this.constantVariable.euProvinces;
    }
  }

  public onprovinceChange(event){
    this.aboutYou.get('county').setValue('');
    const selectedVal = (event.detail.value).split(" ").join("");
    console.log('selectedVal -----1 ', selectedVal);
    this.countiesList = this.constantVariable[selectedVal];
  }
  
  public onCurrentprovinceChange(event){
    this.aboutYou.get('currentCounty').setValue('');
    const selectedVal = (event.detail.value).split(" ").join("");
    console.log('selectedVal -----2 ', selectedVal);
    this.currentCountiesList = this.constantVariable[selectedVal];
  }

  public logout(): void{
    this.modalCtrl.dismiss(this.aboutYou.value, 'logout');
  }

  changeExpType(e){
    console.log('e', e);
    this.aboutYou.controls.expYear.reset();
    if(e.detail.value === 'new' ) {
      this.aboutYou.controls.expYear.setValidators(null);
      this.aboutYou.controls.expYear.updateValueAndValidity();
    } else {
      this.aboutYou.controls.expYear.setValidators(null);
      this.aboutYou.controls.expYear.updateValueAndValidity();
    }
  } 

  public numericOnly(event): boolean {
    let pattern = /^([0-9])$/;
    let result = pattern.test(event.key);
    return result;
  }
  

  public async takeVideo(event: any, type: any) {
    this.tackNewImg1 = false;
    this.subscriptions.push(
      this.globalService.selectFile(event).subscribe(res => {
        if (res) {
          this.selectedPhoto = res;
          this.aboutYou.value.video = res;
        }
      })
    );
  }


  public addDocs(): void {
    this.usersService.uploadFile(this.selectedPhoto, this.userDocId,).then(res => {
      if (res) {
        this.aboutYou.value.video = res;
        const data: any = {
          docId: this.userDocId,
          video: res,
          verifiedUser: true,
        };
        this.subscriptions.push(
          this.usersService.updateUser(data).subscribe(res => {
          }, error => {
            console.error('error', error);
          })
        );
      }
    });
  }

  public async openStreamModal(){
    const modal = await this.modalCtrl.create({
      component: CaptureModalComponent,
      cssClass: 'aboutExpStream',
      backdropDismiss: false,
      componentProps: {
        streamVideo: 'stream'
      }
    });
    modal.onDidDismiss().then((res: any) => { 
      if(res.role === 'blob'){
        this.blob = res.data;
        res.data.file = res.data;
        this.confUpload(res.data.file);
      }else {
        console.log('Nothing done!');
      }
    })
    return await modal.present();
  }

  private async confUpload(blob){
    const alert = await this.alertCtrl.create({
      header: 'Alert',
      message: 'Are you sure want to upload captured video',
      mode: 'ios',
      cssClass: '',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => { 
            console.log('Uploading cancel!');
           }
        },
        {
          text: 'Yes',
          role: 'confirm',
          handler: () => { 
            this.selectedPhoto = blob;
            this.selectedPhoto.name = 'Capture video';
            this.aboutYou.value.video = blob;
            // this.usersService.uploadCaptureVideo(blob, this.userDocId);
           }
        }
      ],
    });
    await alert.present();
  }

  public doBlankVlue() {
    this.selectedPhoto = '';
    this.isVideoBuffering = false;
    this.videoPath = '';
    this.videoData = '';
    this.usersService.getDownloadurl('');
    this.showProgress = false;
    this.progressValue = 0;
    this.aboutYou.value.video = '';
    this.ref.detectChanges();
  }

  public captureVideoStreamFromMobile(): void {
    this.doBlankVlue()
    this.isVideoBuffering = true;
    this.ref.detectChanges();

    const options: CaptureVideoOptions = {
      limit: 1, // You can specify the number of videos to capture
      duration: 30 // Maximum duration in seconds
    };

      this.mediaCapture.captureVideo(options).then(
        (data: any) => {
          if (data.length > 0) {
            this.getVideoFromCameraFolder(data[0].fullPath);
            // this.videoPath = Capacitor.convertFileSrc(data[0].fullPath);
          } else {
            this.doBlankVlue();
          }
        },
        (err: CaptureError) => {
          this.doBlankVlue();
          console.error('ERROR IN CAPTURE VIDEO', err);
        }
      );

    }


    getVideoFromCameraFolder(fullPath: any) {
      let myPath = fullPath;
      // Make sure we copy from the right location
      if (fullPath.indexOf('file://') < 0) {
        myPath = 'file://' + fullPath;
      }

      const name = myPath.substr(myPath.lastIndexOf('/') + 1);
      console.log('VIDEO NAME====>', name);
      const copyFrom = myPath.substr(0, myPath.lastIndexOf('/') + 1);
      this.file
      .checkFile(copyFrom, name)
      .then((checkFileRes) => {
        console.log(
          '=================IS VIDEO EXIST IN CAMERA DIR==================',
          checkFileRes
          );
          this.platform
            .ready()
            .then(() => {
              return this.file.resolveDirectoryUrl(copyFrom);
            })
            .then((rootDir) => {
              console.log('ROOT DIR =========', rootDir);
              return this.file.getFile(rootDir, name, { create: false });
            })
            .then(
              (fileEntry) => {
                fileEntry.file(
                  (file) => {
                    console.log('Get Video From Camera Folder ===>', file);
                    this.uploadOnFirebaseStorageFromCamera(file, copyFrom);
                  },
                  (error) => {
                    this.isVideoBuffering = false;
                    console.log('Error in getting video from Camera Dir', error);
                  }
                );
              },
              (error) => {
                this.isVideoBuffering = false;
                console.log('rror in getting video from Camera Dir', error);
              }
            );
        })
        .catch((err) => {
          this.isVideoBuffering = false;
          console.error('Source file does not exist:', err);
        });
    }

    async uploadOnFirebaseStorageFromCamera(f: any, copyFrom: any) {
      this.selectedPhoto = {}
      console.log('==========FILE FROM CAMERA DIR=============', f);
      const type = this.getMimeType(f.name.split('.').pop());
      console.log('VIDEO DIR PATH===>', copyFrom);
      this.file.readAsArrayBuffer(copyFrom, f.name).then((result) => {
        console.log('=======readAsArrayBuffer From===========Camera Dir', result);
        const fileBlob = new Blob([result], { type: type });
        console.log('=========CONVERT VIDEO IN FILE BLOB====', fileBlob);
        // this.selectedPhoto.file = fileBlob;
        // this.selectedPhoto.name = 'Capture video';
        // this.aboutYou.value.video = this.selectedPhoto;
        // console.log('this.selectedPhoto==========', this.selectedPhoto)


        this.uploadToFirestorage(fileBlob)
        this.ref.detectChanges();

        this.isVideoBuffering = false;
        this.ref.detectChanges();
      });
    }

    public getMimeType(fileExt: any): any {
      if (fileExt === 'wav') return { type: 'audio/wav' };
      else if (fileExt === 'jpg') return { type: 'image/jpg' };
      else if (fileExt === 'mp4') return { type: 'video/mp4' };
      else if (fileExt === 'MOV') return { type: 'video/quicktime' };
    }



    public uploadToFirestorage(selectedFile) {
      console.log('selectedFile===', selectedFile)
      this.subscriptions.push(
        this.usersService.uploadFileVideoForMobile(selectedFile, this.userDocId).subscribe(res => {
          this.isVideoBuffering = false;
          this.ref.detectChanges();
          this.progressValue = Math.floor((res.bytesTransferred / res.totalBytes) * 100);
          console.log('progressValue', this.progressValue)
          this.showProgress = true;
          if (this.progressValue > 10) {
            this.progressPercent = `.${this.progressValue}`;
            if (this.progressValue === 100) {
              this.showProgress = false;
              this.ref.detectChanges();
            } else {
              this.showProgress = true;
              this.ref.detectChanges();
            }
          }

          this.usersService.downloadVideoUrl.subscribe(downloadUrl => {
            console.log('downloadUrl', downloadUrl)
            if(downloadUrl && downloadUrl.url) {
              this.videoPath = downloadUrl.url;
              this.videoData = downloadUrl.url;
              this.aboutYou.value.video = this.videoData;
              this.ref.detectChanges();
              this.showProgress = false;
              this.ref.detectChanges();
            } else {
              this.videoPath ='';
              this.ref.detectChanges();
            }
          }, error => {
            this.doBlankVlue();
            this.ref.detectChanges();
            console.log('error in 452 ==>', error);
            this.globalService.showToastMessage('error in getting download url.', 'error');

          });
        }, error => {
          console.log('error ==> 457', error);
          this.globalService.showToastMessage('Something went worng', 'error');
        })
      );
    }

  




}
